<template>
    <div class="pt-2" v-if="hasRole(sysAdmin) || hasRole(rosterAdmin)">
        <div class="card w-100 h-100">
            <div class="card-header bg-dark text-white">Rounds Rosters</div>
            <div class="card-body">
                <div class="row">
                    <div class="form-group col-sm-12 text-center">
                        <label class="custom-address-label">Select a tracking year</label>
                        <Select2 v-model="year" :options="yearList" :settings="yearSettings" :disabled="yearList.length<1"
                                 :name="'years'" @change="getClasses"></Select2>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12 text-center">
                        <label class="custom-address-label">Select a course, or add a new one</label>
                        <Select2 v-model.number="course.id" :options="courseList" :settings="courseSettings"
                                 :name="'courses'" @change="getCourse"></Select2>
                    </div>
                </div>
                <div class="row" id="classForm">
                    <div class="col-sm-12 col-md-12">
                        <fieldset class="field-border">
                            <legend class="field-border">OEMS Approval # {{ course.approval_number }}</legend>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 form-group">
                                    <label class="custom-address-label">Select a course type, or enter new type</label>
                                    <Select2 v-model="course.course_type_id" :options="courseTypes" :settings="locTypeSettings"
                                             :name="'courseType'" @change="validateField('courseType')"></Select2>
                                </div>
                                <div class="col-sm-6 col-md-6 form-group">
                                    <label class="custom-address-label">Select course location, or enter new location</label>
                                    <Select2 v-model="course.location_id" :options="locations" :settings="locTypeSettings"
                                             :name="'courseLocation'" @change="validateField('courseLocation')"></Select2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 form-group">
                                    <label class="custom-address-label">Enter course name</label>
                                    <input type="text" v-model="course.course_name" name="courseName" id="courseName" class="form-control"
                                           data-fv-not-empty="true"
                                           data-fv-not-empty___message="Course name is required">
                                </div>
                                <div class="col-sm-6 col-md-6 form-group">
                                    <label class="custom-address-label">Enter course date</label>
                                    <div class="input-group date">
                                        <datepicker v-model="course.course_date" :wrap="true" :config="dateOptions"
                                                    placeholder="mm/dd/yyyy" id="courseDate" name="courseDate" @dp-change="validateField('courseDate')"></datepicker>
                                        <div class="input-group-append">
                                            <div class="input-group-text datepickerbutton">
                                                <i class="fas fa-calendar"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 form-group">
                                    <label class="custom-address-label">Enter OEMS approval number</label>
                                    <input type="text" v-model="course.approval_number" name="approvalNumber" id="approvalNumber"
                                           class="form-control"
                                           data-fv-not-empty="true"
                                           data-fv-not-empty___message="Approval number is required">
                                </div>
                                <div class="col-sm-6 col-md-6 form-group">
                                    <label class="custom-address-label">Select attendees</label>
                                    <select2 v-model="course.attendees" :options="memberList" :settings="memberSettings" :name="'attendees'"
                                             :id="'attendees'" @change="validateField('attendees')"></select2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 form-group">
                                    <label class="custom-address-label">Select roster to upload (PDF Only)</label>
                                    <input type="file" name="roster" id="roster" ref="roster" class="form-control" @change="setFile()"
                                           data-fv-not-empty="true"
                                           data-fv-not-empty___message="Roster is required"
                                           data-fv-file="true"
                                           data-fv-file___extension="pdf"
                                           data-fv-file___type="application/pdf"
                                           data-fv-file___message="Invalid file type">
                                </div>
                                <div class="col-sm-6 col-md-6 text-center" v-show="course.roster_path!==null && course.roster_path!=='N/A'">
                                    <a class="btn btn-success" role="button" :href="course.roster_path" target="_blank">View roster</a>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-sm-9 col-md-9"></div>
                                <div class="col-sm-3 col-md-3">
                                    <button class="btn btn-danger" @click="delClass" :disabled="isDisabled">Delete Class</button>
                                    <button class="btn btn-primary" @click="submitClass" >{{ addUpdate }} class</button>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <!---MODAL--->
        <membermodal ref="messageModal" :uid="course.id" @hide="closeModal"></membermodal>
    </div>
    <div class="pt-2" v-else>
        <div class="card w-100 h-100">
            <div class="card-header bg-danger text-white">ACCESS DENIED</div>
            <div class="card-body"></div>
        </div>
    </div>
</template>

<script>
import membermodal from './MembersModal'
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import Declarative from 'formvalidation/plugins/Declarative';
import {roleCheck} from "../../helpers/rolecheck";
import memberAppStore from "../../store/"
export default {
    name: "RoundsRosters",
    store: memberAppStore,
    mixins: [roleCheck],
    components: {
        membermodal
    },
    created() {
        this.getYears()
        this.getCourseTypes()
        this.getLocations()
        this.getMembers()
    },
    mounted() {
        this.classForm=formValidation(document.getElementById('classForm'), {
            fields: {
                courseType: {
                    validators: {
                        notEmpty: {
                            message: 'Course type is required'
                        }
                    }
                },
                courseLocation: {
                    validators: {
                        notEmpty: {
                            message: 'Course location is required'
                        }
                    }
                },
                courseDate: {
                    validators: {
                        notEmpty: {
                            message: 'Course date is required'
                        },
                        date: {
                            format: 'MM/DD/YYYY',
                            message: 'Invalid date'
                        }
                    }
                },
                attendees: {
                    validators: {
                        notEmpty: {
                            message: 'Attendees are required'
                        }
                    }
                }
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap()
            }
        })
    },
    computed: {
        addUpdate() {
            if (this.course.id>0) {
                return "Update"
            } else {
                return "Add"
            }
        },
        isDisabled() {
            return this.addUpdate !== "Update";
        },

    },
    data() {
        return {
            course: {
                id: null,
                course_type_id: null,
                location_id: null,
                course_name: null,
                course_date: null,
                approval_number: null,
                roster_path: null,
                attendees: []
            },
            roster: null,
            year: null,
            yearList: [],
            courseList: [
                {id: 0, text: 'Add new course'}
            ],
            yearSettings: {
                width: '25%',
                placeholder: 'Select a year',
                theme: 'bootstrap4',
                minimumResultsForSearch: Infinity
            },
            courseSettings: {
                width: '30%',
                placeholder: 'Select a course',
                theme: 'bootstrap4'
            },
            courseTypes: [],
            locations: [],
            locTypeSettings: {
                width: '100%',
                theme: 'bootstrap4',
                tags: true
            },
            memberList: [],
            memberSettings: {
                width: '100%',
                placeholder: 'Select attendees',
                theme: 'bootstrap4',
                multiple: true
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
                allowInputToggle: false
            }
        }
    },
    methods: {
        setFile() {
            this.roster=this.$refs.roster.files[0]
        },
        delClass() {
            let modal=this.$refs.messageModal
            modal.output="Deleting class record"
            modal.messageSent=false
            $(modal.$refs.messageSentModal).modal('show')
            axios.delete('/api/deleteCourse/'+this.course.id)
            .then(response=>{
                modal.messageSent=true
                modal.output="Class record deleted"
            })
            .catch(error => {
                modal.messageSent=true
                modal.output=error
            })
        },
        submitClass() {
            this.classForm.validate().then(response => {
                if (response==="Valid") {
                    let modal=this.$refs.messageModal
                    if (this.addUpdate==="add") {
                        modal.ouput="Adding class"
                    } else {
                        modal.output="Updating class"
                    }
                    modal.messageSent=false
                    $(modal.$refs.messageSentModal).modal('show')
                    let formData=new FormData()
                    formData.append('method',this.addUpdate)
                    formData.append('roster',this.roster)
                    formData.append('data',JSON.stringify(this.course))
                    let headers = {
                        'Content-Type': 'multipart/form-data'
                    }
                    axios.post('/api/updateCourse',formData,{headers:headers})
                        .then(response=> {
                            modal.messageSent=true
                            modal.output=response.data
                        })
                        .catch(error => {
                            modal.messageSent=true
                            modal.output=error
                        })
                }
            })
        },
        closeModal() {
            let modal=this.$refs.messageModal
            modal.messageSent=false
            $(modal.$refs.messageSentModal).modal('hide')
            let k=Object.keys(this.course)
            k.forEach(item => {
                this.course[item]=null
            })

            this.year=null
            this.yearList=[]
            this.courseList=[
                {id: 0, text: 'Add new course'}
            ]
            this.courseTypes=[]
            this.memberList=[]
            this.locations=[]
            this.getYears()
            this.getCourseTypes()
            this.getLocations()
            this.getMembers()
            this.classForm.resetForm(true)
        },
        getYears() {
            axios.get('api/getYears')
            .then(response => {
                response.data.forEach(item => {
                    this.yearList.push(item)
                })
            }).catch(error => {
                console.log(error)
            })
        },
        getCourseTypes() {
            axios.get('api/getCourseTypes')
            .then(response => {
                response.data.forEach(item => {
                    this.courseTypes.push(item)
                })
            }).catch (error => {
                console.log(error)
            })
        },
        getLocations() {
            axios.get('api/getLocations')
            .then(response => {
                response.data.forEach(item => {
                    this.locations.push(item)
                })
            }).catch(error => {
                console.log(error)
            })
        },
        getMembers() {
            let formData=new FormData
            formData.append('filter','als')
            axios.post('api/getMemberList')
            .then(response => {
                response.data.forEach(item => {
                    this.memberList.push(item)
                })
            }).catch(error => {
                console.log(error)
            })
        },
        getClasses() {
            this.courseList=[
                {id: 0, text: 'Add new course'}
            ]
            axios.get('api/getCourses/'+this.year)
            .then(response => {
                response.data.forEach(item => {
                    this.courseList.push(item)
                })
            }).catch(error => {
                console.log(error)
            })
        },
        getCourse() {
            if (this.course.id===0) {
                //Adding a new one so lets clear out the course data
                this.course.course_type_id=null
                this.course.location_id=null
                this.course.course_name=null
                this.course.course_date=null
                this.course.approval_number=null
                this.course.roster_path=null
                this.course.attendees=[]
                //Enable validator just in case
                this.classForm.enableValidator('roster')
            } else {
                //Lets get the data
                axios.get('api/getCourse/'+this.course.id)
                .then(response => {
                    let course=response.data.course
                    let keys=Object.keys(course)
                    keys.forEach(k =>{
                        this.course[k]=course[k]
                    })
                    this.course.attendees=response.data.attendees
                    //Now set the roster path as not mandatory
                    if (this.course.roster_path!=="N/A") {
                        this.classForm.disableValidator('roster')
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            }
        },
        validateField(field) {
            this.classForm.validateField(field)
        }
    }
}
</script>

<style scoped>

</style>
