<template>
<div>
    <div v-if="evalStateLoading" class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div v-else-if="evalStateLoaded">
        <div v-if="type==='admin'">
            <div class="row text-center py-2">
                <div class="col-sm-12 col-md-12">
                    Select a member to view their evaluations
                </div>
            </div>
            <div class="row text-center py-2">
                <div class="col-md-12 col-sm-12">
                    <Select2 v-model="traineeId" :options="memberList" :settings="memberListOptions" @change="getEvals()"></Select2>
                </div>
            </div>
            <div class="row text-center py-2" v-if="traineeId!=='' && eval_date===null">
                <div class="col-md-12 col-sm-12 h4 text-danger">Evaluation is incomplete</div>
            </div>
            <div class="card w-100 border border-dark">
                <div class="card-header bg-primary text-white text-center">Trainee Evaluation Form</div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="col-sm-6 col-md-6">
                            <div>
                                <label class="col-sm-4 col-form-label" for="traineename">Trainee Name</label>
                                <div class="form-group col-sm-8 col-md-8">
                                    <input type="text" name="traineename" id="traineename" class="form-control" v-model="traineename" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <div>
                                <label class="col-sm-4 col-form-label" for="preceptorname">Preceptor Name</label>
                                <div class="form-group col-sm-8 col-md-8">
                                    <input type="text" name="preceptorname" id="preceptorname" class="form-control" v-model="evaluator_name" readonly>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-6 col-md-6">
                            <div>
                                <label class="col-sm-4 col-form-label" for="shiftdate">Date of shift</label>
                                <div class="col-sm-8 col-md-8 form-group">
                                    <input type="text" name="shiftdate" id="shiftdate" class="form-control" v-model="shift_date" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <div>
                                <label class="col-sm-4 col-form-label" for="evaldate">Date of evaluation</label>
                                <div class="col-sm-8 col-md-8 form-group">
                                    <input type="text" name="evaldate" id="evaldate" class="form-control" v-model="eval_date" readonly>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-6 col-md-6">
                            <div class="form-group">
                                <div class="custom-control custom-checkbox custom-checkbox-switch">
                                    <input type="checkbox" class="custom-control-input form-control" name="calls" id="calls" v-model="calls" disabled>
                                    <label class="custom-control-label" for="calls">Were there any calls on the shift?</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <div class="form-group">
                                <div class="custom-control custom-checkbox custom-checkbox-switch">
                                    <input type="checkbox" class="custom-control-input form-control" name="runreports" id="runreports" v-model="run_reports" disabled>
                                    <label class="custom-control-label" for="runreports">Were any run reports completed?</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="section in sectionList" class="row py-1">
            <div class="col-sm-12 col-md-12">
                <div class="w-100 border border-dark">
                    <div class="card-header bg-primary text-white text-center">{{ section.text }}</div>
                    <div class="card-body">
                        <div v-for="question in questions" v-if="section.id===question.section_name">
                            <div v-for="q in question.questions" class="row">
                                <div class="col-sm-6 col-md-6">
                                    <label>{{q.question}}</label>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div v-if="q.question_type==='scale'">
                                        <div class="form-group">
                                            <div class="form-check form-check-inline">
                                                <input type="radio" :id="'1_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'1'" v-model="answers[q.id]" disabled>
                                                <label class="form-check-label" :for="'1_'+q.id">1</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input type="radio" :id="'2_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'2'" v-model="answers[q.id]" disabled>
                                                <label class="form-check-label" :for="'2_'+q.id">2</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input type="radio" :id="'3_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'3'" v-model="answers[q.id]" disabled>
                                                <label class="form-check-label" :for="'3_'+q.id">3</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input type="radio" :id="'4_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'4'" v-model="answers[q.id]" disabled>
                                                <label class="form-check-label" :for="'4_'+q.id">4</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input type="radio" :id="'5_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'5'" v-model="answers[q.id]" disabled>
                                                <label class="form-check-label" :for="'5_'+q.id">5</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input type="radio" :id="'NA_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'N/A'" v-model="answers[q.id]" disabled>
                                                <label class="form-check-label" :for="'NA_'+q.id">N/A</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="q.question_type==='text'" class="form-group">
                                        <textarea class="form-control" :name="'S'+q.section_id+'Q'+q.id" v-model="answers[q.id]" readonly=""></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-primary" @click="go(first)" :disabled="first===null">&lt;&lt;First</button>
        <button class="btn btn-primary" @click="go(prev)" :disabled="prev===null">&lt;Prev</button>
        Record {{current_page}} of {{last_page}}
        <button class="btn btn-primary" @click="go(next)" :disabled="next===null">Next&gt;</button>
        <button class="btn btn-primary" @click="go(last)" :disabled="last===null">Last>&gt;&gt;</button>
    </div>
</div>
</template>

<script>
import ENUM from "../../../helpers/enum";

export default {
    name: "EvalViewer",
    props: ['type'],
    data() {
        return {
            eval_date:'',
            calls: 0,
            run_reports: 0,
            traineeId:'',
            evaluator_name: '',
            shift_date: '',
            sectionList:[],
            questions:[],
            answers:{},
            first:null,
            prev:null,
            last:null,
            next:null,
            current_page: 0,
            last_page: 0,
            questionsLoaded: ENUM.LOADING,
            sectionsLoaded: ENUM.LOADING,
            evalsLoaded: ENUM.LOADING,
            memberList: [],
            memberListOptions: {
                width: "25%",
                theme: 'bootstrap4'
            }
        }
    },
    computed: {
        apiState() {
            if (this.questionsLoaded===ENUM.LOADED && this.sectionsLoaded===ENUM.LOADED) {
                return ENUM.LOADED
            } else {
                return ENUM.LOADING
            }
        },
        evalStateLoading() {
            return this.evalsLoaded===ENUM.LOADING
        },
        evalStateLoaded() {
            return this.evalsLoaded===ENUM.LOADED
        },
        traineename() {
            let name
            if (this.traineeId!=='') {
               let obj=this.memberList.find( o => o.id === parseInt(this.traineeId))
               name=obj.text
            } else {
                name=null
            }
            return name
        }
    },
    watch: {
        apiState: function() {
            if (this.apiState===ENUM.LOADED && this.type==='user') {
                this.getEvals()
            }
        }
    },
    created() {
        if (this.type==='admin') {
            this.getMembersWithEvals()
        }
        this.getQuestions()
        this.getSections()
    },
    methods: {
        getMembersWithEvals() {
            axios.get('/api/training/getMembersWithEvals')
            .then(response => {
                response.data.data.forEach(item => {
                    this.memberList.push(item)
                })
            })
        },
        getQuestions() {
            this.questions=[]
            axios.get('/api/training/getQuestions')
                .then(response => {
                    response.data.data.forEach(item => {
                        this.questions.push(item)
                    })
                    this.buildAnswers()
                }).catch(error => {
                console.log(error)
            })
        },
        buildAnswers() {
            this.questions.forEach(section=> {
                let question=section.questions
                question.forEach(q=> {
                    this.answers[q.id]=''
                })
            })
            this.questionsLoaded=ENUM.LOADED
            if (this.type==='admin') {
                this.evalsLoaded=ENUM.LOADED
            }
        },
        getSections() {
            axios.get('/api/training/getSections')
                .then(response => {
                    response.data.data.forEach(item => {
                        this.sectionList.push(item)
                    })
                    this.sectionsLoaded=ENUM.LOADED
                }).catch(error => {
                console.log(error)
            })
        },
        getEvals() {
            let url
            if (this.type==='user') {
                url='/api/training/getMyEvals'
            } else if (this.type==='admin') {
                url='/api/training/getUserEval/'+this.traineeId

            }
            axios.get(url)
                .then(response => {
                    let id
                    let answer
                    this.first=response.data.links.first
                    this.last=response.data.links.last
                    this.next=response.data.links.next
                    this.prev=response.data.links.prev
                    this.current_page=response.data.meta.current_page
                    this.last_page=response.data.meta.last_page
                    if (this.type=='admin') {
                        this.calls=response.data.data[0].calls
                        this.eval_date=response.data.data[0].eval_date
                        this.evaluator_name=response.data.data[0].evaluator_name
                        this.run_reports=response.data.data[0].run_reports
                        this.shift_date=response.data.data[0].shift_date
                    }
                    if (response.data.data[0].answers.length>0) {
                        response.data.data[0].answers.forEach(item => {
                            id=item.question_id
                            answer=item.answer
                            this.answers[id]=answer
                            this.evalsLoaded=ENUM.LOADED
                        })
                    } else {
                        this.evalsLoaded=ENUM.LOADED
                    }
                })
        },
        go(direction) {
            axios.get(direction)
                .then(response => {
                    this.evalsLoaded=ENUM.LOADING
                    let id
                    let answer
                    this.first=response.data.links.first
                    this.last=response.data.links.last
                    this.next=response.data.links.next
                    this.prev=response.data.links.prev
                    this.current_page=response.data.meta.current_page
                    this.last_page=response.data.meta.last_page
                    if (this.type=='admin') {
                        this.calls=response.data.data[0].calls
                        this.eval_date=response.data.data[0].eval_date
                        this.evaluator_name=response.data.data[0].evaluator_name
                        this.run_reports=response.data.data[0].run_reports
                        this.shift_date=response.data.data[0].shift_date
                    }
                    if (response.data.data[0].answers.length>0) {
                        response.data.data[0].answers.forEach(item => {
                            id=item.question_id
                            answer=item.answer
                            this.answers[id]=answer
                            this.evalsLoaded=ENUM.LOADED
                        })
                    } else {
                        this.evalsLoaded=ENUM.LOADED
                    }
                })
        },
    }
}
</script>

<style scoped>

</style>
