<template>
    <div class="pt-2">
        <div class="card w-100 h-100">
            <div class="card-header bg-dark text-white">Member Roster</div>
            <div class="card-body">
                <div ref="roster" id="roster">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 text-center h3">Member Roster</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 text-center">
                            <table class="table table-bordered table-striped">
                                <thead class="bg-dark">
                                <tr>
                                    <th v-for="col in columns" :class="'text-white'">{{col}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="row in rows">
                                    <td v-for="col in columns">{{row[col]}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import memberAppstore from "../../store"
import moment from 'moment'
export default {
    name: "MemberRoster",
    store: memberAppstore,
    created() {
        this.getRoster()
    },
    data() {
        return {
            rows: []
        }
    },
    computed: {
        "columns" :function columns() {
            if (this.rows.length===0) {
                return []
            }
            return Object.keys(this.rows[0])
        },
    },
    methods: {
        getRoster() {
            axios.get('/api/getMemberRoster')
                .then(response => {
                    response.data.data.forEach(row => {
                        if (row!==null) {
                            this.rows.push(row)
                        }
                    })
                })
        }
    }
}
</script>

<style scoped>

</style>
