<template>
    <div id="history">
        <div class="row h-100">
            <div class="form-group col-sm-6 col-md-6">
                <label class="custom-address-label">Committee Positions</label>
                <Select2 v-model="positions" :options="positionOptions" :settings="positionSettings" :name="'positions'" disabled></Select2>
            </div>
            <div class="form-group col-sm-6 col-md-6">
                <label class="custom-address-label">Last CORI Date</label>
                <input type="text" class="form-control" v-model="last_cori_date" name="last_cori_date" id="last_cori_date" readonly>
            </div>
        </div>

        <fieldset class="field-border">
            <legend class="field-border">Membership History</legend>
            <div class="row">
                <div class="col-sm-5 col-md-5 d-flex justify-content-center font-weight-bold">Event Type</div>
                <div class="col-sm-5 col-md-5 d-flex justify-content-center font-weight-bold">Event Data</div>
                <div class="col-sm-2 col-md-2"></div>
            </div>
            <div class="form-row" v-for="(history, index) in histories" :key="index">
                <div class="form-group col-sm-5 col-md-5">
                    <Select2 v-model.number="history.history_event_id" :options="historyOptions" :settings="historySettings"
                             :name="'history['+index+'].type'" :id="'history['+index+'].type'" disabled=""></Select2>
                </div>
                <div class="form-group col-sm-5 col-md-5">
                    <input type="text" v-model="history.event_date" class="form-control" :name="'history['+index+'].event_date'"
                           :id="'history['+index+'].event_date'" readonly>
                </div>
                <div class="col-sm-2 col-md-2"></div>
            </div>
        </fieldset>
    </div>

</template>

<script>
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'

export default {
    name: "ProfileCommittee",
    mounted() {

    },
    created() {
        this.getPositionOptions()
        this.getHistoryEventTypes()
    },
    data() {
        return {
            positionOptions: [],
            positionSettings: {
                width: '100%',
                theme: 'bootstrap4',
                multiple: true
            },
            historyOptions: [],
            historySettings: {
                width: '100%',
                theme: 'bootstrap4',
                minimumResultsForSearch: Infinity
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
                allowInputToggle: false
            }
        }
    },
    computed: {
        ...mapFields('profile',['positions','member.last_cori_date']),
        ...mapMultiRowFields('profile',['histories'])
    },
    methods: {
        getPositionOptions() {
            axios.get('/api/getPositions')
            .then((response) => {
                this.positionOptions=response.data
            })
        },
        getHistoryEventTypes() {
            axios.get('api/getEventTypes')
            .then((response) => {
                this.historyOptions=response.data
            })
        }
    }
}
</script>

<style scoped>

</style>
