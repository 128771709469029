<template>
    <div id="refs">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <label>Are you apply for Full Time, Part Time or Per Diem employment?</label>
                <div class="form-group col-md-12 col-sm-12">
                    <Select2 v-model="empType" :options="empOptions" :settings="empSettings" :name="'empType'"
                             @change="revalidate('empType')" ></Select2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <label>Briefly describe why you would like to work for Rehoboth Ambulance</label>
                <div class="form-group">
                    <textarea class="form-control" name="whyjoin" v-model="why_join" rows="4"
                    data-fv-not-empty="true"
                    data-fv-not-empty___message="Reason for joining in required"></textarea>
                </div>
            </div>
        </div>

            <fieldset class="field-border">
                <legend class="field-border">
                    Please provide three references
                </legend>
                <div class="row">
                    <div class="col-md-3 col-sm-3">Name</div>
                    <div class="col-md-3 col-sm-3">Phone Number</div>
                    <div class="col-md-3 col-sm-3">Years known</div>
                    <div class="col-md-3 col-sm-3">How do you know them?</div>
                </div>
                <div class="form-row" v-for="(reference, index) in references" :key="index">
                    <div class="form-group col-sm-3 col-md-3">
                        <input type="text" v-model="reference.name" :name="'reference['+index+'].name'" :id="'reference['+index+'].name'" class="form-control"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Name is required">
                    </div>
                    <div class="form-group col-sm-3 col-md-3">
                        <input type="text" v-model="reference.phone" :name="'reference['+index+'].phone'" :id="'reference['+index+'].phone'" class="form-control"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Phone number is required"
                            data-fv-phone="true"
                            data-fv-phone___country="US"
                            data-fv-phone___message="Invalid phone number">
                    </div>
                    <div class="form-group col-sm-3 col-md-3">
                        <input type="number" v-model="reference.years_known" :name="'reference['+index+'].yearsKnown'" :id="'reference['+index+'].yearsKnown'" class="form-control"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Years known is required"
                            data-fv-numeric="true"
                            data-fv-numeric___message="Not a number">
                    </div>
                    <div class="form-group col-sm-3 col-md-3">
                        <input type="text" v-model="reference.how_known" :name="'reference['+index+'].howKnown'" :id="'reference['+index+'].howKnown'" class="form-control"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="How known is required">
                    </div>
                </div>
            </fieldset>


    </div>
</template>

<script>
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import Declarative from 'formvalidation/plugins/Declarative';
import Phone from 'formvalidation/validators/phone';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
import memberAppStore from "../../store"
import states from "../../states";

export default {
    name: "references",
    //store: memberAppStore,
    props: ['appType'],
    data() {
        return {
            empOptions: [
                'Full Time',
                'Part Time',
                'Per Diem'
            ],
            empSettings: {
                width: '100%',
                theme: 'bootstrap4',
                placeholder: 'Select all that apply',
                multiple: true
            }
        }
    },
    mounted() {
        this.empValidator={
            validators: {
                notEmpty: {
                    message: "What type of employment is required"
                }
            }
        }
        this.refer=formValidation(document.getElementById('refs'), {
            fields: {
                'empType': this.empValidator
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap()
            }
        })
        this.refer.registerValidator('phone',Phone)
    },
    computed: {

        ...mapFields('application',['empType','why_join']),
        ...mapMultiRowFields('application',['references'])
    },
    methods: {
        revalidate(name) {
            this.refs.validateField(name)
        },
        async validate() {
            let result=await this.refer.validate()
            return result==="Valid"
        }
    }


}
</script>

<style>

</style>
