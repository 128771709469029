//This module is for membership,observer apps
import {getField, updateField} from "vuex-map-fields";
import ENUM from '../helpers/enum'

export default {
    namespaced: true,
    state: {
        apiState: ENUM.INIT,
        appType: null,
        member: {
            first_name: null,
            middle_name: null,
            last_name: null,
            dob: null,
            email: null,
            call_sign: null,
            auth_to_practice: null,
            last_cori_date: null
        },
        phones: [{
            phone_type: null,
            phone_number: null
        }],
        addresses: [{
            address_type: 'Home',
            street: null,
            city: null,
            state: null,
            zip_code: null
        }],
        emergencyContacts: [{
            relationship: null,
            first_name: null,
            last_name: null,
            street: null,
            city: null,
            state: null,
            zip_code: null,
            phone: null
        }],
        certifications: [{
            cert_type_id: null,
            cert_number: null,
            expiration_date: null,
            state: null
        }],
        empType: null,
        why_join: null,
        references: [{
            name: null,
            phone: null,
            years_known: null,
            how_known: null
        }, {
            name: null,
            phone: null,
            years_known: null,
            how_known: null
        }, {
            name: null,
            phone: null,
            years_known: null,
            how_known: null
        }],
        cori: {
            other_name: null,
            last_six: null,
            place_of_birth: null,
            gender: null,
            height_feet: null,
            height_inches: null,
            eye_color: null,
            race: null,
            mother_maiden: null,
            father_name: null,
            former_street: null,
            former_city: null,
            former_state: null,
            former_zip_code: null,
            cori_affirm: null
        },
        license_photo: null,
        ride_along_dates: null,
        cpr_card_copy: null
    },
    getters: {
        getField,
    },
    actions: {

        addCert({commit},level) {
            let toAdd=[]
            switch (level) {
                case "1":
                    //Add EMT Basic and CPR
                    toAdd=[{
                        cert_type_id: '3',
                        cert_number: null,
                        expiration_date: null,
                        state: 'MA'
                    },{
                        cert_type_id: '6',
                        cert_number: null,
                        expiration_date: null,
                        state: null
                    }]
                    break;
                case "2":
                    //Add Advanced EMT and CPR
                    toAdd=[{
                        cert_type_id: '4',
                        cert_number: null,
                        expiration_date: null,
                        state: null
                    }, {
                        cert_type_id: '6',
                        cert_number: null,
                        expiration_date: null,
                        state: null
                    }]
                    break;
                case "3":
                    //Add paramedic, CPR and ACLS
                    toAdd=[{
                        cert_type_id: '5',
                        cert_number: null,
                        expiration_date: null,
                        state: 'MA'
                    },{
                        cert_type_id: '6',
                        cert_number: null,
                        expiration_date: null,
                        state: null
                    }, {
                        cert_type_id: '7',
                        cert_number: null,
                        expiration_date: null,
                        state: null
                    }]
                    break;
                case "4":
                    //Add ACLS for the medic
                    toAdd=[{
                        cert_type_id: '7',
                        cert_number: null,
                        expiration_date: null,
                        state: null
                    }]
                    break;
                default:
                    //Add one row
                    toAdd=[{
                        cert_type_id: null,
                        cert_number: null,
                        expiration_date: null,
                        state: null
                    }]
            }
            toAdd.forEach(row => {
                commit('ADD_CERT_ROW',row)
            })
        }

    },
    mutations: {
        updateField,
        setAppType(state,appType) {
            state.appType=appType
        },
        uploadFile(state, file) {
            state.license_photo=file
        },
        uploadCprCard(state,file) {
            state.cpr_card_copy=file
        },
        addPhone(state) {
            state.phones.push({
                phone_type: null,
                phone_number: null
            })
        },
        deletePhone(state, index) {
            state.phones.splice(index,1)
            //this.phones.splice(index,1);
        },
        addContact(state, newIndex) {
            state.emergencyContacts.push({
                relationship: null,
                first_name: null,
                last_name: null,
                street: null,
                city: null,
                state: null,
                zip_code: null,
                phone: null
            })
        },
        removeContact(state, index) {
            state.emergencyContacts.splice(index,1)
        },
        ADD_CERT_ROW(state, toAdd) {
            state.certifications.push(toAdd)
        },
        removeCert(state, toRemove) {
            state.certifications.splice(toRemove,1)
        }
    }
}
