<template>
    <div class="pt-4">
        <div class="row chevron-header h6">
            <a data-toggle="collapse" aria-expanded="true" href="#" aria-controls="collapseLinks collapseDates"
               data-target=".collapseLinksDates" class="d-block">
                <i class="fas fa-chevron-down float-right"></i>
                Show/Hide Top Panels
            </a>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-6">
                    <expirations></expirations>
                </div>
            <div class="col-md-6 col-sm-6">
                    <links></links>
                </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router/router'
import expirations from './MembersOnly/Expirations'
import links from './MembersOnly/Links'
import memberAppStore from "../store"
export default {
    name: "MembersApp",
    components: {
        expirations,
        links
    },
    store: memberAppStore,
    router: router,
    props: ['uid'],
    mounted() {
        router.push({ name: 'profile'})
        //Now populate the store with the member's profile info
        memberAppStore.dispatch('profile/getProfile', this.uid)
    }
}
</script>

<style scoped>

</style>
