<template>
    <div class="pt-2" v-if="hasRole(sysAdmin) || hasRole(trainingAdmin)">
        <div class="card w-100 h-100">
            <div class="card-header bg-dark text-white">Eval Question Admin</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <Select2 v-model="section_choice" :options="sectionList" :settings="sectionSettings" :disabled="sectionList.length<1"
                                 name="sectionList" @change="setEmpty"></Select2>
                    </div>
                </div>
                <div v-if="section_choice!==''">
                    <fieldset class="field-border">
                        <legend class="field-border">{{section_name}}</legend>
                        <div class="row">
                            <div class="col-sm-5 col-md-5 font-weight-bold text-center">Question</div>
                            <div class="col-sm-3 col-md-3 font-weight-bold text-center">Question Type</div>
                            <div class="col-sm-3 col-md-3 font-weight-bold text-center">Is Active</div>
                            <div class="col-sm-1 col-md-1"></div>
                        </div>
                        <div class="form-row" v-for="(question, index) in questions_list" :key="index">
                            <div class="form-group col-sm-5 col-md-5">
                                <input v-model="question.question" type="text" class="form-control" :name="'question['+index+'].question'">
                            </div>
                            <div class="form-group col-sm-3 col-md-3">
                                <Select2 v-model="question.question_type" :options="typeList" :settings="questionSettings" :name="'question['+index+'].question_type'"></Select2>
                            </div>
                            <div class="form-group col-sm-3 col-md-3">
                                <Select2 v-model="question.is_active" :options="activeList" :settings="questionSettings" :name="'question['+index+'].is_active'"></Select2>
                            </div>
                            <div class="col-sm-1 col-md-1">
                                <i class="alert-danger fas fa-minus-circle" @click="remove(index)" v-show="(index || (!index && questions_list.length>1)) && (deleting!==index)"></i>
                                <div class="spinner-border spinner-border-sm text-danger" role="status" v-show="(index || (!index && questions_list.length >1)) && (deleting===index)">
                                    <span class="sr-only">Deleting...</span>
                                </div>
                                <i class="alert-success fas fa-plus-circle" @click="add(index)" v-show="index === questions_list.length-1"></i>
                            </div>
                        </div>
                    </fieldset>
                    <div class="row justify-content-end">
                        <div class="col-lg-9"></div>
                        <div class="col-lg-3">
                            <button class="btn btn-dark" @click="updateQuestions">Update Questions</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!---MODAL--->
        <membermodal ref="messageModal" @hide="closeModal"></membermodal>
    </div>
    <div class="pt-2" v-else>
        <div class="card w-100 h-100">
            <div class="card-header bg-danger text-white">ACCESS DENIED</div>
            <div class="card-body"></div>
        </div>
    </div>
</template>

<script>
import {roleCheck} from "../../../helpers/rolecheck";
import membermodal from '../MembersModal'

export default {
    name: "EvalAdmin",
    mixins: [roleCheck],
    components: {
        membermodal
    },
    data() {
        return {
            deleting: -1,
            section_choice: '',
            typeList: [
                {id: 'scale', text: 'Rating Scale'},
                {id: 'text', text: 'Comment Field'}
            ],
            activeList: [
                {id: 1, text: 'Yes'},
                {id: 0, text: 'No'}
            ],
            questionSettings: {
                width: "100%",
                theme: "bootstrap4"
            },
            sectionList: [],
            sectionSettings: {
                width: "25%",
                placeholder: "Select a section",
                theme: "bootstrap4"
            },
            questions: []
        }
    },
    created() {
        this.getSections()
        this.getQuestions()
    },
    computed: {
        section_name() {
            if (this.section_choice!=='') {
                let name=this.sectionList.find(o=>o.id===this.section_choice)
                return name['text']
            } else {
                return false
            }
        },
        questions_list() {
            if (this.section_choice !=='') {
                let question=this.questions.find(o=>o.section_name===this.section_choice)
                return question['questions']
            } else {
                return false
            }

        },
        section_id() {
            if (this.section_choice !=='') {
                let section=this.questions.find(o=>o.section_name===this.section_choice)
                return section['section_id']
            } else {
                return false
            }

        }
    },
    methods: {
        getSections() {
            axios.get('/api/training/getSections')
            .then(response => {
                response.data.data.forEach(item => {
                    this.sectionList.push(item)
                })
            }).catch(error => {
                console.log(error)
            })
        },
        getQuestions() {
            this.questions=[]
            axios.get('/api/training/getQuestions')
            .then(response => {
                response.data.data.forEach(item => {
                    this.questions.push(item)
                })
            }).catch(error => {
                console.log(error)
            })
        },
        setEmpty() {
            if (this.questions_list.length===0) {
                let question={
                    'id': '0',
                    'section_id': this.section_id,
                    'question': '',
                    'question_type': '',
                    'is_active': '1'
                }
                this.questions_list.push(question)
            }
        },
        add() {
            let question={
                'id': '0',
                'section_id': this.section_id,
                'question': '',
                'question_type': '',
                'is_active': '1'
            }
            this.questions_list.push(question)
        },
        remove(index) {
            this.deleting=index
            let qid=this.questions_list[index].id
            axios.delete('/api/training/deleteQuestion/'+qid)
            .then(response=> {
                this.questions_list.splice(index,1)
                this.deleting=-1
            }).catch(error => {
                console.log(error)
                this.deleting=-1
            })
        },
        updateQuestions() {
            let all_questions=[]
            this.questions.forEach(section => {
                let qs=section.questions
                qs.forEach(q => {
                    all_questions.push(q)
                })
            })
            console.log(all_questions)
            let formData=new FormData()
            formData.append('questions',JSON.stringify(all_questions))
            this.$refs.messageModal.output="Updating questions"
            $(this.$refs.messageModal.$refs.messageSentModal).modal('show')
            axios.post('/api/training/updateQuestions',formData)
            .then(response=>{
                this.$refs.messageModal.messageSent=true
                this.$refs.messageModal.output=response.data
            })
            .catch(error => {
                this.$refs.messageModal.messageSent=true
                this.$refs.messageModal.output=error
            })
        },
        closeModal() {
            this.$refs.messageModal.messageSent=false
            $(this.$refs.messageModal.$refs.messageSentModal).modal('hide')
            this.getQuestions()
        }
    }
}
</script>

<style scoped>

</style>
