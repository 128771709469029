<template>
    <div class="pt-2" v-if="hasRole(sysAdmin)">
        <div class="card w-100 h-100">
            <div class="card-header bg-dark text-white">Mail Recipient Admin</div>
            <div class="card-body">
                <table class="table table-striped table-bordered">
                    <thead class="bg-dark">
                    <tr>
                        <th v-for="col in columns" :class="'text-white'">{{col}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="row in rows">
                        <td v-for="col in columns">
                            <input type="text" v-model="row[col]" class="form-control" :readonly="col==='id' || col==='mail_type'">
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="row float-right">
                    <div class="col-sm-12 col-md-12">
                        <button class="btn btn-dark" @click="updateRecipients">Update Recipients</button>
                    </div>
                </div>
            </div>
        </div>
        <membermodal ref="messageModal" @hide="closeModal"></membermodal>
    </div>
    <div class="pt-2" v-else>
        <div class="card w-100 h-100">
            <div class="card-header bg-danger text-white">ACCESS DENIED</div>
            <div class="card-body"></div>
        </div>
    </div>
</template>

<script>
import {roleCheck} from "../../../helpers/rolecheck";
import membermodal from '../MembersModal'
import ENUM from '../../../helpers/enum'

export default {
    name: "MailRecipients",
    mixins: [roleCheck],
    components: {
        membermodal
    },
    created() {
        this.getRecipients()
    },
    data() {
        return {
            rows: []
        }
    },
    computed: {
        "columns": function columns() {
            if (this.rows.length===0) {
                return []
            }
            return Object.keys(this.rows[0])
        },
    },
    methods: {
        getRecipients() {
            axios.get('/api/admin/getRecipients')
            .then(response=>{
                this.rows=[]
                response.data.forEach(row => {
                    this.rows.push(row)
                })
            })
        },
        updateRecipients() {
            let modal=this.$refs.messageModal
            let formData=new FormData
            formData.append('recipients',JSON.stringify(this.rows))
            modal.output="Updating Recipients"
            $(modal.$refs.messageSentModal).modal('show')
            axios.post('/api/admin/updateRecipients',formData)
            .then(response=>{
                modal.messageSent=true
                modal.output=response.data
            })
            .catch(error=>{
                modal.messageSent=true
                modal.output=error
            })
        },
        closeModal() {
            let modal=this.$refs.messageModal
            modal.messageSent=false
            $(modal.$refs.messageSentModal).modal('hide')
            this.getRecipients()
        }
    }
}
</script>

<style scoped>

</style>
