<template>
    <div class="collapse show d-md-flex pt-4 pl-1" id="memberMenu">
        <ul class="nav flex-column flex-nowrap overflow-hidden">
            <li class="nav-item">
                <router-link class="nav-link text-truncate menuItem" to="/profile"><span class="d-none d-sm-inline memberMenu">My Profile</span></router-link>
            </li>
            <li class="nav-item">
                <router-link to="/minutes" class="nav-link text-truncate menuItem"><span class="d-none d-sm-inline memberMenu">Meeting Minutes</span></router-link>
            </li>
            <li class="nav-item">
                <router-link to="/roster" class="nav-link text-truncate menuItem"><span class="d-none d-sm-inline memberMenu">Member Roster</span></router-link>
            </li>
            <li class="nav-item" v-if="hasRole(sysAdmin) || hasRole(memberAdmin)">
                <router-link class="nav-link text-truncate menuItem" to="/database"><span class="d-none d-sm-inline memberMenu">Membership Database</span></router-link>
            </li>
            <li class="nav-item" v-if="hasRole(rosterAdmin) || hasRole(memberAdmin) || hasRole(sysAdmin)">
                <a class="nav-link collapsed text-truncate menuItem" href="#reports" data-toggle="collapse" data-target="#reports">
                    <span class="d-none d-sm-inline memberMenu">Reports</span>
                </a>
                <div class="collapse" id="reports" aria-expanded="false">
                    <ul class="flex-column pl-3 nav">
                        <li class="nav-item" v-if="hasRole(sysAdmin) || hasRole(rosterAdmin)">
                            <router-link class="nav-link py-0 menuItem" to="/reports/rounds"><span class="memberMenu">Rounds Attendance</span></router-link>
                        </li>
                        <li class="nav-item" v-if="hasRole(sysAdmin) || hasRole(memberAdmin) || hasRole(trainingAdmin)">
                            <router-link class="nav-link py-1 menuItem" to="/reports/expirations"><span class="memberMenu">Expiration Dates</span></router-link>
                        </li>
                        <li class="nav-item" v-if="hasRole(committeeMinutesAdmin) || hasRole(sysAdmin)">
                            <router-link to="/reports/meeting" class="nav-link py-0 menuItem"><span class="memberMenu">Meeting Attendance</span></router-link>
                        </li>
                        <li class="nav-item" v-if="hasRole(memberAdmin) || hasRole(sysAdmin)">
                            <router-link to="/reports/exports" class="nav-link py-0 menuItem"><span class="memberMenu">Data Exports</span></router-link>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="nav-item" v-if="hasRole(sysAdmin) || hasRole(rosterAdmin)">
                <router-link class="nav-link text-truncate menuItem" to="/roundsroster"><span class="d-none d-sm-inline memberMenu">Rounds Rosters</span></router-link>
            </li>
            <li class="nav-item" v-if="hasRole(sysAdmin) || hasRole(trainingAdmin) || isEvaluator || hasEvals">
                <a class="nav-link collapsed text-truncate menuItem" href="#training" data-toggle="collapse" data-target="#training">
                    <span class="d-none d-sm-inline memberMenu">Training</span> <span v-if="pending_evals>0" class="badge badge-pill badge-danger">{{pending_evals}} evals pending</span>
                </a>
                <div class="collapse" id="training" aria-expanded="false">
                    <ul class="flex-column pl-3 nav">
                        <li class="nav-item" v-if="isTrainee">
                            <router-link to="/training/requestEval" class="nav-link py-1 menuItem"><span class="memberMenu">Request Evaluation</span></router-link>
                        </li>
                        <li class="nav-item" v-if="isEvaluator">
                            <router-link to="/training/evaluation" class="nav-link py-1 menuItem"><span class="memberMenu">Submit Evaluation</span>
                                <span v-if="pending_evals>0" class="badge badge-pill badge-danger">{{pending_evals}} pending</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="hasRole(sysAdmin) || hasRole(trainingAdmin)">
                            <router-link to="/training/evalReports" class="nav-link py-1 menuItem"><span class="memberMenu">Review Evaluations</span></router-link>
                        </li>
                        <li class="nav-item" v-if="hasEvals">
                            <router-link to="/training/myEvals" class="nav-link py-1 menuItem"><span class="memberMenu">My Evaluations</span>
                                <span v-if="evals_done>0" class="badge badge-pill badge-success">{{evals_done}} completed</span>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="hasRole(sysAdmin) || hasRole(trainingAdmin)">
                            <router-link to="/training/evalAdmin" class="nav-link py-1 menuItem"><span class="memberMenu">Eval Question Admin</span></router-link>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="nav-item" v-if="hasRole(sysAdmin)">
                <a class="nav-link collapsed text-truncate menuItem" href="#sysAdmin" data-toggle="collapse" data-target="#sysAdmin">
                    <span class="d-none d-sm-inline memberMenu">System Admin</span>
                </a>
                <div class="collapse" id="sysAdmin" aria-expanded="false">
                    <ul class="flex-volumn pl-3 nav">
                        <li class="nav-item">
                            <router-link to="/sysAdmin" class="nav-link text-truncate menuItem"><span class="d-none d-sm-inline memberMenu">Database Table Admin</span></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/mailAdmin" class="nav-link text-truncate menuItem"><span class="d-none d-sm-inline memberMenu">Mail Recipient Admin</span></router-link>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link text-truncate menuItem" href="/logout"><span class="d-none d-sm-inline memberMenu">Logout</span></a>
            </li>
        </ul>
    </div>
</template>

<script>
import router from '../router/router'
import {roleCheck} from "../helpers/rolecheck"
import memberAppStore from "../store"

export default {
    name: "Navigation",
    router: router,
    store: memberAppStore,
    mixins: [roleCheck],
    created() {
        memberAppStore.dispatch('getRoles')
    },
    computed: {
        pending_evals() {
            return memberAppStore.getters['profile/getPendingEvals']
        },
        evals_done() {
            return memberAppStore.getters['profile/getEvalsDone']
        }
    }
}
</script>

<style scoped>

</style>
