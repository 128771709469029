<template>
    <div class="pt-2">
        <div class="card w-100 h-100" id="evalForm">
            <div class="card-header bg-dark text-white">Evaluation Reports</div>
            <div class="card-body">
                <EvalViewer :type="'admin'"></EvalViewer>
            </div>
        </div>
    </div>
</template>

<script>
import EvalViewer from './EvalViewer'

export default {
    name: "EvalReports",
    components: {
        EvalViewer
    }

}
</script>

<style scoped>

</style>
