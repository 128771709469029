<template>
    <div>
        <div class="container">
            <div class="row mb-4">
                <div class="col">
                    <span class="h2">Corporate Leadership</span>
                </div>
            </div>
            <div class="row mb-4 justify-content-center">
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">
                        <div class="card-body py-3">
                            <h5 class="mb-0">Chairman</h5>
                            <span v-html="chair"></span>
                        </div>
                        <div class="card-footer pt-0 border-top-0">
                            <a href="#" class="msgSend" id="chair" @click="sendMessage('Chairman','chair@rehobothambulance.org')">Send a message</a>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">

                        <div class="card-body py-3">
                            <h5 class="mb-0">Vice Chairman</h5>
                            <span v-html="vice"></span>
                        </div>
                        <div class="card-footer pt-0 border-top-0">
                            <a href="#" class="msgSend" id="vicechair" @click="sendMessage('Vice Chairman','vicechair@rehobothambulance.org')">Send a message</a>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">

                        <div class="card-body py-3">
                            <h5 class="mb-0">Secretary</h5>
                            <span v-html="secretary"></span>
                        </div>
                        <div class="card-footer pt-0 border-top-0">
                            <a href="#" class="msgSend" id="secretary" @click="sendMessage('Secretary','secretary@rehobothambulance.org')">Send a message</a>
                        </div>
                    </div>

                </div>
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">

                        <div class="card-body py-3">
                            <h5 class="mb-0">Treasurer</h5>
                            <span v-html="treasurer"></span>
                        </div>
                        <div class="card-footer pt-0 border-top-0">
                            <a href="#" class="msgSend" id="treasurer" @click="sendMessage('Treasurer','treasurer@rehobothambulance.org')">Send a message</a>
                        </div>
                    </div>

                </div>
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">

                        <div class="card-body py-3">
                            <h5 class="mb-0">Board of Directors</h5>
                            <span v-html="bod"></span>
                        </div>
                        <div class="card-footer pt-0 border-top-0">
                            <a href="#" class="msgSend" id="bod" @click="sendMessage('Board of Directors','bod@rehobothambulance.org')">Send a message</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <span class="h2">Operations Leadership</span>
                </div>
            </div>
            <div class="row mb-4 justify-content-center">
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">
                        <div class="card-body py-3">
                            <h5 class="mb-0">Director</h5>
                            <span v-html="chief"></span>
                        </div>
                        <div class="card-footer pt-0 border-top-0">
                            <a href="#" class="msgSend" id="chief" @click="sendMessage('Chief','director@rehobothambulance.org')">Send a message</a>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">

                        <div class="card-body py-3">
                            <h5 class="mb-0">Deputy Director</h5>
                            <span v-html="deputy"></span>
                        </div>
                        <div class="card-footer pt-0 border-top-0">
                            <a href="#" class="msgSend" id="deputy" @click="sendMessage('Deputy Chief','deputy@rehobothambulance.org')">Send a message</a>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">

                        <div class="card-body py-3">
                            <h5 class="mb-0">Field Supervisors</h5>
                            <span v-html="fs"></span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">

                        <div class="card-body py-3">
                            <h5 class="mb-0">Training</h5>
                            <span v-html="training"></span>
                        </div>
                        <div class="card-footer pt-0 border-top-0">

                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">

                        <div class="card-body py-3">
                            <h5 class="mb-0">Supplies</h5>
                            <span v-html="supplies"></span>
                        </div>
                        <div class="card-footer pt-0 border-top-0">

                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-3 d-flex">
                    <div class="card w-100 p-3">

                        <div class="card-body py-3">
                            <h5 class="mb-0">Vehicle Maintenance</h5>
                            <span v-html="vehMaint"></span>
                        </div>
                        <div class="card-footer pt-0 border-top-0">

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!---- MODAL DIALOG BOX --->
        <div class="modal fade" ref="messageModal" id="messageModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="messageTitle" aria-hidden="true">
            <form method="post"  id="sendAMessage">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="messageTitle">Send a message to the {{recipient}}</h5>
                    </div>
                    <div class="modal-body" id="messageText">
                            <input type="hidden" name="to" id="messageTo">
                            <div class="form-group">
                                <input v-model="senderName" type="text" name="SenderName" id="SenderName" class="form-control" placeholder="Your name"
                                       data-fv-not-empty="true"
                                       data-fv-not-empty___message="Your name is required">
                            </div>
                            <div class="form-group">
                                <input type="text" v-model="senderEmail" name="SenderEmail" id="SenderEmail" class="form-control" placeholder="Your email address"
                                       data-fv-not-empty="true"
                                       data-fv-not-empty___message="Your email address is required"
                                       data-fv-email-address="true"
                                       data-fv-email-address___message="Invalid email address">
                            </div>
                            <div class="form-group">
					<textarea class="form-control" v-model="message" id="message" name="message" rows="4" placeholder="Type message here"
                              data-fv-not-empty="true"
                              data-fv-not-empty___message="Message is required"></textarea>
                            </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button> <button type="submit" class="btn btn-primary">Send Message</button>
                        <div class="form-group"><div id="captchaContainer"></div></div>

                    </div>
                </div>
            </div>
            </form>
        </div>

        <!---END MODAL--->

        <!---- MODAL DIALOG BOX --->
        <modal ref="messageSentModal" :redirect-url="messageRedirect"></modal>
        <!---END MODAL--->
    </div>
</template>

<script>
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import SubmitButton from 'formvalidation/plugins/SubmitButton';
import Icon from 'formvalidation/plugins/Icon';
import Declarative from 'formvalidation/plugins/Declarative';

export default {
    name: "Leadership",
    mounted() {
        console.log("Leadership Vue Mounted")

        this.fv=formValidation(document.getElementById('sendAMessage'),{
            plugins: {
                declarative: new Declarative({
                    html5Input: true,
                }),
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
                icon: new Icon({
                    valid: 'fa fa-check',
                    invalid: 'fa fa-times',
                    validating: 'fa fa-refresh',
                }),
            }
        });
        this.fv.on('core.form.valid', () => {
            let modal=this.$refs.messageSentModal
            modal.output="Sending your message"
            $(this.$refs.messageModal).modal('hide')
            $(modal.$refs.messageSentModal).modal('show')
            axios.post('/api/sendMessage', {
                senderName: this.senderName,
                senderEmail: this.senderEmail,
                message: this.message,
                formType: this.formType,
                subject: this.subject,
                to: this.to,
                recipient:this.recipient
            })
            .then(function (response) {
                modal.messageSent=true
                modal.output=response.data;
            })
            .catch(function (error) {
                modal.messageSent=true
                modal.output=error;
            });
        });
    },
    props: ['positions','messageRedirect'],
    computed: {
        chair: function() {
            let chairName=''
            if(this.positions['Chairman'].length!==0) {
                chairName=this.positions['Chairman'][0].first_name+" "+this.positions['Chairman'][0].last_name
            }
            return chairName
        },
        vice: function() {
            let viceName=''
            if(this.positions['Vice Chairman'].length!==0) {
                viceName=this.positions['Vice Chairman'][0].first_name+" "+this.positions['Vice Chairman'][0].last_name
            }
            return viceName
        },
        secretary: function() {
            let secName=''
            if(this.positions['Secretary'].length!==0) {
                secName=this.positions['Secretary'][0].first_name+" "+this.positions['Secretary'][0].last_name
            }
            return secName
        },
        treasurer: function() {
            let tresName=''
            if(this.positions['Treasurer'].length!==0) {
                tresName=this.positions['Treasurer'][0].first_name+" "+this.positions['Treasurer'][0].last_name
            }
            return tresName
        },
        fs: function() {
            let fsMembers=''
            this.positions['Field Supervisor'].forEach(function(member) {
                fsMembers=member.first_name+" "+member.last_name+"<br>"
            })
            return fsMembers
        },
        bod: function() {
            let bodMembers=''
            this.positions['Board of Directors'].forEach(function(member) {
                bodMembers+=member.first_name+" "+member.last_name+"<br>"
            })
            return bodMembers
        },
        chief: function () {
            let chiefName=''
            if (this.positions['Chief'].length!==0) {
                chiefName=this.positions['Chief'][0].first_name+" "+this.positions['Chief'][0].last_name
            }
            return chiefName
        },
        deputy: function () {
            let depName=''
            if (this.positions['Deputy Chief'].length!==0) {
                depName=this.positions['Deputy Chief'][0].first_name+" "+this.positions['Deputy Chief'][0].last_name
            }
            return depName
        },
        training: function() {
            let trainers=''
            this.positions['Training'].forEach(function(trainer) {
                trainers+=trainer.first_name+" "+trainer.last_name+"<br>"
            })
            return trainers
        },
        supplies: function() {
            let suppliers=''
            this.positions['Supplies'].forEach(function(supplier) {
                suppliers+=supplier.first_name+" "+supplier.last_name+"<br>"
            })
            return suppliers
        },
        vehMaint: function() {
            let maintainers=''
            this.positions['Vehicle Maintenance'].forEach(function(maintainer) {
                maintainers+=maintainer.first_name+" "+maintainer.last_name+"<br"
            })
            return maintainers
        }
    },
    data() {
        return {
            recipient: '',
            formType: 'leadership',
            to: '',
            senderName: '',
            senderEmail: '',
            message: '',
            subject: 'A message from Rehoboth Ambulance Website'
        }
    },
    methods: {
        sendMessage: function(receiver,to) {
            this.recipient=receiver
            this.to=to
            $(this.$refs.messageModal).modal('show')
        }
    }
}
</script>

<style scoped>

</style>
