import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    { path: '/profile', name: 'profile', component: require('../components/MembersOnly/Profile').default},
    { path: '/minutes', name: 'minutes', component: require('../components/MembersOnly/Minutes').default},
    { path: '/database', name: 'database', component: require('../components/MembersOnly/MembershipDatabase').default},
    { path: '/roster', name: 'roster', component: require('../components/MembersOnly/MemberRoster').default},
    { path: '/reports/rounds', name: 'roundsReport', component: require('../components/MembersOnly/RoundsAttendance').default},
    { path: '/reports/expirations', name: 'expirationReport', component: require('../components/MembersOnly/ExpirationReport').default},
    { path: '/reports/meeting', name: 'meetingAttendance', component: require('../components/MembersOnly/MeetingAttendance').default},
    { path: '/reports/exports', name: 'dataExport', component: require('../components/MembersOnly/DataExport.vue').default},
    { path: '/roundsroster', name: 'roundsRoster', component: require('../components/MembersOnly/RoundsRosters').default},
    { path: '/training/requestEval', name: 'requestEval', component: require('../components/MembersOnly/Training/RequestEval').default},
    { path: '/training/evaluation', name: 'evaluation', component: require('../components/MembersOnly/Training/Evaluation').default},
    { path: '/training/evalReports', name: 'evalReports', component: require('../components/MembersOnly/Training/EvalReports').default},
    { path: '/training/myEvals', name: 'myEvals', component: require('../components/MembersOnly/Training/MyEvals').default},
    { path: '/training/evalAdmin', name: 'evalAdmin', component: require('../components/MembersOnly/Training/EvalAdmin').default},
    { path: '/sysAdmin', name: 'sysAdmin', component: require('../components/MembersOnly/SysAdmin/Admin').default},
    { path: '/mailAdmin', name: 'mailAdmin', component: require('../components/MembersOnly/SysAdmin/MailRecipients').default}
]

const router = new VueRouter({
    routes: routes,
    //mode: "history"
})

export default router
