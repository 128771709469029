<template>
    <div class="pt-2">
        <div class="card w-100 h-100">
            <div v-if="apiStateLoaded" class="card-header bg-dark text-white">{{ member.first_name }} {{member.middle_name}} {{ member.last_name}}</div>
            <div v-else class="card-header bg-dark text-white">My Profile</div>
            <div class="card-body">
                <div v-if="apiStateLoading" class="d-flex justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div v-else-if="apiStateError" class="alert alert-danger" role="alert">
                    Error loading data
                </div>
                <div v-else-if="apiStateLoaded">
                    <div class="container>">
                        <div class="row justify-content-center">
                            <div class="col-lg-10">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <strong>Created on:</strong> {{ created_on}}
                                    </div>
                                </div>
                                <div class="row"><div class="col-sm-12"></div></div>
                                <!--Tab definitions-->
                                <ul class="nav nav-tabs nav-justified">
                                    <li class="nav-item">
                                        <a class="nav-link" @click.prevent="setActive('demographics')" :class="{ active: isActive('demographics') }"
                                           href="#demographics">Demographics <i v-show="(demoValid===1)" class="fas fa-check text-success"></i>
                                        <i v-show="(demoValid===0)" class="fas fa-times text-danger"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" @click.prevent="setActive('contacts')" :class="{ active: isActive('contacts') } "
                                           href="#contacts">Emergency Contacts <i v-show="(contactValid===1)" class="fas fa-check text-success"></i>
                                            <i v-show="(contactValid===0)" class="fas fa-times text-danger"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" @click.prevent="setActive('certificates')" :class="{ active: isActive('certificates') }"
                                           href="#certificates">Certifications <i v-show="(certsValid===1)" class="fas fa-check text-success"></i>
                                            <i v-show="(certsValid===0)" class="fas fa-times text-danger"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" @click.prevent="setActive('committee')" :class="{ active: isActive('committee') }"
                                            href="#committee">Committee<i class="fa"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" @click.prevent="setActive('rosters')" :class="{ active: isActive('rosters') }"
                                           href="#rosters">Course Rosters</a>
                                    </li>
                                </ul>
                                <!--Tab content-->
                                <div class="tab-content py-3" id="profileContent">
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('demographics') }" id="demographics">
                                        <profiledemo ref="demo"></profiledemo>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('contacts') }" id="contacts">
                                        <profilecontacts ref="contacts"></profilecontacts>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('certificates') }" id="certificates">
                                        <profilecerts ref="certs"></profilecerts>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show' : isActive('committee') }" id="committee">
                                        <profilecommittee ref="committee"></profilecommittee>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show' : isActive('rosters') }" id="rosters">
                                        <profilerosters ref="rosters"></profilerosters>
                                    </div>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-lg-10"></div>
                                    <div class="col-lg-2"><button class="btn btn-dark" @click="updateProfile">Update Profile</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---MODAL--->
        <membermodal ref="messageModal" :uid="uid" @hide="closeModal"></membermodal>
    </div>


</template>

<script>
import ENUM from '../../helpers/enum'
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
import memberAppStore from '../../store'
import profiledemo from "./ProfileTabs/ProfileDemo";
import profilecontacts from "./ProfileTabs/ProfileContacts"
import profilecerts from "./ProfileTabs/ProfileCerts"
import profilecommittee from "./ProfileTabs/ProfileCommittee"
import membermodal from './MembersModal'
import profilerosters from "./ProfileTabs/ProfileRosters"
import moment from "moment";



export default {
    name: "Profile",
    store: memberAppStore,
    components: {
        profiledemo,
        profilecontacts,
        profilecerts,
        profilecommittee,
        membermodal,
        profilerosters
    },
    mounted() {

    },
    computed: {
        ...mapFields('profile',['uid','member']),
        ...mapMultiRowFields('profile',['addresses']),
        apiState() {
            return memberAppStore.getters['profile/getApiState']
        },
        apiStateLoading() {
            return this.apiState===ENUM.LOADING
        },
        apiStateLoaded() {
            return this.apiState===ENUM.LOADED
        },
        apiStateError() {
            return this.apiState===ENUM.ERROR
        },
        created_on() {
            return moment(this.member.created_at).format('L')
        },
        updated_on() {
            return moment(this.member.updated_at).format('L')
        }
    },
    data() {
        return {
            activeTab: 'demographics',
            demoValid: null,
            contactValid: null,
            certsValid: null
        }
    },
    methods: {
        closeModal() {
            //Profile is sent, now lets refresh everything
            let modal=this.$refs.messageModal
            modal.messageSent=false
            $(modal.$refs.messageSentModal).modal("hide")
            this.demoValid=null
            this.contactValid=null
            this.certsValid=null
            memberAppStore.dispatch('profile/getProfile',this.uid)
        },
        isActive(tabItem) {
            return this.activeTab===tabItem
        },
        setActive(tabItem) {
            this.activeTab=tabItem
        },
        updateProfile() {
            this.$refs.demo.validate().then(response => {
                if (response===true) {this.demoValid=1} else {this.demoValid=0}
            })
            this.$refs.contacts.validate().then(response => {
                if (response===true) {this.contactValid=1} else {this.contactValid=0}
            })
            this.$refs.certs.validate().then(response => {
                if (response===true) {this.certsValid=1} else {this.certsValid=0}
                if (this.demoValid===1 && this.certsValid===1 && this.contactValid===1) {
                    let modal=this.$refs.messageModal
                    modal.output="Updating your profile"
                    $(modal.$refs.messageSentModal).modal('show')
                    let headers= {
                        'Content-Type': 'multipart/form-data'
                    }
                    let formData=new FormData()
                    let theStore=memberAppStore.state.profile
                    let keys=Object.keys(theStore.cert_images)
                    keys.forEach(image=>{
                        formData.append(image,theStore.cert_images[image])
                    })
                    formData.append('source','profile')
                    formData.append('data',JSON.stringify(theStore))
                    axios.post('/api/updateProfile',formData, {headers: headers})
                    .then(response => {
                        modal.messageSent=true
                        modal.output=response.data
                    })
                    .catch(error => {
                        modal.messageSent=true
                        modal.output=error
                    })
                }
            })


        }
    }
}
</script>

<style scoped>

</style>
