//This module for the member database functions
import {getField, updateField} from "vuex-map-fields";
import {addCert,ADD_CERT_ROW} from "../helpers/storeFunctions";
import ENUM from '../helpers/enum'
let DefaultState = () => {
    return {
        apiState: ENUM.INIT,
        uid: null,
        level: 0,
        member: [],
        phones: [],
        addresses: [],
        emergencyContacts: [],
        certifications: [],
        positions: [],
        histories: [],
        roles: [],
        courses: [],
        documents: [],
        references: [],
        doc_images: {},
        cert_images: {},
        user: {
            email: null,
        }
    }
}

let EmptyState = () => {
    return {
        apiState: ENUM.INIT,
        uid: null,
        level: 0,
        member: {
            first_name: null,
            middle_name: null,
            last_name: null,
            dob: null,
            email: null,
            call_sign: null,
            auth_to_practice: null,
            last_cori_date: null,
            notes: null,
            rounds_required: null,
            makeup_rounds: null
        },
        phones: [{
            phone_type: null,
            phone_number: null
        }],
        addresses: [{
            address_type: 'Home',
            street: null,
            city: null,
            state: null,
            zip_code: null
        }],
        emergencyContacts: [{
            relationship: null,
            first_name: null,
            last_name: null,
            street: null,
            city: null,
            state: null,
            zip_code: null,
            phone: null
        }],
        certifications: [{
            cert_type_id: 1,
            cert_number: null,
            expiration_date: null,
            state: null,
            cert_path: null
        }],
        references: [{
            name: null,
            phone: null,
            years_known: null,
            how_known: null
        }],
        positions: [],
        histories: [{
            history_event_id: null,
            event_date: null
        }],
        roles: [],
        courses: [],
        documents: [{
            description: null,
            document_date: null,
            document_path: null
        }],
        doc_images: {},
        cert_images: {},
        user: {
            email: null
        }
    }
}

let state=EmptyState()

export default {
    namespaced: true,
    state,
    getters: {
        getField,
        getApiState: (state)=> {
            return state.apiState
        }
    },
    actions: {
        resetDatabase({commit}) {
            commit('RESET_DATABASE_STATE')
        },
        addNewMember({commit}) {
            commit('SET_EMPTY_STATE')
        },
        addCert,
        removeCert({commit},index) {
            commit('DELETE_CERT_ROW',index)
        },
        addHistory({commit}) {
            commit('ADD_HISTORY_ROW')
        },
        deleteHistory({commit},index) {
            commit('DELETE_HISTORY_ROW',index)
        },
        requiredRounds({commit},rounds) {
            commit('SET_REQUIRED_ROUNDS',rounds)
        },
        makeupRounds({commit},rounds) {
            commit('SET_MAKEUP_ROUNDS',rounds)
        },
        async getMemberRecord({commit}, uid) {
            commit('SET_API_STATE',ENUM.LOADING)
            commit('SET_UID',uid)
            if (uid===null) {
                commit('SET_API_STATE',ENUM.ERROR)
            } else {
                await axios.get('api/getMember/'+uid).then(result=> {
                    let stateObject=DefaultState()
                    let profile=result.data.profile
                    let keys=Object.keys(profile)
                    let level=0
                    stateObject.uid=uid

                    keys.forEach(k=> {
                        if (k==="member") {
                            stateObject.member=profile[k]
                        } else if (k==="user") {
                            stateObject.user=profile[k]
                        } else {
                            profile[k].forEach(data => {
                                stateObject[k].push(data)
                            })
                        }
                    })
                    if (profile.histories.length===0) {
                        stateObject.histories.push({
                            history_event_id: null,
                            event_date: null
                        })
                    }
                    if (profile.addresses.length===0) {
                        stateObject.addresses.push({
                            address_type: 'Home',
                            city: '',
                            state: '',
                            street: '',
                            zip_code: ''
                        })
                    }
                    if (profile.phones.length===0) {
                        stateObject.phones.push({
                            phone_type: null,
                            phone_number: null
                        })
                    }
                    if (profile.emergencyContacts.length===0) {
                        stateObject.emergencyContacts.push({
                            relationship: null,
                            first_name: null,
                            last_name: null,
                            street: null,
                            city: null,
                            state: null,
                            zip_code: null,
                            phone: null
                        })
                    }
                    if (profile.certifications.length===0) {
                        let row=[{
                            cert_type_id: 1,
                            cert_number: null,
                            expiration_date: null,
                            state: null,
                            cert_path: null
                        }]
                        stateObject.certifications.push(row[0])
                    }

                    if (profile.documents.length===0) {
                        let row=[{
                            description: null,
                            document_date: null,
                            document_path: null
                        }]
                        stateObject.documents.push(row[0])
                    }

                    if (profile.references.length===0) {
                        let row=[{
                            name: null,
                            phone: null,
                            years_known: null,
                            how_known: null
                        }]
                        stateObject.references.push(row[0])
                    }


                    if (profile.certifications.filter(cert=>cert.cert_type_id===3).length>0) {
                        level=1
                    }
                    if (profile.certifications.filter(cert=>cert.cert_type_id===4).length>0) {
                        level=2
                    }
                    if (profile.certifications.filter(cert=>cert.cert_type_id===5).length>0) {
                        level=3
                    }
                    stateObject.level=level

                    commit('SET_API_STATE',ENUM.LOADED)
                    stateObject.apiState=ENUM.LOADED
                    Object.assign(state,stateObject)
                }).catch(error => {
                    console.log(error)
                    commit('SET_API_STATE',ENUM.ERROR)
                })
            }
        },
        addDocument({commit}) {
            commit('ADD_DOCUMENT_ROW')
        },
        delDocument({commit},index) {
            commit('DELETE_DOCUMENT_ROW',index)
        },
        delCertImage({commit},fieldName) {
            if (fieldName in state.cert_images) {
                commit('DELETE_CERT_IMAGE',fieldName)
            }
        },
        delDocImage({commit},fieldName) {
            if (fieldName in state.doc_images) {
                commit('DELETE_DOCUMENT_IMAGE')
            }
        }

    },
    mutations: {
        updateField,
        ADD_HISTORY_ROW(state) {
            state.histories.push({
                history_event_id: null,
                event_date: null
            })
        },
        ADD_CERT_IMAGE(state,{fieldName,file}) {
            state.cert_images[fieldName]=file
        },
        DELETE_CERT_IMAGE(state,fieldName) {
            delete state.cert_images[fieldName]
        },
        SET_MAKEUP_ROUNDS(state,rounds) {
            state.member.makeup_rounds=rounds
        },
        SET_REQUIRED_ROUNDS(state,rounds) {
            state.member.rounds_required=rounds
        },
        DELETE_HISTORY_ROW(state,index) {
            state.histories.splice(index,1)
        },
        SET_EMPTY_STATE(state) {
            Object.assign(state,EmptyState())
        },
        RESET_DATABASE_STATE(state) {
            Object.assign(state,DefaultState())
        },
        SET_UID(state,uid) {
            state.uid=uid
        },
        SET_LEVEL(state,level) {
            state.level=level
        },
        SET_API_STATE(state,apiState) {
            state.apiState=apiState
        },
        ADD_MEMBER_DATA(state,{k,data}) {
            state[k].push(data)
        },
        ADD_MEMBER(state,member) {
            state.member=member
        },
        ADD_ADDRESS(state,addresses) {
            addresses.forEach(address =>
            state.addresses.push(address)
            )
        },
        ADD_EMPTY_ADDRESS(state) {
            state.addresses.push({
                address_type: 'Home',
                city: '',
                state: '',
                street: '',
                zip_code: ''
            })
        },
        ADD_CERT_ROW,
        DELETE_CERT_ROW(state,index) {
            state.certifications.splice(index,1)
        },
        ADD_DOCUMENT_ROW(state) {
            state.documents.push({
                description: null,
                document_date: null,
                document_path: null
            })
        },
        DELETE_DOCUMENT_ROW(state,index) {
            state.documents.splice(index,1)
        },
        ADD_DOCUMENT_IMAGE(state,{fieldName,file}) {
            state.doc_images[fieldName]=file
        },
        DELETE_DOCUMENT_IMAGE(state,fieldName) {
            delete state.doc_images[fieldName]
        },
        addPhone(state) {
            state.phones.push({
                phone_type: null,
                phone_number: null
            })
        },
        deletePhone(state, index) {
            state.phones.splice(index,1)
            //this.phones.splice(index,1);
        },
        addContact(state) {
            state.emergencyContacts.push({
                relationship: null,
                first_name: null,
                last_name: null,
                street: null,
                city: null,
                state: null,
                zip_code: null,
                phone: null
            })
        },
        removeContact(state, {index}) {
            state.emergencyContacts.splice(index,1)
        },
    }
}
