<template>
    <div class="pt-2" v-if="hasRole(sysAdmin) || hasRole(memberAdmin)">
        <div class="card w-100 h-100">
            <div class="card-header bg-dark text-white">Membership Database</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <Select2 v-model.number="uid" :options="memberList" :settings="memberSettings" :disabled="memberList.length<1"
                            name="memberList" @change="getMember"></Select2>
                    </div>
                </div>
                <br><br>
                <div v-if="apiStateLoading" class="d-flex justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div v-show="apiStateError" class="alert alert-danger" role="alert">
                    Error loading data
                </div>
                <div class=row v-show="uid!==null">
                    <div class="col-sm-6">
                        Created on: {{ this.created_on }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-md-2 d-flex justify-content-center">
                        <img :src="this.id_photo" width="122" height="169" class="img-responsive img-thumbnail">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-md-2 d-flex justify-content-center">
                        <div v-if="showUpload">
                            <button class="btn btn-primary" @click="onPickPhoto">Upload ID Photo</button>
                            <input type="file" style="display: none" ref="fileInput" accept="image/jpeg" @change="onPhotoPicked">
                        </div>
                        <div v-if="uploadingFile" class="d-flex justify-content-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Uploading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-md-2 d-flex justify-content-center">
                        <div v-if="!uploadSuccess" class="d-flex justify-content-center">
                            <div class="text-danger"><strong>Error uploading photo</strong></div>
                        </div>
                    </div>
                </div>
                <p></p>

                    <div class="col-sm-12">
                        <!--Tab Definitions -->
                        <ul class="nav nav-tabs nav-justified">
                            <li class="nav-item">
                                <a class="nav-link" @click.prevent="setActive('demographics')" :class="{ active: isActive('demographics') }"
                                   href="#demographics">Demographics <i v-show="(demoValid===1)" class="fas fa-check text-success"></i>
                                    <i v-show="(demoValid===0)" class="fas fa-times text-danger"></i></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click.prevent="setActive('contacts')" :class="{ active: isActive('contacts') } "
                                   href="#contacts">Emergency Contacts <i v-show="(contactValid===1)" class="fas fa-check text-success"></i>
                                    <i v-show="(contactValid===0)" class="fas fa-times text-danger"></i></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click.prevent="setActive('certificates')" :class="{ active: isActive('certificates') }"
                                   href="#certificates">Certifications <i v-show="(certsValid===1)" class="fas fa-check text-success"></i>
                                    <i v-show="(certsValid===0)" class="fas fa-times text-danger"></i></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click.prevent="setActive('committee')" :class="{ active: isActive('committee') }"
                                   href="#committee">Committee <i v-show="(committeeValid===1)" class="fas fa-check text-success"></i>
                                    <i v-show="(committeeValid===0)" class="fas fa-times text-danger"></i></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click.prevent="setActive('rosters')" :class="{ active: isActive('rosters') }"
                                   href="#rosters">Course Rosters</a>
                            </li>
                        </ul>
                        <!--Tab Content -->
                        <div class="tab-content py-3" id="profileContent">
                            <div class="tab-pane fade" :class="{ 'active show': isActive('demographics') }" id="demographics">
                                <dbdemo ref="demo" :key="`${componentKey}-1`"></dbdemo>
                            </div>
                            <div class="tab-pane fade" :class="{ 'active show': isActive('contacts') }" id="contacts">
                                <dbcontacts ref="contacts" :key="`${componentKey}-2`"></dbcontacts>
                            </div>
                            <div class="tab-pane fade" :class="{ 'active show': isActive('certificates') }" id="certificates">
                                <dbcerts ref="certs" :key="`${componentKey}-3`"></dbcerts>
                            </div>
                            <div class="tab-pane fade" :class="{ 'active show' : isActive('committee') }" id="committee">
                                <dbcommittee ref="committee" :key="`${componentKey}-4`"></dbcommittee>
                            </div>
                            <div class="tab-pane fade" :class="{ 'active show' : isActive('rosters') }" id="rosters">
                                <dbrosters ref="rosters"></dbrosters>
                            </div>
                        </div>
                        <div class="row justify-content-end">
                            <div class="col-lg-9"></div>
                            <div class="col-lg-3"><button class="btn btn-danger" @click="delRecord" :disabled="isDisabled">Delete Record</button>
                            <button class="btn btn-dark" @click="updateRecord">{{ addUpdate }} Record</button></div>
                        </div>
                    </div>


            </div>
        </div>
        <!---MODAL--->
        <membermodal ref="messageModal" :uid="uid" @hide="closeModal"></membermodal>
    </div>
    <div class="pt-2" v-else>
        <div class="card w-100 h-100">
            <div class="card-header bg-danger text-white">ACCESS DENIED</div>
            <div class="card-body"></div>
        </div>
    </div>
</template>

<script>
import ENUM from '../../helpers/enum'
import {mapFields} from 'vuex-map-fields'
import dbcerts from './DatabaseTabs/DatabaseCerts'
import dbcontacts from './DatabaseTabs/DatabaseContacts'
import dbdemo from './DatabaseTabs/DatabaseDemo'
import dbcommittee from './DatabaseTabs/DatabaseCommittee'
import dbrosters from './DatabaseTabs/DatabaseRosters'
import membermodal from './MembersModal'
import {roleCheck} from "../../helpers/rolecheck";
import memberAppStore from "../../store"
import moment from 'moment'

function resetValidators(vm) {
    vm.demoValid=null
    vm.contactValid=null
    vm.certsValid=null
    vm.committeeValid=null
    vm.$refs.demo.resetForm()
    vm.$refs.contacts.resetForm()
    vm.$refs.committee.resetForm()
    vm.$refs.certs.resetForm()
}

export default {
    name: "MembershipDatabase",
    store: memberAppStore,
    mixins: [roleCheck],
    components: {
        dbcerts,
        dbcommittee,
        dbdemo,
        dbcontacts,
        membermodal,
        dbrosters
    },
    created() {
        this.getMemberList()
    },
    mounted() {

    },
    computed: {
        ...mapFields('database',['member.first_name','member.last_name','member.created_at','member.id_photo']),
        created_on() {
            return moment(this.created_at).format('L')
        },
        isDisabled() {
            return this.addUpdate !== "Update";
        },
        addUpdate() {
            if (this.uid>0) {
                return "Update"
            } else {
                return "Add"
            }
        },
        apiState() {
            return memberAppStore.getters['database/getApiState']
        },
        apiStateLoading() {
            return this.apiState===ENUM.LOADING
        },
        apiStateLoaded() {
            return this.apiState===ENUM.LOADED
        },
        apiStateError() {
            return this.apiState===ENUM.ERROR
        },
        tabsValid() {
            return (this.demoValid===1) && (this.contactValid===1) && (this.certsValid===1) && (this.committeeValid===1)
        },
        showUpload() {
            return this.apiStateLoaded && !this.uploadingFile
        },

    },
    data() {
        return {
            module: 'database',
            componentKey: 0,
            activeTab: 'demographics',
            demoValid: null,
            contactValid: null,
            certsValid: null,
            committeeValid: null,
            image: null,
            uid: null,
            uploadingFile: false,
            uploadSuccess: true,
            memberList: [
                {id: 0, text: 'Add member'},
            ],
            memberSettings: {
                width: "25%",
                placeholder: "Select a member",
                theme: "bootstrap4"
            }
        }
    },
    methods: {
        onPickPhoto () {
            this.$refs.fileInput.click()
        },
        onPhotoPicked (event) {
            this.uploadingFile=true
            const files=event.target.files
            let filename = files[0].name
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.image=files[0]
            //Now upload the file
            let headers= {
                'Content-Type': 'multipart/form-data'
            }
            let formData=new FormData()
            formData.append('uid',this.uid)
            formData.append('idPhoto',this.image)
            axios.post('/api/uploadId',formData,{headers: headers})
                .then(response => {
                    this.uploadingFile=false
                    let success=response.data.success
                    if (success===1) {
                        this.id_photo=response.data.id_photo
                        this.uploadSuccess=true
                    } else {
                        this.uploadSuccess=false
                    }
                })
                .catch(error => {
                    this.uploadSuccess=false
                    this.uploadingFile=false
                })
        },
        forceReload() {
            this.componentKey+=1
        },
        isActive(tabItem) {
            return this.activeTab===tabItem
        },
        setActive(tabItem) {
            this.activeTab=tabItem
        },
        getMember() {
            resetValidators(this)
            if (this.uid===0) {
                memberAppStore.dispatch('database/addNewMember')
            } else {
                memberAppStore.dispatch('database/addNewMember')
                memberAppStore.dispatch('database/getMemberRecord',this.uid)
            }
        },
        getMemberList() {
            axios.post('api/getMemberList')
            .then(response => {
                response.data.forEach(item => {
                    this.memberList.push(item)
                })
            }).catch(error =>{
                console.log(error)
            })
        },
        delRecord() {
            let modal=this.$refs.messageModal
            modal.output="Deleting record"
            modal.messageSent=false
            $(modal.$refs.messageSentModal).modal('show')
            axios.delete('/api/deleteMember/'+this.uid)
                .then(response=>{
                    modal.messageSent=true
                    modal.output=response.data
                })
                .catch(error => {
                    modal.messageSent=true
                    modal.output=error
                })
        },
        updateRecord() {
            this.$refs.demo.validate().then(response => {
                if (response===true) {
                    this.demoValid=1
                } else {
                    this.demoValid=0
                }
            })
            this.$refs.contacts.validate().then(response => {
                if (response===true) {
                    this.contactValid=1
                } else {
                    this.contactValid=0
                }
            })
            this.$refs.certs.validate().then(response => {
                if (response===true) {
                    this.certsValid=1
                } else {
                    this.certsValid=0
                }
            })
            this.$refs.committee.validate().then(response => {
                if (response===true) {
                    this.committeeValid=1
                    if (this.tabsValid) {
                        let modal=this.$refs.messageModal
                        if (this.addUpdate==="Add") {
                            modal.output="Adding record"
                        } else {
                            modal.output="Updating record"
                        }
                        $(modal.$refs.messageSentModal).modal('show')
                        let headers= {
                            'Content-Type': 'multipart/form-data'
                        }
                        let formData=new FormData()
                        let theStore=memberAppStore.state.database
                        let keys=Object.keys(theStore.cert_images)
                        keys.forEach(image=>{
                            formData.append(image,theStore.cert_images[image])
                        })
                        let docKeys=Object.keys(theStore.doc_images)
                        docKeys.forEach(image=>{
                            formData.append(image,theStore.doc_images[image])
                        })
                        formData.append('source','database')
                        formData.append('data',JSON.stringify(theStore))
                        axios.post('/api/updateRecord',formData,{headers: headers})
                        .then(response => {
                            modal.messageSent=true
                            modal.output=response.data
                        })
                        .catch(error => {
                            modal.messageSent=true
                            modal.output=error
                        })
                    }
                } else {
                    this.committeeValid=0
                }
            })
        },
        closeModal() {
            //Profile is sent, now lets refresh everything
            let modal=this.$refs.messageModal
            modal.messageSent=false
            $(modal.$refs.messageSentModal).modal("hide")
            resetValidators(this)
            memberAppStore.dispatch('database/addNewMember')
            this.uid=null
            this.memberList=[
                {id: 0, text: 'Add member'}
            ]
            this.getMemberList()
            this.forceReload()

        },
    }
}
</script>

<style scoped>

</style>
