<template>
    <div id="cori">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <label class="custom-address-label">Please provide the following additional information to allow us to request your CORI.  Providing this information online as part of this application is optional.  If you choose NOT to provide the information here, you will be required to complete a separate CORI form before you can begin working.</label>
                <div>
                    <div class="custom-control custom-checkbox custom-checkbox-switch">
                        <input type="checkbox" class="custom-control-input" id="askcori" name="askcori" v-model="askcori" @change="setFields">
                        <label class="custom-control-label" for="askcori">Do you wish to provide the information for your CORI now?</label>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <fieldset v-show="askcori">
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <label class="custom-address-label">Maiden name or other name by which you ar known</label>
                    <div class="form-group">
                        <input type="text" name="othername" id="othername" placeholder="Other name" class="form-control" v-model="other_name">
                    </div>
                </div>
                <div class="col-sm-6 col-md-6">
                    <label class="custom-address-label">Last 6 of social security number</label>
                    <div class="form-group">
                        <input type="text" name="last6" id="last6" placeholder="12-3456" class="form-control" v-model="last_six"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Last 6 of SSN required">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <label class="custom-address-label">Place of birth</label>
                    <div class="form-group">
                        <input type="text" name="pob" id="pob" class="form-control" v-model="place_of_birth"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Place of birth required">
                    </div>
                </div>
                <div class="col-sm-6 col-md-6">
                    <label class="custom-address-label">Gender</label>
                    <div class="form-group">
                        <Select2 v-model="gender" :options="genderOptions" :settings="genderSettings" :name="'gender'" @change="revalidate('gender')"></Select2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <label class="custom-address-label">Height</label>
                    <div class="form-group">
                        <input type="number" name="height" id="height" class="form-control" placeholder="feet" v-model="height_feet"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Height is required"
                            data-fv-numeric="true"
                            data-fv-numeric___message="Not a number">
                    </div>
                </div>
                <div class="col-sm-6 col-md-6">
                    <label class="custom-address-label"> </label>
                    <div class="form-group">
                        <input type="number" name="inches" id="inches" class="form-control" placeholder="inches" v-model="height_inches"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Height is required"
                            data-fv-numeric="true"
                            data-fv-numeric___message="Not a number">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <label class="custom-address-label">Eye Color</label>
                    <div class="form-group">
                        <input type="text" name="eyes" id="eyes" class="form-control" v-model="eye_color"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Eye color is required">
                    </div>
                </div>
                <div class="col-sm-6 col-md-6">
                    <label class="custom-address-label">Race</label>
                    <div class="form-group">
                        <input type="text" name="race" id="race" class="form-control" v-model="race"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Race is required">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <label class="custom-address-label">Mother's Maiden Name</label>
                    <div class="form-group">
                        <input type="text" name="mothermaiden" id="mothermaiden" class="form-control" v-model="mother_maiden"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Mother's maiden name is required">
                    </div>
                </div>
                <div class="col-sm-6 col-md-6">
                    <label class="custom-address-label">Father's Full Name</label>
                    <div class="form-group">
                        <input type="text" name="fathername" id="fathername" class="form-control" v-model="father_name"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Father's full name is required">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <label class="custom-address-label">Former address if at current address for less than 3 years</label>
                    <div class="form-group">
                        <input type="text" name="formerstreet" id="formerstreet" class="form-control" v-model="former_street" placeholder="Street">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-4 col-md-4">
                    <input type="text" name="formercity" id="formercity" class="form-control" v-model="former_city" placeholder="City">
                </div>
                <div class="form-group col-sm-4 col-md-4">
                    <Select2 v-model="former_state" :options="stateOptions" :settings="stateSettings" :name="'formerState'" :id="'formerState'"></Select2>
                </div>
                <div class="form-group col-sm-4 col-md-4">
                    <input type="text" name="formerzip" id="formerzip" class="form-control" v-model="former_zip_code" placeholder="Zip Code"
                        data-fv-zip-code="true"
                        data-fv-zip-code___message="Invalid zip code">
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <label class="custom-address-label">Please upload a copy of your driver's license.  If you do not provide this, your CORI will not be processed.  PDF or JPG only</label>
                    <div class="form-group">
                        <input type="file" name="id" id="id" ref="license" class="form-control" @change="setFile()"
                            data-fv-not-empty="true"
                            data-fv-not-empty___message="Driver's license copy is required"
                            data-fv-file="true"
                            data-fv-file___extension="pdf,jpg,jpeg"
                            data-fv-file___type="image/jpg,image/jpeg,application/pdf"
                            data-fv-file___message="Invalid file type">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox custom-checkbox-switch">
                            <input type="checkbox" class="custom-control-input form-control" id="coriAffirm" name="coriAffirm" v-model="cori_affirm"
                                data-fv-not-empty="true"
                                data-fv-not-empty___message="CORI affirmation is required">
                            <label class="custom-control-label" for="coriAffirm">I affirm that the information provided for my CORI check is true and accurate.
                                I also affirm that I have uploaded a true and accurate copy of my driver's license.
                                I understand if any of the information is missing, my CORI request will not be processed until I complete a form in person.</label>
                        </div>
                    </div>
                </div>
            </div>

        </fieldset>
        <div class="row" v-show="appType==='memberApp'">
            <div class="col-md-12 col-sm-12">
                <p>By submitting this application, I affirm that all information contained within is true and accurate.  I also confirm my understanding of the staffing requirements described above</p>
            </div>
        </div>
        <div class="row" v-show="appType==='memberApp'">
            <div class="col-sm-12 col-md-12">
                <p>Rehoboth Ambulance uses a service called Checkr to complete background checks on applicants.  The checks performed include:</p>
                    <ul>
                        <li>National Criminal Search</li>
                        <li>Sex Offender Search</li>
                        <li>Global Watchlist Search</li>
                        <li>Employment Verification</li>
                        <li>Motor Vehicle Report</li>
                        <li>SSN Trace</li>
                        <li>FACIS Search</li>
                    </ul>
                <p>Upon submitting this application, you will receive an email from Checkr with a link to follow to complete the background check.  This check must be completed within seven (7) days or your application will be discarded.</p>
            </div>
        </div>
        <div class="form-group"><div id="captchaContainer"></div></div>
    </div>
</template>

<script>

import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import Declarative from 'formvalidation/plugins/Declarative';
import ZipCode from 'formvalidation/validators/zipCode';
import Recaptcha from 'formvalidation/plugins/Recaptcha';
import { mapFields } from 'vuex-map-fields'
import memberAppStore from "../../store";
import states from "../../states";
export default {
    name: "cori",
    //store: memberAppStore,
    mounted() {
        this.coriForm=formValidation(document.getElementById('cori'), {
            fields: {
                gender: {
                    validators: {
                        notEmpty: {
                            message: "Gender is required"
                        }
                    }
                }
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
                recaptcha: new Recaptcha({
                    element: 'captchaContainer',
                    message: 'Invalid verification',
                    siteKey: process.env.MIX_RECAPTCHA_SITE_KEYv2_robot,
                    //badge: 'bottomright',
                    theme: 'light',
                    timeout: '120',
                    //size: 'invisible',
                    //backendVerificationUrl: '/api/checkCaptcha'
                })
            }
        })
        this.coriForm.registerValidator('zipCode',ZipCode)
        this.setFields();

    },
    props: ['appType'],
    data() {
        return {
            test: {
                file: null
            },
            askcori: false,
            stateOptions: states,
            stateSettings: {
                width: "100%",
                theme: "bootstrap4",
                placeholder: "State"
            },
            genderOptions: [
                'Male',
                'Female',
                'Other',
                'Choose not to identify'
            ],
            genderSettings: {
                width: "100%",
                theme: "bootstrap4",
                minimumResultsForSearch: Infinity
            }
        }
    },
    methods: {
        setFields() {
            let coriFields=Object.keys(this.coriForm.getFields());
            if (this.askcori) {
                coriFields.forEach((field) => {
                    this.coriForm.enableValidator(field)
                })
            }
            if (!this.askcori) {
                coriFields.forEach((field) => {
                    this.coriForm.disableValidator(field)
                })
            }
        },
        revalidate(name) {
            this.coriForm.validateField(name)
        },
        async validate() {
            let result=await this.coriForm.validate()
            return result==="Valid"
        },
        setFile() {
            let file=this.$refs.license.files[0]
            memberAppStore.commit('application/uploadFile',file)
        }
    },
    computed: {
        ...mapFields('application',['' +
        'cori.cori_affirm',
        'cori.eye_color',
        'cori.father_name',
        'cori.former_city',
        'cori.former_state',
        'cori.former_street',
        'cori.former_zip_code',
        'cori.gender',
        'cori.height_feet',
        'cori.height_inches',
        'cori.last_six',
        'cori.mother_maiden',
        'cori.other_name',
        'cori.place_of_birth',
        'cori.race'])
    }

}
</script>

<style>

</style>
