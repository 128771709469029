function addCert({commit},level) {
    console.log(level)
    let row=[]
    switch(level) {
        case 0:
            //No level, remove all
            row={
                cert_type_id: null,
                cert_number: null,
                expiration_date: null,
                state: null,
                cert_path: null,
            }
            break;
        case 1:
            //Add EMT
            row={
                cert_type_id: 3,
                cert_number: null,
                expiration_date: null,
                state: 'MA',
                cert_path: null,
            }
            break;
        case 2:
            //Add Advanced
            row={
                cert_type_id: 4,
                cert_number: null,
                expiration_date: null,
                state: 'MA',
                cert_path: null,
            }
            break;
        case 3:
            //Add medic
            row={
                cert_type_id: 5,
                cert_number: null,
                expiration_date: null,
                state: 'MA',
                cert_path: null,
            }
            break
        case 4:
            //Add ACLS
            row={
                cert_type_id: 7,
                cert_number: null,
                expiration_date: null,
                state: null,
                cert_path: null,
            }
            break
        case 5:
            //Add CPR
            row={
                cert_type_id: 6,
                cert_number: null,
                expiration_date: null,
                state: null,
                cert_path: null,
            }
            break
        default:
            //Add blank row
            row={
                cert_type_id: null,
                cert_number: null,
                expiration_date: null,
                state: null,
                cert_path: null,
            }
            break
    }
    commit('ADD_CERT_ROW',row)
}

function ADD_CERT_ROW(state,row) {
    state.certifications.push(row)
}

export {addCert,ADD_CERT_ROW}
