import ROLE from "./roleEnum";

let roleCheck = {
    created() {
        this.evaluator()
        this.trainee()
    },
    data() {
        return {
            isEvaluator: 0,
            isTrainee: 0
        }
    },
    computed: {
        sysAdmin: function() {
            return ROLE.SYSADMIN
        },
        memberAdmin: function() {
            return ROLE.MEMBERADMIN
        },
        rosterAdmin: function() {
            return ROLE.ROSTERADMIN
        },
        trainingAdmin: function() {
            return ROLE.TRAININGADMIN
        },
        committeeMinutesAdmin: function() {
            return ROLE.COMMITTEEMINUTESADMIN
        },
        bodMinutesAdmin: function() {
            return ROLE.BODMINUTESADMIN
        }
    },
    methods: {
        hasRole(role) {
            return this.$store.state.roles.includes(role)
        },
        evaluator() {
            axios.get('/api/training/getLatestMemberHistory')
                .then(response=>{
                    this.isEvaluator = response.data === ROLE.PRECEPTOR;
                })
        },
        hasEvals() {
            if (this.$store.state.profile.member.evals_done>0) {
                return true
            } else {
                return false
            }
        },
        trainee() {
            axios.get('/api/training/getLatestTrainingHistory')
                .then(response=>{
                    this.isTrainee = response.data === ROLE.TRAINEE
                })
        }
    }
}


export {roleCheck}


