<template>
    <div class="card w-100 h-100">
        <div class="card-header bg-dark text-white chevron-header">
            <a data-toggle="collapse" href="#collapseDates" aria-expanded="true" aria-controls="collapseDates"
               id="datesHeading" class="text-white d-block">
                <i class="fas fa-chevron-down float-right"></i>
                Expiration Dates and Rounds Attendance
            </a>
        </div>
        <div id="collapseDates" class="collapse show collapseLinksDates" aria-labelledby="datesHeading">
            <div class="card-body">
                <div v-if="apiStateLoading" class="d-flex justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div v-else-if="apiStateError" class="alert alert-danger" role="alert">
                    Error loading data
                </div>
                <div v-else-if="apiStateLoaded">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 h6">Hello, {{ firstName }}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block">Driver's License: </span>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block text-white text-center" :class="driverColor">{{driverExpiration}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block">EMT License: </span>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block text-white text-center" :class="emtColor">{{emtExpiration}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block">CPR: </span>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block text-white text-center" :class="cprColor">{{cprExpiration}}</span>
                        </div>
                    </div>
                    <div class="row" v-show="(level===3)">
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block">ACLS: </span>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block text-white text-center" :class="aclsColor">{{aclsExpiration}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block">Sturdy Rounds: </span>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block text-center">{{sturdyRounds}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block text-tender">Other Rounds: </span>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block text-center">{{otherRounds}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block">In-house Rounds: </span>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block text-center">{{inhouseRounds}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block">Makeup rounds:</span>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block text-center">{{makeup_rounds}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block">Total Rounds: </span>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <span class="d-block text-center text-white" :class="roundsColor">{{totalRounds}} / {{requiredRounds}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import  ENUM from '../../helpers/enum'
import memberAppStore from '../../store'
import {mapFields, mapMultiRowFields} from "vuex-map-fields";
import {expDateFormat, roundsFormat} from "../../helpers/expirations";

export default {
    name: "Expirations",
    store: memberAppStore,
    mounted() {

    },
    data() {
        return {
            sturdyRounds: 0,
            otherRounds: 0,
            inhouseRounds: 0
        }
    },
    watch: {
        apiState: function() {
            if (this.apiState===ENUM.LOADED) {
                this.getRounds()
            }
        }
    },
    computed: {
        apiState() {
            return memberAppStore.getters['profile/getApiState']
        },
        firstName() {
            return memberAppStore.getters['profile/getFirstName']
        },
        apiStateLoading() {
            return this.apiState===ENUM.LOADING
        },
        apiStateLoaded() {
            return this.apiState===ENUM.LOADED
        },
        apiStateError() {
            return this.apiState===ENUM.ERROR
        },
        ...mapFields('profile',['level','member.rounds_required','member.makeup_rounds','member.id']),
        ...mapMultiRowFields('profile',['certifications']),
        requiredRounds() {
            return this.rounds_required+this.makeup_rounds
        },
        totalRounds() {
            return this.sturdyRounds+this.otherRounds+this.inhouseRounds
        },
        driverExpiration() {
            let index=this.certifications.findIndex(o=>o.cert_type_id===1)
            if (index!==-1) {
                return this.certifications[index].expiration_date
            } else {
                return "N/A"
            }
        },
        emtExpiration() {
            let index=null
            switch (this.level) {
                case 0:
                    index=-1
                    break
                case 1:
                    index=this.certifications.findIndex(o=>o.cert_type_id===3)
                    break
                case 2:
                    index=this.certifications.findIndex(o=>o.cert_type_id===4)
                    break
                case 3:
                    index=this.certifications.findIndex(o=>o.cert_type_id===5)
                    break
                default:
                    index=-1
            }
            if (index!==-1) {
                return this.certifications[index].expiration_date
            } else {
                return "N/A"
            }
        },
        cprExpiration() {
            let index=this.certifications.findIndex(o=>o.cert_type_id===6)
            if (index!==-1) {
                return this.certifications[index].expiration_date
            } else {
                return "N/A"
            }
        },
        aclsExpiration() {
            let index=this.certifications.findIndex(o=>o.cert_type_id===7)
            if (index!==-1) {
                return this.certifications[index].expiration_date
            } else {
                return "N/A"
            }
        },
        emtColor() {
            if (this.emtExpiration!=='N/A') {
                return expDateFormat(this.emtExpiration)
            } else {
                return ''
            }
        },
        cprColor() {
            if (this.cprExpiration!=='N/A') {
                return expDateFormat(this.cprExpiration)
            } else {
                return ''
            }
        },
        aclsColor() {
            if (this.aclsExpiration!=='N/A') {
                return expDateFormat(this.aclsExpiration)
            } else {
                return ''
            }
        },
        driverColor() {
            if (this.driverExpiration!=='N/A') {
                return expDateFormat(this.driverExpiration)
            } else {
                return ''
            }
        },
        roundsColor() {
            return roundsFormat(this.totalRounds,this.requiredRounds)
        }

    },
    methods: {
        getRounds() {

                axios.get('/api/getMemberRounds/'+this.id)
                    .then(response => {
                        this.sturdyRounds=response.data['sturdy']
                        this.inhouseRounds=response.data['inhouse']
                        this.otherRounds=response.data['other']
                    })
                    .catch(error => {
                        console.log(error)
                    })

        }
    }
}
</script>

<style scoped>

</style>
