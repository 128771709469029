<template>
    <div class="modal fade" id="messageSentModal" tabindex="-1" role="dialog" aria-labelledby="messageSent" aria-hidden="true" ref="messageSentModal">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-body" id="messageSentText">
                    {{output}}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" id="modalButton" v-show="messageSent" @click="dismissModal">Ok</button>
                    <div class="spinner-border text-primary" id="modalSpinner" role="status" v-show="!messageSent"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "modal",
    props: ['uid'],
    data() {
        return {
            messageSent: false,
            output: '',
        };
    },
    methods: {
        dismissModal() {
            this.$emit("hide")
            //$(this.$refs.messageSentModal).modal("hide")
            //this.$store.dispatch('profile/getProfile',this.uid)
        }
    }
}
</script>

<style scoped>

</style>
