/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

//window.Vue = require('vue').default;
//Imports
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from "vue-router";
import JsonExcel from "vue-json-excel"
import Select2 from 'v-select2-component'
import Datepicker from 'vue-bootstrap-datetimepicker'
import Tooltip from 'vue-directive-tooltip'
import VueFormWizard from 'vue-form-wizard'
import router from './router/router'
import memberAppStore from './store/'




/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

//Component registration
//const files = require.context('./', true, /\.vue$/i)
//files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
Vue.component('contact-form', require('./components/ContactForm').default);
Vue.component('cpr',require('./components/Cpr').default)
Vue.component('events',require('./components/Events').default)
Vue.component('leadership',require('./components/Leadership').default)
Vue.component('member-app',require('./components/MemberApp').default)
Vue.component('members-navigation',require('./components/MembersNavigation').default)
Vue.component('members-only',require('./components/MembersOnly').default)
Vue.component('modal',require('./components/modal').default)
Vue.component('observer-app',require('./components/ObserverApp').default)
Vue.component('Select2',Select2);
Vue.component('datepicker',Datepicker);
Vue.component('downloadExcel',JsonExcel)

//Plugins
Vue.use(Vuex)
//Vue.use(VueTabs)
Vue.use(VueFormWizard)
Vue.use(VueRouter)
Vue.use(Tooltip)


//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
//Vue.component('contact-form', require('./components/ContactForm').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    router: router,
    store: memberAppStore,
    el: '#app',
})


