<template>
    <div>
        <form id="memberapp">
            <form-wizard title="" subtitle="" @on-complete="onComplete" shape="tab" color="#152a49">
                <tab-content title="Demographics" icon="fas fa-user" :before-change="()=>beforeTabSwitch('demo')">
                    <demographics ref="demo" :appType="appType"></demographics>
                </tab-content>
                <tab-content title="Emergency Contacts" icon="fas fa-id-card" :before-change="()=>beforeTabSwitch('emergency')">
                    <contacts ref="emergency" :appType="appType"></contacts>
                </tab-content>
                <tab-content title="Certifications" icon="fas fa-id-badge" :before-change="()=>beforeTabSwitch('certs')">
                    <certs ref="certs" :appType="appType"></certs>
                </tab-content>
                <tab-content title="References" icon="fas fa-users" :before-change="()=>beforeTabSwitch('reference')">
                    <references ref="reference" :appType="appType"></references>
                </tab-content>
                <tab-content title="CORI" icon="fas fa-check-circle" :before-change="()=>beforeTabSwitch('cori')">
                    <cori ref="cori" :appType="appType"></cori>
                </tab-content>
            </form-wizard>
        </form>
        <!---MODAL--->
        <modal ref="messageModal" :redirect-url="messageRedirect"></modal>

    </div>


</template>

<script>

import memberAppStore from "../store"
import demographics from "./Applications/demographics";
import contacts from "./Applications/contacts";
import references from "./Applications/references"
import cori from "./Applications/cori";
import certs from "./Applications/certs";


export default {
    name: "MemberApp",
    components: {
        demographics,
        contacts,
        certs,
        references,
        cori
    },
    store: memberAppStore,
    created() {
        this.setAppType()
    },
    mounted() {
        console.log('MemberApp mounted')
    },
    props: ['messageRedirect'],
    data() {
        return {
            appType: 'memberApp',
        }
    },
    methods: {
        setAppType: function() {
            memberAppStore.commit('application/setAppType','memberApp')
        },
        onComplete: function() {
            let modal=this.$refs.messageModal
            modal.output="Sending your application"
            $(modal.$refs.messageSentModal).modal('show')
            let headers = {
                'Content-Type': 'multipart/form-data'
            }
            let formData=new FormData()
            let theStore=this.$store.state.application
            formData.append('data',JSON.stringify(theStore))
            formData.append('licensePhoto',theStore.license_photo)
            axios.post('/api/sendApplication', formData, { headers: headers})
            .then(function (response) {
                modal.messageSent=true
                modal.output=response.data
            })
            .catch(function(error) {
                modal.messageSent=true
                modal.output=error
            })
        },
        beforeTabSwitch: function(id) {
            let tabToValidate=this.$refs[id]
            return tabToValidate.validate()
        },
    }
}
</script>

<style scoped>

</style>
