<template>
    <div class="pt-2">
        <div class="card w-100 h-100" id="evalForm">
            <div class="card-header bg-dark text-white">Submit Evaluation</div>
            <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <div v-if="pending_evals>0" class="text-center py-2">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        Select an evaluation to complete, or submit an unsolicited evaluation for a third rider
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <Select2 v-model="pending_eval_id" :options="pendingEvalList" :settings="evalSettings" @change="getEval()"></Select2>
                                    </div>
                                </div>
                            </div>
                            <div class="card w-100 border border-dark">
                                <div class="card-header bg-primary text-white text-center">Trainee Evaluation Form</div>
                                <div class="card-body">
                                    <div class="form-row">
                                        <div class="col-sm-6 col-md-6">
                                            <div>
                                                <label class="col-sm-4 col-form-label" for="traineename">Trainee Name</label>
                                                <div class="form-group col-sm-8 col-md-8">
                                                    <Select2 v-model="trainee" :options="traineeList" :settings="nameSettings" :name="'traineename'" :id="'traineename'"></Select2>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-6">
                                            <div class="form-group">
                                                <label class="col-sm-4 col-form-label" for="preceptorname">Preceptor Name</label>
                                                <div class="col-sm-8 col-md-8">
                                                    <input type="text" v-model="preceptor" name="preceptorname" id="preceptorname" class="form-control" readonly>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-sm-6 col-md-6">
                                            <div class="form-group">
                                                <label class="col-sm-4 col-form-label" for="shiftdate">Date of shift</label>
                                                <div class="col-sm-8 col-md-8">
                                                    <div class="input-group date">
                                                        <datepicker v-model="shiftdate" class="form-control" :wrap="true" :config="dateOptions" placeholder="mm/dd/yyyy"
                                                                    :name="'shiftdate'" :id="'shiftdate'"></datepicker>
                                                        <div class="input-group-append">
                                                            <div class="input-group-text datepickerbutton">
                                                                <i class="fas fa-calendar"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-6">
                                            <div class="form-group">
                                                <label class="col-sm-4 col-form-label" for="evaldate">Date of evaluation</label>
                                                <div class="col-sm-8 col-md-8">
                                                    <div class="input-group date">
                                                        <datepicker v-model="evaldate" class="form-control" :wrap="true" :config="dateOptions" placeholder="mm/dd/yyyy"
                                                                    :name="'evaldate'" :id="'evaldate'"></datepicker>
                                                        <div class="input-group-append">
                                                            <div class="input-group-text datepickerbutton">
                                                                <i class="fas fa-calendar"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-sm-6 col-md-6">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox custom-checkbox-switch">
                                                    <input type="checkbox" class="custom-control-input form-control" name="calls" id="calls" v-model="calls">
                                                    <label class="custom-control-label" for="calls">Were there any calls on the shift?</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-6">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox custom-checkbox-switch">
                                                    <input type="checkbox" class="custom-control-input form-control" name="runreports" id="runreports" v-model="runreports">
                                                    <label class="custom-control-label" for="runreports">Were any run reports completed?</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-sm-12 col-md-12">
                                            <p>Please rate the trainee om a scale of 1 to 5 for each of the areas below.  Any rating of 2 or less requires written feedback on what caused that rating, along with recommendations for improvement.  If an item does not apply, please mark it N/A.  Please note that all feedback on this form will be provided to the trainee in an anonymous format - only the Chief, Deputy Chief and Training Officer can see who made which specific comments.  Your candid feedback is appreciated.</p>
                                            <p>All questions are required.  If any question is rated at 2 or less, the comments field for that section will become mandatory</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div v-if="answersLoading" class="d-flex justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div v-else-if="answersLoaded">
                    <div v-for="section in sectionList" class="row py-1">
                        <div class="col-sm-12 col-md-12">
                            <div class="w-100 border border-dark">
                                <div class="card-header bg-primary text-white text-center">{{ section.text }}</div>
                                <div class="card-body">
                                    <div v-for="question in questions" v-if="section.id===question.section_name">
                                        <div v-for="q in question.questions" class="row">
                                            <div class="col-sm-6 col-md-6">
                                                <label>{{q.question}}</label>
                                            </div>
                                            <div class="col-sm-6 col-md-6">
                                                <div v-if="q.question_type==='scale'">
                                                    <div class="form-group">
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" :id="'1_'+q.id" :name="addField(q.section_id,q.id,q.question_type)" class="form-check-input" :value="'1'" v-model="answers[q.id]"
                                                                   @change="setCommentsRequired(q.section_id,q.id)">
                                                            <label class="form-check-label" :for="'1_'+q.id">1</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" :id="'2_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'2'" v-model="answers[q.id]" @change="setCommentsRequired(q.section_id,q.id)">
                                                            <label class="form-check-label" :for="'2_'+q.id">2</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" :id="'3_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'3'" v-model="answers[q.id]" @change="setCommentsRequired(q.section_id,q.id)">
                                                            <label class="form-check-label" :for="'3_'+q.id">3</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" :id="'4_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'4'" v-model="answers[q.id]" @change="setCommentsRequired(q.section_id,q.id)">
                                                            <label class="form-check-label" :for="'4_'+q.id">4</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" :id="'5_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'5'" v-model="answers[q.id]" @change="setCommentsRequired(q.section_id,q.id)">
                                                            <label class="form-check-label" :for="'5_'+q.id">5</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" :id="'NA_'+q.id" :name="'S'+q.section_id+'Q'+q.id" class="form-check-input" :value="'N/A'" v-model="answers[q.id]" @change="setCommentsRequired(q.section_id,q.id)">
                                                            <label class="form-check-label" :for="'NA_'+q.id">N/A</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="q.question_type==='text'" class="form-group">
                                                    <textarea maxlength="250" class="form-control" :name="addField(q.section_id,q.id,q.question_type)" v-model="answers[q.id]"></textarea>
                                                    <span class="float-right  pull-right message-counter text-small">{{ answers[q.id].length }} / 250</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-75" id="errorMessages"></div>
                    <button class="btn btn-primary" @click="submitEval">Submit Evaluation</button>
                </div>
            </div>
        </div>
        <!---MODAL--->
        <membermodal ref="messageModal" @hide="closeModal"></membermodal>

    </div>
</template>

<script>
import ENUM from '../../../helpers/enum'
import memberAppStore from '../../../store'
import membermodal from '../MembersModal'
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger'
import Bootstrap from 'formvalidation/plugins/Bootstrap'
import Declarative from 'formvalidation/plugins/Declarative'
import Message from 'formvalidation/plugins/Message'


export default {
    name: "Evaluation",
    store: memberAppStore,
    components: {
        membermodal
    },
    data() {
        return {
            questionsLoaded: ENUM.LOADING,
            sectionsLoaded: ENUM.LOADING,
            answersSet: ENUM.LOADING,
            pending_eval_id: null,
            pendingEvalList: [],
            evalSettings: {
                width: "25%",
                theme: "bootstrap4"
            },
            evalId: '0',
            trainee: '',
            traineeList: [],
            nameSettings: {
                width: "100%",
                theme: "bootstrap4"
            },
            shiftdate:'',
            evaldate:'',
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
                allowInputToggle: false
            },
            runreports: false,
            calls: false,
            sectionList:[],
            questions:[],
            answers:{}
        }
    },
    created() {
        this.getPendingEvals()
        this.getQuestions()
        this.getSections()
        this.getTrainees()
    },
    mounted() {
        this.scaleValidator={
            validators: {
                notEmpty: {
                    message: "Rating is required"
                }
            }
        }
        this.commentValidator={
            validators: {
                notEmpty: {
                    enabled: false,
                    message: "Comments are required"
                },
                stringLength: {
                    enabled: true,
                    max: 250,
                    message: 'Comment field is limited to 250 characters',
                }
            }
        }
        this.traineeValidator={
            validators: {
                notEmpty: {
                    message: "Trainee is required"
                }
            }
        }
        this.dateValidator={
            validators: {
                notEmpty: {
                    message: "Date is required"
                },
                date: {
                    format: 'MM/DD/YYYY',
                    message: 'Invalid date'
                }
            }
        }
        this.fv=formValidation(document.getElementById('evalForm'), {
            fields: {
                traineename: this.traineeValidator,
                shiftdate: this.dateValidator,
                evaldate: this.dateValidator
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true,
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),

            }
        })

    },
    computed: {
        preceptor() {
            let first_name=memberAppStore.getters['profile/getFirstName']
            let last_name=memberAppStore.getters['profile/getLastName']
            return first_name+" "+last_name
        },
        preceptor_id() {
            return memberAppStore.getters['profile/getMemberId']
        },
        pending_evals() {
            return memberAppStore.getters['profile/getPendingEvals']
        },
        apiState() {
            if (this.questionsLoaded===ENUM.LOADED && this.sectionsLoaded===ENUM.LOADED) {
                return ENUM.LOADED
            } else {
                return ENUM.LOADING
            }
        },
        answersLoading() {
            return this.answersSet===ENUM.LOADING
        },
        answersLoaded() {
            return this.answersSet===ENUM.LOADED
        },

    },
    watch: {
        apiState: function() {
            if (this.apiState===ENUM.LOADED) {
                this.buildAnswers()
            }
        }
    },
    methods: {
        fieldLength(textField) {
            console.log(textField)
            console.log(textField.length)
            return textField.length
        },
        getTrainees() {
            axios.get('/api/training/getTrainees')
            .then(response =>{
                response.data.data.forEach(item=> {
                    this.traineeList.push(item)
                })
            })
            .catch(error => {
                console.log(error)
            })
        },
        getQuestions() {
            this.questions=[]
            axios.get('/api/training/getQuestions')
                .then(response => {
                    response.data.data.forEach(item => {
                        this.questions.push(item)
                    })
                    this.questionsLoaded=ENUM.LOADED
                }).catch(error => {
                console.log(error)
            })
        },
        addField(section_id,question_id,question_type) {
            let fieldName
            let validator
            if (question_type==="scale") {
                fieldName='S'+section_id+'Q'+question_id
                validator=this.scaleValidator
                this.fv.addField(fieldName,validator)
            }
            if (question_type==="text") {
                fieldName='S'+section_id+'Q'+question_id+'_text'
                validator=this.commentValidator
                this.fv.addField(fieldName,validator)
            }
            return fieldName
        },
        setCommentsRequired(section_id) {
            let section_answers=[]
            let index=this.questions.findIndex(q => q.section_id === section_id)
            this.questions[index].questions.forEach(q => {
                section_answers.push(this.answers[q.id])
            })
            let commentIndex=this.questions[index].questions.findIndex(q=>q.question_type==='text')
            let commentId=this.questions[index].questions[commentIndex].id
            let fieldName='S'+section_id+'Q'+commentId+'_text'
            let validator=this.commentValidator
            if (section_answers.includes('1') || section_answers.includes('2')) {
                this.fv.enableValidator(fieldName,'notEmpty')
            } else {
                this.fv.resetField(fieldName)
                this.fv.disableValidator(fieldName,'notEmpty')
            }
            this.fv.revalidateField(fieldName)
        },
        buildAnswers() {
            this.questions.forEach(section=> {
                let question=section.questions
                question.forEach(q=> {
                    let prop=q.id
                    //this.answers[prop]=''
                    this.answers= {
                        ...this.answers,
                        [prop]: ''
                    }
                })
            })
            this.answersSet=ENUM.LOADED
        },
        getSections() {
            axios.get('/api/training/getSections')
                .then(response => {
                    response.data.data.forEach(item => {
                        this.sectionList.push(item)
                    })
                    this.sectionsLoaded=ENUM.LOADED
                }).catch(error => {
                console.log(error)
            })
        },
        submitEval() {
            this.fv.validate().then(status => {
                if (status==="Valid") {
                    let formData=new FormData
                    this.$refs.messageModal.output="Submitting evaluation"
                    $(this.$refs.messageModal.$refs.messageSentModal).modal('show')
                    formData.append('eval_id',this.evalId)
                    formData.append('trainee_id',this.trainee)
                    formData.append('evaluator_id',this.preceptor_id)
                    formData.append('calls_on_shift',this.calls)
                    formData.append('run_reports_completed',this.runreports)
                    formData.append('shift_date',this.shiftdate)
                    formData.append('eval_date',this.evaldate)
                    formData.append('answers',JSON.stringify(this.answers))
                    axios.post('api/training/submitEval',formData)
                        .then(response=>{
                            this.$refs.messageModal.messageSent=true
                            this.$refs.messageModal.output=response.data.message
                            memberAppStore.dispatch('profile/updatePendingEvals',response.data.pending_evals)
                        })
                        .catch(error => {
                            this.$refs.messageModal.messageSent=true
                            this.$refs.messageModal.output=error
                        })
                }
            })
        },
        closeModal() {
            this.$refs.messageModal.messageSent=false
            $(this.$refs.messageModal.$refs.messageSentModal).modal('hide')
            this.$router.push({name: 'profile'})
        },
        getEval() {
            axios.get('/api/training/getEvalToSubmit/'+this.pending_eval_id)
            .then(response=> {
                console.log(response.data.data)
                this.evalId=response.data.data.id
                this.trainee=response.data.data.trainee_id
                this.calls=response.data.data.calls_on_shift
                this.runreports=response.data.data.run_reports_completed
                this.shiftdate=response.data.data.shift_date
            })
        },
        getPendingEvals() {
            axios.get('/api/training/getPendingEvals')
            .then(response => {
                response.data.data.forEach(item => {
                    this.pendingEvalList.push(item)
                })
            })
            .catch (error => {
                console.log(error)
            })
        }
    }
}
</script>

<style scoped>

</style>
