//This module is a subset of the database module used for an individual member's profile.
// Needed since there may be conflicts between the logged in user and the database functions
import {getField,updateField} from "vuex-map-fields"
import ENUM from '../helpers/enum'
import {addCert,ADD_CERT_ROW} from "../helpers/storeFunctions";

let DefaultState = () => {
    return {
        apiState: ENUM.INIT,
        uid: '',
        level: 0,
        member: [],
        phones: [],
        addresses: [],
        emergencyContacts: [],
        certifications: [],
        positions: [],
        histories: [],
        courses: [],
        cert_images: {}
    }
}

export default {
    namespaced: true,
    state: {
        apiState: ENUM.INIT,
        uid: '',
        level: 0,
        member: [],
        phones: [],
        addresses: [],
        emergencyContacts: [],
        certifications: [],
        positions: [],
        histories: [],
        courses: [],
        cert_images: {}

    },
    getters: {
        getField,
        getApiState: (state) => {
            return state.apiState
        },
        getFirstName: (state) => {
            return state.member.first_name
        },
        getLastName: (state) => {
            return state.member.last_name
        },
        getMemberId: (state) => {
            return state.member.id
        },
        getPendingEvals: (state) => {
            return state.member.open_evals
        },
        getEvalsDone: (state) => {
            return state.member.evals_done
        }
    },
    actions: {
        updatePendingEvals({commit},evals) {
            commit('UPDATE_PENDING_EVALS',evals)
        },
        resetProfile({commit}) {
            commit('RESET_PROFILE_STATE')
        },
        addCert,
        removeCert({commit},index) {
            commit('DELETE_CERT_ROW',index)
        },
        async getProfile({commit}, uid) {
            commit('RESET_PROFILE_STATE')
            commit('SET_UID',uid)
            commit('SET_API_STATE',ENUM.LOADING)
            if (uid===null) {
                commit('SET_API_STATE',ENUM.ERROR)
            } else {
                await axios.get('api/getProfile/'+uid).then(result=> {
                    let profile=result.data.profile
                    let keys=Object.keys(profile)

                    keys.forEach(k=> {
                        if (k==="member") {
                            commit('ADD_MEMBER',profile[k])
                        } else {
                            profile[k].forEach(data => {
                                commit('ADD_MEMBER_DATA',{k,data})
                            })
                        }
                    })
                    if (profile.phones.length===0) {
                        commit('addPhone')
                    }
                    if (profile.emergencyContacts.length===0) {
                        commit('addContact')
                    }
                    if (profile.certifications.length===0) {
                        let row=[{
                            cert_type_id: 1,
                            cert_number: null,
                            expiration_date: null,
                            state: null,
                            cert_path: null,
                        }]
                        commit('ADD_CERT_ROW',row[0])
                    }
                    let level=0

                    if (profile.certifications.filter(cert=>cert.cert_type_id===3).length>0) {
                        level=1
                    }
                    if (profile.certifications.filter(cert=>cert.cert_type_id===4).length>0) {
                        level=2
                    }
                    if (profile.certifications.filter(cert=>cert.cert_type_id===5).length>0) {
                        level=3
                    }
                    commit('SET_LEVEL',level)

                    commit('SET_API_STATE',ENUM.LOADED)
                }).catch(error => {
                    console.log(error)
                    commit('SET_API_STATE',ENUM.ERROR)
                })
            }
        },
    },
    mutations: {
        updateField,
        UPDATE_PENDING_EVALS(state,evals) {
            state.member.open_evals=evals
        },
        RESET_PROFILE_STATE(state) {
            Object.assign(state,DefaultState())
        },
        ADD_CERT_IMAGE(state,{fieldName,file}) {
            state.cert_images[fieldName]=file
        },
        SET_UID(state,uid) {
            state.uid=uid
        },
        SET_LEVEL(state,level) {
            state.level=level
        },
        SET_API_STATE(state,apiState) {
            state.apiState=apiState
        },
        ADD_MEMBER_DATA(state,{k,data}) {
            state[k].push(data)
        },
        ADD_MEMBER(state,member) {
            state.member=member
        },
        ADD_ADDRESS(state,addresses) {
            addresses.forEach(address =>
                state.addresses.push(address)
            );
        },
        ADD_CERT_ROW,
        DELETE_CERT_ROW(state,index) {
            state.certifications.splice(index,1)
        },
        addPhone(state) {
            state.phones.push({
                phone_type: null,
                phone_number: null
            })
        },
        deletePhone(state, index) {
            state.phones.splice(index,1)
            //this.phones.splice(index,1);
        },
        addContact(state) {
            state.emergencyContacts.push({
                relationship: null,
                first_name: null,
                last_name: null,
                street: null,
                city: null,
                state: null,
                zip_code: null,
                phone: null
            })
        },
        removeContact(state, index) {
            state.emergencyContacts.splice(index,1)
        },
    }
}
