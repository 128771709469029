<template>
    <div class="pt-2" v-if="hasRole(sysAdmin)">
        <div class="card h-100 w-100">
            <div class="card-header bg-dark text-white">Database Table Admin</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-12 text-center">
                        <label class="custom-address-label">Select a database table</label>
                        <Select2 v-model="tableChoice" :options="tableOptions" :settings="tableSettings" @change="getTable"></Select2>
                    </div>
                </div>
                <fieldset class="field-border" v-if="tableChoice!==''">
                    <legend class="field-border">{{table_name}}</legend>
                    <table class="table table-bordered table-striped" v-if="apiLoaded">
                        <thead class="bg-dark">
                        <tr>
                            <th v-for="col in columns" :class="'text-white'">{{col}}</th><th>Add/Remove</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row,index) in rows" :key="index">
                            <td v-for="col in columns">
                                <input type="text" v-model="row[col]" class="form-control" :readonly="row['default_field']===1 || (col==='id' || col==='default_field')">
                            </td>
                            <td>
                                <i class="alert-danger fas fa-minus-circle" @click="remove(index)" v-show="(index || (!index && rows.length >1)) && (row['default_field']!==1) && (deleting!==index)"></i>
                                <div class="spinner-border spinner-border-sm text-danger" role="status" v-show="(index || (!index && rows.length >1)) && (row['default_field']!==1) && (deleting===index)"><span class="sr-only">Deleting...</span></div>
                                <i class="alert-success fas fa-plus-circle" @click="add(index)" v-show="index===rows.length-1"></i>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="row float-right" v-if="apiLoaded">
                        <div class="col-sm-12 col-md-12">
                            <button class="btn btn-dark" @click="updateTable">Update Table</button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" v-if="apiLoading">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <!---MODAL--->
        <membermodal ref="messageModal" @hide="closeModal"></membermodal>
    </div>
    <div class="pt-2" v-else>
        <div class="card w-100 h-100">
            <div class="card-header bg-danger text-white">ACCESS DENIED</div>
            <div class="card-body"></div>
        </div>
    </div>
</template>

<script>
import {roleCheck} from "../../../helpers/rolecheck";
import membermodal from '../MembersModal'
import ENUM from '../../../helpers/enum'

export default {
    name: "Admin",
    mixins: [roleCheck],
    components: {
        membermodal
    },
    data() {
        return {
            tableChoice: '',
            tableOptions: [
                {id: 'CertType', text: 'Certification Types'},
                {id: 'CourseType', text: 'Course Types'},
                {id: 'MembershipEvent', text: 'Employment History'},
                {id: 'TrainingEvent', text: 'Training History'},
                {id: 'Position', text: 'Committee Positions'},
            ],
            tableSettings: {
                width: '25%',
                theme: 'bootstrap4'
            },
            rows: [],
            fixedRows:0,
            deleting: -1,
            apiState: ENUM.LOADING
        }
    },
    computed: {
        apiLoaded() {
            return this.apiState===ENUM.LOADED
        },
        apiLoading() {
            return this.apiState===ENUM.LOADING
        },
        apiError() {
            return this.apiState===ENUM.ERROR
        },
        table_name() {
            if (this.tableChoice!=='') {
                let name=this.tableOptions.find(o=>o.id===this.tableChoice)
                return name['text']
            } else {
                return false
            }
        },
        "columns": function columns() {
            if (this.rows.length===0) {
                return []
            }
            return Object.keys(this.rows[0])
        },
    },
    methods: {
        getTable() {
            this.apiState=ENUM.LOADING
            axios.get('/api/admin/getTable/'+this.tableChoice)
            .then(response => {
                this.rows=[]
                let fRow=0
                response.data.forEach(row => {
                    this.rows.push(row)
                    if (row.default_field===1) {
                        fRow++
                    }
                    this.fixedRows=fRow
                })
                this.apiState=ENUM.LOADED
            })
        },
        add(index) {
            let newRow={}
            newRow['id']=index+2
            newRow[this.columns[1]]=''
            newRow['default_field']=0
            this.rows.push(newRow)
        },
        remove(index) {
            this.deleting=index
            let pid=this.rows[index].id
            axios.delete('/api/admin/deleteAdminRow/'+this.tableChoice+'/'+pid)
            .then(response => {
                this.rows.splice(index,1)
                this.deleting=-1
            })
            .catch (error => {
                console.log(error)
                this.deleting=-1
            })
        },
        updateTable() {
            let modal=this.$refs.messageModal
            let formData = new FormData
            formData.append('model',this.tableChoice)
            formData.append('fields',JSON.stringify(this.rows))
            modal.output="Updating table"
            $(modal.$refs.messageSentModal).modal('show')
            axios.post('/api/admin/updateAdminRow',formData)
            .then(response=>{
                modal.messageSent=true
                modal.output=response.data
            })
            .catch(error=> {
                modal.messageSent=true
                modal.output=error
            })
        },
        closeModal() {
            let modal=this.$refs.messageModal
            this.rows=[]
            this.tableChoice=''
            modal.messageSent=false
            $(modal.$refs.messageSentModal).modal('hide')
        }
    }

}
</script>

<style scoped>

</style>
