<template>
    <div class="pt-2" v-if="hasRole(rosterAdmin) || hasRole(memberAdmin) || hasRole(sysAdmin)">
        <div class="card w-100 h-100">
            <div class="card-header bg-dark text-white">Expiration Dates Report</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-12 text-center">
                        <download-excel class="btn btn-success" :data="rows" type="csv" name="expirations.csv">
                            Download Excel
                        </download-excel>
                        <button class="btn btn-primary" @click="download">Download PDF</button>
                    </div>
                </div>
                <div ref="expirations" id="expirations">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 text-center h3">Expiration Dates Report</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 text-center">
                            <table class="table table-bordered table-striped">
                                <thead class="bg-dark">
                                <tr>
                                    <th v-for="col in columns" :class="'text-white'">{{col}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="row in rows">
                                    <td v-for="col in columns" :class="setBg(row[col])">{{row[col]}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pt-2" v-else>
        <div class="card w-100 h-100">
            <div class="card-header bg-danger text-white">ACCESS DENIED</div>
            <div class="card-body"></div>
        </div>
    </div>
</template>

<script>
import html2pdf from 'html2pdf.js'
import {expDateFormat} from "../../helpers/expirations";
import {roleCheck} from "../../helpers/rolecheck";
import memberAppStore from "../../store"
import moment from 'moment'

export default {
    name: "ExpirationReport",
    mixins: [roleCheck],
    store: memberAppStore,
    created() {
        this.getExpirations()
    },
    data() {
        return {
            rows: []
        }
    },
    computed: {
        "columns": function columns() {
            if (this.rows.length===0) {
                return []
            }
            return Object.keys(this.rows[0])
        },
    },
    methods: {
        setBg(date) {
            if (moment(date, "MM/DD/YYYY",true).isValid()) {
                return expDateFormat(date)
            } else {
                return ''
            }
        },
        getExpirations() {
            axios.get('/api/expirationDates')
                .then(response => {
                    response.data.data.forEach(row =>{
                        if (row!==null) {
                            this.rows.push(row)
                        }
                    })
                })
        },
        download() {
            let opt= {
                margin: 10,
                filename: 'expirations.pdf'
            }
            let element=document.getElementById('expirations')
            html2pdf().set(opt).from(element).save()
        }
    }
}
</script>

<style scoped>

</style>
