<template>
    <div id="rosters">
        <div class="row h-100">
            <div class="col-sm-12 col-md-12 text-center">
                <table class="table table-bordered table-striped">
                    <thead class="bg-dark">
                    <tr>
                        <th class="text-white">Course Name</th>
                        <th class="text-white">Course Date</th>
                        <th class="text-white">Approval Number</th>
                        <th class="text-white">Course Roster</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="course in courses">
                        <td>{{course.course_name}}</td>
                        <td>{{course.course_date}}</td>
                        <td>{{course.approval_number}}</td>
                        <td><span v-show="course.roster_path!=='N/A'">
                            <a :href="course.roster_path" target="_blank"><i class="fas fa-download"></i></a>
                        </span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
import { mapMultiRowFields } from 'vuex-map-fields'
export default {
    name: "rosters",
    computed: {
        ...mapMultiRowFields('database',['courses'])
    }
}
</script>

<style scoped>

</style>
