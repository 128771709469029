import moment from "moment"

function roundsFormat(attended,total) {
    let half=Math.floor(total/2);
    let bgclass="";
    if (attended < half && attended < total) {
        bgclass="bg-danger";
    } else if (attended >= half && attended < total) {
        bgclass="bg-warning";
    } else {
        bgclass="bg-success";
    }
    return bgclass;
}

function expDateFormat(expDate) {
    let bgclass="";
    //convert to Date object
    expDate=moment(expDate,"MM/DD/YYYY")
    let e=expDate
    //get today
    let today=moment()
    //get 60 days earlier from expiration
    let minus60=moment(e, 'MM/DD/YYY').subtract(60,'d')
    //Check if expDate is greater than today
    if (today.isAfter(expDate)) {
        bgclass="bg-danger";
    } else if (today.isBetween(minus60,expDate)) {
        bgclass="bg-warning";
    } else if (today.isBefore(minus60)) {
        bgclass="bg-success";
    }

    return bgclass;
}

export {roundsFormat}
export {expDateFormat}
