<template>
<div id="certs">
    <div class="row h-100" v-show="appType==='memberApp'">
        <div class="col-sm-6 col-md-6 align-self-center">
            <div>
                <div class="custom-control custom-checkbox custom-checkbox-switch">
                    <input type="checkbox" v-model="isProvider" class="custom-control-input" name="isProvider" id="isProvider" @change="setProvider">
                    <label class="custom-control-label" for="isProvider">Are you a Massachusetts licensed EMS provider?</label>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-6">
            <div class="form-group" v-show="isProvider">
                <label class="custom-address-label">What is your level of training?</label>
                <Select2 v-model="level" :options="levelOptions" :settings="levelSettings" :name="'level'" @change="addCerts"></Select2>
            </div>
        </div>
    </div>

    <fieldset class="field-border">
        <legend class="field-border">Certifications/Licenses</legend>
        <div class="row">
            <div class="col-sm-3 col-md-3 d-flex justify-content-center font-weight-bold">Type</div>
            <div class="col-sm-3 col-md-3 d-flex justify-content-center font-weight-bold">Number</div>
            <div class="col-sm-2 col-md-2 d-flex justify-content-center font-weight-bold">Expiration</div>
            <div class="col-sm-3 col-md-3 d-flex justify-content-center font-weight-bold">State of Issue</div>
            <div class="col-sm-1 col-md-1"></div>
        </div>
        <div class="form-row" v-for="(certification, index) in certifications" :key="index">
            <div class="form-group col-sm-3 col-md-3">
                <Select2 v-model="certification.cert_type_id" :options="certTypes" :settings="certSettings" :name="'certification['+index+'].type'"
                         :id="'certification['+index+'].type'" @change="setCerts('certification['+index+'].type',index)"></Select2>
            </div>
            <div class="form-group col-sm-3 col-md-3">
                <input type="text" v-model="certification.cert_number" class="form-control" :name="'certification['+index+'].number'" :id="'certification['+index+'].number'">
            </div>
            <div class="form-group col-sm-2 col-md-2 selectContainer">
                <div class="input-group date">
                    <datepicker v-model="certification.expiration_date" class="form-control" :wrap="true" :config="dateOptions" placeholder="mm/dd/yyyy"
                                :name="'certification['+index+'].expiration'"
                                :id="'certification['+index+'].expiration'"
                                @change="revalidate('certification['+index+'].expiration')"></datepicker>
                    <div class="input-group-append">
                        <div class="input-group-text datepickerbutton">
                            <i class="fas fa-calendar"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-sm-3 col-md-3">
                <Select2 v-model="certification.state" :options="stateList" :settings="stateSettings" :name="'certification['+index+'].state'"
                         :id="'certification['+index+'].state'"
                         @change="revalidate('certification['+index+'].state')"></Select2>
            </div>
            <div class="col-sm-1 col-md-1">
                <div v-show="appType==='memberApp'">
                    <i class="alert-danger fas fa-minus-circle" @click="remove(index)" v-show="showRemove(index)"></i>
                    <i class="alert-success fas fa-plus-circle" @click="add(index)" v-show="index === certifications.length-1"></i>
                </div>
            </div>
        </div>
        <div class="row" v-show="appType==='observerApp'">
            <div class="col-md-12 col-sm-12">
                <label class="custom-address-label">Please upload a copy of your CPR card.  You must also have it with you during your ride along.  PDF or JPG only</label>
                <div class="form-group">
                    <input type="file" name="cprcard" id="cprcard" ref="cprcard" class="form-control" @change="setCprCard()"
                           data-fv-not-empty="true"
                           data-fv-not-empty___message="CPR Card is required"
                           data-fv-file="true"
                           data-fv-file___extension="pdf,jpg,jpeg"
                           data-fv-file___type="image/jpg,image/jpeg,application/pdf"
                           data-fv-file___message="Invalid file type">
                </div>
            </div>
        </div>
        <div class="row" v-show="appType==='observerApp'">
            <div class="col-sm-12 col-md-12">
                <label class="custom-address-label">Please provide your preferred dates for a ride along</label>
                <div class="form-group">
                    <textarea class="form-control" name="dates" id="dates" row="4" v-model="ride_along_dates"
                              data-fv-not-empty="true"
                              data-fv-not-empty___message="Ride along dates are required"></textarea>
                </div>
            </div>
        </div>
    </fieldset>
</div>
</template>

<script>
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import Declarative from 'formvalidation/plugins/Declarative';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
import memberAppStore from "../../store";
import states from "../../states";


export default {
    name: "certs",
    //store: memberAppStore,
    mounted() {
        this.levelValidator={
            validators: {
                notEmpty: {
                    message: "Provider level is required"
                }
            }
        }
        this.typeValidator={
            validators: {
                notEmpty: {
                    message: "Cert. type is required"
                }
            }
        }
        this.stateValidator={
            validators: {
                notEmpty: {
                    message: "State of issuance is required"
                }
            }
        }
        this.numberValidator={
            validators: {
                notEmpty: {
                    message: "Cert. number is required"
                }
            }
        }
        this.expirationValidator={
            validators: {
                notEmpty: {
                    message: "Expiration date is required"
                },
                date: {
                    format: 'MM/DD/YYYY',
                    message: 'Invalid date'
                }
            }
        }
        this.certs=formValidation(document.getElementById('certs'), {
            fields: {
                'certification[0].type': this.typeValidator,
                'certification[0].number': this.numberValidator,
                'certification[0].expiration': this.expirationValidator,
                'certification[0].state': this.stateValidator,
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true,
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap()
            }
        })
        if (this.appType==="memberApp") {
            this.$set(this.certifications[0],'cert_type_id',1)
            document.getElementById('certification[0].type').disabled=true
            this.certs.removeField('dates')
            this.certs.removeField('cprcard')
        }
        if (this.appType==="observerApp") {
            this.$set(this.certifications[0],'cert_type_id',6)
            document.getElementById('certification[0].type').disabled=true
            document.getElementById('certification[0].number').disabled=true
            document.getElementById('certification[0].state').disabled=true
            this.certs.removeField('certification[0].number')
            this.certs.removeField('certification[0].state')
        }


    },
    data() {
        return {
            certTypes: [],
            certSettings: {
                width: "100%",
                theme: "bootstrap4",
                minimumResultsForSearch: Infinity
            },
            stateList: states,
            stateSettings: {
                width: "100%",
                theme: "bootstrap4"
            },
            isProvider: false,
            level: '',
            levelOptions: [
                {id: 1, text:'Basic'},
                {id: 2, text: 'Advanced'},
                {id: 3, text:'Paramedic'}
            ],
            levelSettings: {
                width: "100%",
                theme: "bootstrap4",
                minimumResultsForSearch: Infinity
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
                allowInputToggle: false
            }
        }
    },
    created() {
        this.getCertTypes()
    },
    props: ['appType'],
    computed: {
        ...mapFields('application',['ride_along_dates']),
        ...mapMultiRowFields('application',['certifications']),
    },
    methods: {
        showRemove(index) {
            let lvl=true
            let i=index || (!index && this.certifications.length >1)
            let l=this.certifications[index].cert_type_id
            if (l==="1") {
                return false
            }
            if (l!=="1" && this.level==0) {
                lvl=true
            }
            switch(this.level) {
                case "1":
                case "2":
                    if (l==="3" || l==="6") {
                        lvl=false
                    }
                    break
                case "3":
                    if (l==="5" || l==="6" || l==="7") {
                        lvl=false
                    }
                    break
                default:
                    lvl=true
            }
            return i && lvl
        },
        addCerts() {
            this.certs.validateField('level')
            //First get index values for each of the certification types EMT(3), Advanced(4), Paramedic(5), CPR(6), ACLS(7)
            //If it doesn't exist, the value will be -1
            let emt=this.certifications.findIndex(function (certification) {return certification.cert_type_id==="3"})
            let advanced=this.certifications.findIndex(function (certification) {return certification.cert_type_id==="4"})
            let medic=this.certifications.findIndex(function (certification) {return certification.cert_type_id==="5"})
            let cpr=this.certifications.findIndex(function (certification) {return certification.cert_type_id==="6"})
            let acls=this.certifications.findIndex(function (certification) {return certification.cert_type_id==="7"})

            //Now lets figure out if we need to rename, add or delete fields based on the selected provider level
            let toAdd=0
            let toRemove=0
            let level=this.level
            switch (level) {
                case "1":
                    //Check if EMT and CPR are already here
                    if (emt!==-1 && cpr!==-1) {
                        //Do nothing
                        break;
                    }
                    //Check if advanced EMT is already present and if so change it to EMT
                    if (advanced!==-1) {
                        this.$set(this.certifications[advanced], "cert_type_id", "3")
                        break;
                    }
                    //Check if medic exists and change it to EMT, then delete ACLS
                    if (medic!==-1) {
                        toRemove=acls
                        this.$set(this.certifications[medic], "cert_type_id", "3")
                        memberAppStore.commit('application/removeCert', toRemove)
                        break;
                    }
                    //Ok.. need to add it
                    toAdd=level
                    memberAppStore.dispatch('application/addCert',toAdd)
                    break;
                case "2":
                    //Check if Advanced and CPR are already here
                    if (advanced!==-1 && cpr!==-1) {
                        //Do nothing
                        break;
                    }
                    //Check if EMT is here, if so change it to Advanced
                    if (emt!=-1) {
                        this.$set(this.certifications[emt], "cert_type_id", "4")
                        break;
                    }
                    //Check if medic exists and change it to advanced, then delete ACLS
                    if (medic!==-1) {
                        this.$set(this.certifications[medic], "cert_type_id", "4")
                        toRemove=acls
                        memberAppStore.commit('application/removeCert',toRemove)
                        break;
                    }
                    toAdd=level
                    memberAppStore.dispatch('application/addCert',toAdd)
                    break;
                case "3":
                    //Check if medic, cpr and acls are already here
                    if (medic!==-1 && cpr!==-1 && acls!==-1)
                        //Do nothing
                        break;
                    //Check if EMT is here, if so change it to medic and add ACLS
                    if (emt!==-1) {
                        toAdd="4"
                        this.$set(this.certifications[emt], "cert_type_id", "5")
                        memberAppStore.dispatch('application/addCert',toAdd)
                        break;
                    }
                    //Check if advanced is here, if so change it to medic and add ACLS
                    if (advanced!==-1) {
                        toAdd="4"
                        this.$set(this.certifications[advanced],"cert_type_id","5")
                        memberAppStore.dispatch('application/addCert',toAdd)
                        break;
                    }
                    toAdd=level
                    memberAppStore.dispatch('application/addCert',toAdd)
                    break;
                default:
                    //Nothing found so add what's needed
                    toAdd=level
                    memberAppStore.dispatch('application/addCert',toAdd)
                    break;
            }
            let certKeys=Object.keys(this.certifications)
            let certType=''
            let fv=this.certs
            let certobject=this.certifications
            let tv=this.typeValidator
            let nv=this.numberValidator
            let ev=this.expirationValidator
            let sv=this.stateValidator

            let valFields=Object.keys(this.certs.getFields())
            //Remove the validators
            valFields.forEach((field) => {
                if (field!=="level") {
                   fv.removeField(field)
                }
            })

            let element=async function() {
                await document.getElementsByName('certification[1].type')
                return "Success"
            }
            element().then(function() {
                certKeys.forEach((item) => {
                certType=certobject[item].cert_type_id
                if (certType==1) {
                    fv.addField('certification['+item+'].type',tv)
                    fv.addField('certification['+item+'].number',nv)
                    fv.addField('certification['+item+'].expiration',ev)
                    fv.addField('certification['+item+'].state',sv)
                    document.getElementById('certification['+item+'].type').disabled=true
                    document.getElementById('certification['+item+'].number').disabled=false
                    document.getElementById('certification['+item+'].expiration').disabled=false
                    document.getElementById('certification['+item+'].state').disabled=false
                }
                if (certType==2) {
                   fv.addField('certification['+item+'].type',tv)
                    fv.addField('certification['+item+'].number',nv)
                    fv.addField('certification['+item+'].expiration',ev)
                    document.getElementById('certification['+item+'].type').disabled=false
                    document.getElementById('certification['+item+'].number').disabled=false
                    document.getElementById('certification['+item+'].expiration').disabled=false
                    document.getElementById('certification['+item+'].state').disabled=true

                }
                if ( certType==3 || certType==4 || certType==5) {
                        fv.addField('certification['+item+'].type',tv)
                        fv.addField('certification['+item+'].number',nv)
                        fv.addField('certification['+item+'].expiration',ev)
                        document.getElementById('certification['+item+'].type').disabled=true
                        document.getElementById('certification['+item+'].number').disabled=false
                        document.getElementById('certification['+item+'].expiration').disabled=false
                        document.getElementById('certification['+item+'].state').disabled=true

                    }
                if (certType==6 || certType==7) {
                    fv.addField('certification['+item+'].type',tv)
                    fv.addField('certification['+item+'].expiration',ev)
                    document.getElementById('certification['+item+'].type').disabled=true
                    document.getElementById('certification['+item+'].number').disabled=true
                    document.getElementById('certification['+item+'].expiration').disabled=false
                    document.getElementById('certification['+item+'].state').disabled=true
                }
            })
            })

        },
        getCertTypes() {
            axios.get('/api/getCertTypes')
            .then((response) => {
                this.certTypes=response.data
            })
        },
        setProvider() {
            if (this.isProvider) {
                this.certs.addField('level',this.levelValidator)
            } else {
                this.certs.removeField('level')
            }
            return this.isProvider
        },
        revalidate(name) {
            this.certs.validateField(name)

        },
        async validate() {
            let result=await this.certs.validate()
            return result==="Valid"
        },
        add(index) {
            let newIndex=index+1
            memberAppStore.dispatch('application/addCert', "0")
            let tv=this.typeValidator
            let fv=this.certs
            let element=async function(newIndex) {
                await document.getElementsByName('certification['+newIndex+'].type')
                return "Success"
            }
            element().then(function() {
                fv.addField('certification['+newIndex+'].type',tv)
            })
        },
        remove(toRemove) {
            memberAppStore.commit('application/removeCert', toRemove)
        },
        setCerts(name,index) {
            this.certs.validateField(name)
            let certType=this.certifications[index].cert_type_id
            let valFields=Object.keys(this.certs.getFields())
            switch (certType) {
                case "1":
                    this.certs.addField('certification['+index+'].number',this.numberValidator)
                    this.certs.addField('certification['+index+'].expiration',this.expirationValidator)
                    this.certs.addField('certification['+index+'].state',this.stateValidator)
                    document.getElementById('certification['+index+'].number').disabled=false
                    document.getElementById('certification['+index+'].expiration').disabled=false
                    document.getElementById('certification['+index+'].state').disabled=false
                    break;
                case "2":
                case "3":
                case "4":
                case "5":
                    this.certs.addField('certification['+index+'].number',this.numberValidator)
                    this.certs.addField('certification['+index+'].expiration',this.expirationValidator)
                    document.getElementById('certification['+index+'].number').disabled=false
                    document.getElementById('certification['+index+'].expiration').disabled=false
                    document.getElementById('certification['+index+'].state').disabled=true
                    if (valFields.includes('certification['+index+'].state')) {
                        this.certs.removeField('certification['+index+'].state')
                    }
                    this.certifications[index].state="MA"
                    break;
                case "6":
                case "7":
                     this.certs.addField('certification['+index+'].expiration',this.expirationValidator)
                     document.getElementById('certification['+index+'].state').disabled=true
                     document.getElementById('certification['+index+'].number').disabled=true
                     document.getElementById('certification['+index+'].expiration').disabled=false
                     if (valFields.includes('certification['+index+'].state')) {
                        this.certs.removeField('certification['+index+'].state')
                    }
                    if (valFields.includes('certification['+index+'].number')) {
                        this.certs.removeField('certification['+index+'].number')
                    }
                    this.certifications[index].state=null
                    break;
                default:
                    break;
            }
        },
        setCprCard() {
            let file=this.$refs.cprcard.files[0]
            memberAppStore.commit('application/uploadCprCard', file)
        }
    }
}
</script>

<style scoped>

</style>
