<template>
    <div class="pt-2" v-if="hasRole(sysAdmin) || hasRole(committeeMinutesAdmin)">
        <div class="card w-100 h-100">
            <div class="card-header bg-dark text-white">Meeting Attendance Report</div>
            <div class="card-body">
                <div class="row">
                    <div class="form-group col-sm-12 text-center">
                        <label class="custom-address-label">Select a year</label>
                        <Select2 v-model="year" :options="yearList" :settings="yearSettings" :disabled="yearList.length<1"
                                 :name="'years'" @change="getAttendance"></Select2>
                    </div>
                </div>
                <div class="row" v-show="year!==null">
                    <div ref="attendance" id="attendance">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 text-center h3">Meeting Attendance Report</div>
                        </div>
                        <div class="row">
                            <div class="'col-sm-12 col-md-12">E = Excused, P = Present</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 text-center">
                                <table class="table table-responsive w-100 d-block d-md-table table-bordered table-striped">
                                    <thead class="bg-dark">
                                    <tr>
                                        <th v-for="col in columns" :class="'text-white'">{{col}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="row in rows">
                                        <td v-for="col in columns">{{row[col]}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pt-2" v-else>
        <div class="card w-100 h-100">
            <div class="card-header bg-danger text-white">ACCESS DNIED</div>
            <div class="card-body"></div>
        </div>
    </div>
</template>

<script>

import {roleCheck} from "../../helpers/rolecheck";
import memberAppStore from "../../store"

export default {
    name: "MeetingAttendance",
    store: memberAppStore,
    mixins: [roleCheck],
    created() {
        this.getMinutes()
    },
    data() {
        return {
            rows: [],
            year: null,
            yearList: [],
            yearSettings: {
                width: '25%',
                placeholder: 'Select a year',
                theme: 'bootstrap4',
                minimumResultsForSearch: Infinity
            },
        }
    },
    computed: {
        "columns": function columns() {
            if (this.rows.length===0) {
                return []
            }
            return Object.keys(this.rows[0])
        },
    },
    methods: {
        getMinutes() {
            axios.get('/api/getMinutes')
                .then(response=>{
                    let meetingMinutes=response.data
                    this.yearList=Object.keys(meetingMinutes).sort().reverse()
                })
                .catch(error=>{
                    console.log(error)
                })
        },
        getAttendance() {
            this.rows=[]
            axios.get('/api/getMeetingAttendance/'+this.year)
                .then(response => {
                    response.data.data.forEach(row => {
                        if (row!==null) {
                            this.rows.push(row)
                        }
                    })
                })
        }
    }
}
</script>

<style scoped>

</style>
