<template>
    <div class="pt-2" v-if="hasRole(sysAdmin) || hasRole(rosterAdmin)">
        <div class="card w-100 h-100">
            <div class="card-header bg-dark text-white">Rounds Attendance Report</div>
            <div class="card-body">
                <div class="row">
                    <div class="form-group col-sm-12 text-center">
                        <label class="custom-address-label">Select a tracking year</label>
                        <Select2 v-model="year" :options="yearList" :settings="yearSettings" :disabled="yearList.length<1"
                                 :name="'years'" @change="getReport"></Select2>
                    </div>
                </div>
                <div class="row" v-show="year!==null">
                    <div class="col-sm-12 col-md-12 text-center">
                        <download-excel class="btn btn-success" :data="rows" type="csv" name="roundsreport.csv">
                            Download Excel
                        </download-excel>
                        <button class="btn btn-primary" @click="download">Download PDF</button>
                    </div>
                </div>
                <div ref="expirations" id="expirations">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 text-center h3">Rounds Attendance Report</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 text-center">
                            <table class="table table-bordered table-striped">
                                <thead class="bg-dark">
                                <tr>
                                    <th v-for="col in columns" :class="'text-white'">{{col}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="row in rows">
                                    <td v-for="col in columns">{{row[col]}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pt-2" v-else>
        <div class="card w-100 h-100">
            <div class="card-header bg-danger text-white">ACCESS DENIED</div>
            <div class="card-body"></div>
        </div>
    </div>
</template>

<script>
import html2pdf from 'html2pdf.js'
import {roleCheck} from "../../helpers/rolecheck";
import memberAppStore from "../../store"


export default {
    name: "RoundsAttendance",
    store: memberAppStore,
    mixins: [roleCheck],
    created() {
        this.getYears()
    },
    data() {
        return {
            rows: [],
            year: null,
            yearList: [],
            yearSettings: {
                width: '25%',
                placeholder: 'Select a year',
                theme: 'bootstrap4',
                minimumResultsForSearch: Infinity
            },
        }
    },
    computed: {
        "columns": function columns() {
            if (this.rows.length===0) {
                return []
            }
            return Object.keys(this.rows[0])
        },
    },
    methods: {
        getYears() {
            axios.get('api/getYears')
                .then(response => {
                    response.data.forEach(item => {
                        this.yearList.push(item)
                    })
                }).catch(error => {
                console.log(error)
            })
        },
        download() {
            let opt= {
                margin: 10,
                filename: 'roundsreport.pdf'
            }
            let element=document.getElementById('expirations')
            html2pdf().set(opt).from(element).save()
        },
        getReport() {
            this.rows=[]
            axios.get('/api/getRoundsReport/'+this.year)
                .then(response => {
                    response.data.data.forEach(row =>{
                        if (row!==null) {
                            this.rows.push(row)
                        }
                    })
                })
        }
    }
}
</script>

<style scoped>

</style>
