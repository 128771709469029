<template>
    <div class="card w-100 h-100">
        <div class="card-header bg-dark text-white chevron-header">
            <a data-toggle="collapse" href="#collapseLinks" aria-expanded="true" aria-controls="collapseLinks "
               id="linksHeading" class="text-white d-block">
                <i class="fas fa-chevron-down float-right"></i>
                Important Links and Documents
            </a>
        </div>
        <div class="collapse show collapseLinksDates" id="collapseLinks" aria-labelledby="linksHeading">
            <div class="card-body">
                <div class="accordion" id="links">
                    <div class="card">
                        <div class="card-header bg-dark chevron-header" id="communication">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left text-white" type="button" data-toggle="collapse" data-target="#collapseComms" aria-expanded="true" aria-controle="collapseComms">
                                    Communications
                                </button>
                            </h2>
                        </div>
                        <div id="collapseComms" class="collapse show" aria-labelledby="communication" data-parent="#links">
                            <div class="card-body bg-secondary text-white">
                                <ul>
                                    <li><a class="text-white" href="https://zoom.us/j/94181297277?pwd=cVk1bkRsODh4cUlycWZmbmZ6dEJ1dz09" target="_blank">Click to Join Monthly Meeting</a></li>
                                    <li><a class="text-white" href="http://mail.rehobothambulance.org" target="_blank">Ambulance Email</a></li>
                                    <li><a class="text-white" href="https://teams.microsoft.com" target="_blank">Microsoft Teams</a></li>
                                    <li><a class="text-white" href="https://paychexflex.com/landing_remote/v1/oauth/launch?domain=rehobothambulance.org" target="_blank">Paychex Login</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header bg-dark chevron-header" id="operations">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left text-white" type="button" data-toggle="collapse" data-target="#collapseOps" aria-expanded="false" aria-controls="collapseOps">
                                    Operations
                                </button>
                            </h2>
                        </div>
                        <div id="collapseOps" class="collapse" aria-labelledby="operations" data-parent="#links">
                            <div class="card-body bg-secondary text-white">
                                <ul>
                                    <li><a class="text-white" href="http://aladtec.com/rehoboth" target="_blank">Aladtec Scheduling</a></li>
                                    <li><a class="text-white" href="https://esosuite.net" target="_blank">ESO Login (Agency ID: RACEMS)</a></li>
                                    <li><a class="text-white" href="https://app1.pstrax.com/sso-login.php" target="_blank">PS Trax Inventory Checks</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header bg-dark text-white chevron-header" id="policies">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left text-white" type="button" data-toggle="collapse" data-target="#collapsePolicies" aria-expanded="false" aria-controls="collapsePolicies">
                                    Policies and Procedures
                                </button>
                            </h2>
                        </div>
                        <div id="collapsePolicies" class="collapse" aria-labelledby="policies" data-parent="#links">
                            <div class="card-body bg-secondary text-white">
                                <ul>
                                    <li><a class="text-white" href="https://rehobothambulance.sharepoint.com/:b:/s/RehobothAmbulance/EdnSrYjHeuJDjTwlIcyZyrwBOF3JCtlQuVABUuJo3izd-g?e=lrGeG2" target="_blank">Rehoboth Ambulance Committee Bylaws</a></li>
                                    <li><a class="text-white" href="https://rehobothambulance.sharepoint.com/:b:/s/RehobothAmbulance/EVmZ6FB3ttlNhtRaP5oWJpwBaKW18YGYlAAJ0L0Ld08-AQ?e=42mf1D" target="_blank">Standard Operating Procedures</a></li>
                                    <li><a class="text-white" href="https://rehobothambulance.sharepoint.com/:b:/s/RehobothAmbulance/ESgPo5aBr9BIrrFiauIdsYsBCWYQ_hyr0OtgGGwHbV0wAg?e=rg7B1k" target="_blank">Rehoboth Ambulance Employee Handbook</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Links"
}
</script>

<style scoped>

</style>
