<template>
<div id="emerg">
    <div class="form-row h-100" v-for="(contact, index) in emergencyContacts" :key="index">
        <div class="col-md-11 col-sm-11">
            <fieldset class="field-border">
                <legend class="field-border">Emergency Contact {{ index+1 }}</legend>
                <div class="row">
                    <div class="form-group col-sm-6 col-md-6">
                        <input type="text" v-model="contact.first_name" class="form-control" :name="'contact['+index+'].firstName'" placeholder="First name"
                               data-fv-not-empty="true"
                               data-fv-not-empty___message="First name is required">
                    </div>
                    <div class="form-group col-sm-6 col-md-6">
                        <input type="text" v-model="contact.last_name" class="form-control" :name="'contact['+index+'].lastName'" placeholder="Last name"
                               data-fv-not-empty="true"
                               data-fv-not-empty___message="Last name is required">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6 col-md-6">
                        <input type="tel" v-model="contact.phone" :name="'contact['+index+'].phone'" class="form-control" placeholder="Phone number"
                               data-fv-not-empty="true"
                               data-fv-not-empty___message="Phone number is required"
                               data-fv-phone="true"
                               data-fv-phone___country="US"
                               data-fv-phone___message="Invalid phone number">
                    </div>
                    <div class="form-group col-sm-6 col-md-6">
                        <Select2 v-model="contact.relationship" :options="relTypes" :settings="relSettings" :name="'contact['+index+'].relationship'"
                                 @change="revalidate('contact['+index+'].relationship')"></Select2>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12 col-md-12">
                        <input type="text" v-model="contact.street" :name="'contact['+index+'].street'" class="form-control" placeholder="Street address"
                               data-fv-not-empty="true"
                               data-fv-not-empty___message="Street address is required">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-4 col-md-4">
                        <input type="text" v-model="contact.city" :name="'contact['+index+'].city'" class="form-control" placeholder="City"
                               data-fv-not-empty="true"
                               data-fv-not-empty___message="City is required">
                    </div>
                    <div class="form-group col-sm-4 col-md-4">
                        <Select2 v-model="contact.state" :options="stateList" :settings="stateSettings" :name="'contact['+index+'].state'"
                                 @change="revalidate('contact['+index+'].state')"></Select2>
                    </div>
                    <div class="form-group col-sm-4 col-md-4">
                        <input type="num" v-model="contact.zip_code" :name="'contact['+index+'].zipCode'" class="form-control" placeholder="Zip Code"
                               data-fv-not-empty="true"
                               data-fv-not-empty___message="Zip code is required"
                               data-fv-zip-code="true"
                               data-fv-zip-code___country="US"
                               data-fv-zip-code___message="Invalid zip code">
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-md-1 col-sm-1 align-self-center">
            <i class="alert-danger fas fa-minus-circle" @click="remove(index)" v-show="index || (!index && emergencyContacts.length >1)"></i>
            <i class="alert-success fas fa-plus-circle" @click="add(index)" v-show="index === emergencyContacts.length-1"></i>
        </div>

    </div>
</div>
</template>

<script>
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import Declarative from 'formvalidation/plugins/Declarative';
import Phone from 'formvalidation/validators/phone';
import ZipCode from 'formvalidation/validators/zipCode';
import { mapMultiRowFields } from 'vuex-map-fields'
import memberAppStore from "../../store";
import states from "../../states";

export default {
    name: "contacts",
    //store: memberAppStore,
    props: ['appType'],
    mounted() {
        this.firstNameValidator={
            validators: {
                notEmpty: {
                    message: "First name is required"
                }
            }
        }
        this.lastNameValidator={
            validators: {
                notEmpty: {
                    message: "Last name is required"
                }
            }
        }
        this.phoneValidator={
            validators: {
                notEmpty: {
                    message: "Phone number is required"
                },
                phone: {
                    country: "US",
                    message: "Invalid phone number"
                }
            }
        }
        this.relValidator={
            validators: {
                notEmpty: {
                    message: "Relationship is required"
                }
            }
        }
        this.streetValidator={
            validators: {
                notEmpty: {
                    message: "Street address is required"
                }
            }
        }
        this.cityValidator={
            validators: {
                notEmpty: {
                    message: "City is required"
                }
            }
        }
        this.stateValidator={
            validators: {
                notEmpty: {
                    message: "State is required"
                }
            }
        }
        this.zipValidator={
            validators: {
                notEmpty: {
                    message: "Zip code is required"
                },
                zipCode: {
                    country: "US",
                    message: "Invalid zip code"
                }
            }
        }
        this.emerg=formValidation(document.getElementById('emerg'), {
            fields: {
                'contact[0].relationship': this.relValidator,
                'contact[0].state': this.stateValidator
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true,
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap()
            }
        })
        this.emerg.registerValidator('phone',Phone)
        this.emerg.registerValidator('zipCode',ZipCode)
        //Set the state field to default to Massachusetts and execute validation for UI consistency
        //this.emergencyContacts[0].state="MA"
        this.$set(this.emergencyContacts[0], 'state', 'MA')
        this.emerg.validateField('contact[0].state')
    },
    data() {
        return {
            stateList: states,
            stateSettings: {
                width: "100%",
                placeholder: "Select a state",
                theme: "bootstrap4"
            },
            relTypes: [
                'Spouse',
                'Partner',
                'Friend',
                'Parent',
                'Sibling',
                'Other'
            ],
           relSettings: {
                width: "100%",
               placeholder: "Relationship",
               theme: "bootstrap4"
           }
        }
    },
    computed: {
        ...mapMultiRowFields('application',['emergencyContacts'])
    },
    methods: {
        revalidate(name) {
            this.emerg.validateField(name)
        },
        async validate() {
            let result=await this.emerg.validate()
            return result==="Valid"
        },
        add(index) {
            let fv=this.emerg
            let lastv=this.lastNameValidator
            let firstv=this.firstNameValidator
            let phonev=this.phoneValidator
            let relv=this.relValidator
            let streetv=this.streetValidator
            let cityv=this.cityValidator
            let statev=this.stateValidator
            let zipv=this.zipValidator
            let ec=this
            let newIndex=index+1
            memberAppStore.commit('application/addContact', newIndex)

            let element = async function(newIndex) {
                await document.getElementsByName('contact['+newIndex+'].state')
                return "Success"
            }
            element().then(function () {
                fv.addField("contact["+newIndex+"].lastName",lastv)
                fv.addField("contact["+newIndex+"].firstName",firstv)
                fv.addField("contact["+newIndex+"].phone",phonev)
                fv.addField("contact["+newIndex+"].relationship",relv)
                fv.addField("contact["+newIndex+"].street",streetv)
                fv.addField("contact["+newIndex+"].city",cityv)
                fv.addField("contact["+newIndex+"].state",statev)
                fv.addField("contact["+newIndex+"].zipCode",zipv)
                ec.$set(ec.emergencyContacts[newIndex],"state","MA")
                fv.validateField("contact["+newIndex+"].state")
            })

        },
        remove(index) {
            let getLast=this.emergencyContacts.length
            let toRemove=getLast-1
            this.emerg.removeField("contact["+toRemove+"].lastName")
            this.emerg.removeField("contact["+toRemove+"].firstName")
            this.emerg.removeField("contact["+toRemove+"].phone")
            this.emerg.removeField("contact["+toRemove+"].relationship")
            this.emerg.removeField("contact["+toRemove+"].street")
            this.emerg.removeField("contact["+toRemove+"].city")
            this.emerg.removeField("contact["+toRemove+"].state")
            this.emerg.removeField("contact["+toRemove+"].zipCode")

            memberAppStore.commit('application/removeContact',index)
        }
    }
}
</script>

<style scoped>

</style>
