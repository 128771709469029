export default {
    SYSADMIN: 1,
    MEMBERADMIN: 2,
    ROSTERADMIN: 3,
    TRAININGADMIN: 4,
    COMMITTEEMINUTESADMIN: 5,
    BODMINUTESADMIN: 6,
    TRAINEE: 3,
    PRECEPTOR: 3
}
