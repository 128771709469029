import Vue from 'vue'
import Vuex from 'vuex'
import {getField} from 'vuex-map-fields'

//Modules
import profileStore from './profile'
import applicationStore from './application'
import databaseStore from './database'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        profile: profileStore,
        application: applicationStore,
        database: databaseStore,
    },
    state: {
        roles: []
    },
    getters: {
        getField
    },
    actions: {
        async getRoles({commit}) {
            await axios.get('/api/getUserRoles')
                .then(result => {
                    commit('SET_ROLES',result.data)
                })
        }
    },
    mutations: {
        SET_ROLES(state,roles) {
            state.roles=roles
        }
    }
})

export default store
