import { render, staticRenderFns } from "./Cpr.vue?vue&type=template&id=4fbc85eb&scoped=true&"
import script from "./Cpr.vue?vue&type=script&lang=js&"
export * from "./Cpr.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fbc85eb",
  null
  
)

export default component.exports