<template>
    <div id="history">
        <div class="row h-100">
            <div class="form-group col-sm-6 col-md-6">
                <label class="custom-address-label">Committee Positions</label>
                <Select2 v-model="positions" :options="positionOptions" :settings="positionSettings" :name="'positions'"></Select2>
            </div>
            <div class="form-group col-sm-6 col-md-6 selectContainer">
                <label class="custom-address-label">Last CORI Date</label>
                <div class="input-group date">
                    <datepicker v-model="last_cori_date" class="form-control" :wrap="true" :config="dateOptions" placeholder="mm/dd/yyyy"
                        :name="'last_cori_date'" :id="'last_cori_date'" @change="reValidate('last_cori_date')"></datepicker>
                    <div class="input-group-append">
                        <div class="input-group-text datepickerbutton">
                            <i class="fas fa-calendar"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row h-100">
            <div class="form-group col-sm-6 col-md-6">
                <label class="custom-address-label">Select appropriate website roles <span v-tooltip.top="{
                    ref: 'roleDesc',
                    class: 'tooltip-custom'}">(Role Descriptions)</span></label>
                <Select2 v-model="roles" :options="roleOptions" :settings="roleSettings" :name="'roles'"></Select2>
            </div>
            <div class="form-group col-sm-6 col-md-6">
                <label class="custom-address-label">Rehoboth Ambulance Email Address</label>
                <input type="text" v-model="email" name="racemail" id="racemail" class="form-control">
            </div>
        </div>

        <fieldset class="field-border">
            <legend class="field-border">Membership History</legend>
            <div class="row">
                <div class="col-sm-5 col-md-5 d-flex justify-content-center font-weight-bold">Event Type</div>
                <div class="col-sm-5 col-md-5 d-flex justify-content-center font-weight-bold">Event Data</div>
                <div class="col-sm-2 col-md-2"></div>
            </div>
            <div class="form-row" v-for="(history, index) in histories" :key="index">
                <div class="form-group col-sm-5 col-md-5">
                    <Select2 v-model.number="history.history_event_id" :options="historyOptions" :settings="historySettings"
                             :name="'history['+index+'].type'" :id="'history['+index+'].type'"></Select2>
                </div>
                <div class="form-group col-sm-5 col-md-5">
                    <div class="input-group date">
                        <datepicker v-model="history.event_date" class="form-control" :wrap="true" :config="dateOptions" placeholder="mm/dd/yyyy"
                            :name="'history['+index+'].event_date'" :id="'history['+index+'].event_date'" @change="reValidate('history['+index+'].event_date')"></datepicker>
                        <div class="input-group-append">
                            <div class="input-group-text datepickerbutton">
                                <i class="fas fa-calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-sm-2">
                        <i class="alert-danger fas fa-minus-circle" @click="remove(index)" v-show="(index || (!index && histories.length >1)) && (deleting!==index)"></i>
                        <div class="spinner-border spinner-border-sm text-danger" role="status" v-show="(index || (!index && histories.length >1)) && (deleting===index)"><span class="sr-only">Deleting...</span></div>
                        <i class="alert-success fas fa-plus-circle" @click="add(index)" v-show="index === histories.length-1"></i>
                    </div>
            </div>
        </fieldset>

        <fieldset class="field-border">
            <legend class="field-border">Documents</legend>
            <div class="row">
                <div class="col-sm-7 col-md-7 d-flex justify-content-center font-weight-bold">Document Description</div>
                <div class="col-sm-3 col-md-3 d-flex justify-content-center font-weight-bold">Document Date</div>
                <div class="col-sm-1 col-md-1 d-flex justify-content-center font-weight-bold">Doc Copy</div>
                <div class="col-sm-1 col-md-1"></div>
            </div>
            <div class="form-row" v-for="(document, index) in documents" :key="index">
                <div class="form-group col-sm-7 col-md-7">
                    <input type="text" v-model="document.description" class="form-control" :name="'document['+index+'].description'"
                           :id="'document['+index+'].description'">
                </div>
                <div class="form-group col-sm-3 col-md-3 selectContainer">
                    <div class="input-group date">
                        <datepicker v-model="document.document_date" class="form-control" :wrap="true" :config="dateOptions" placeholder="mm/dd/yyyy"
                                    :name="'document['+index+'].document_date'"
                                    :id="'document['+index+'].document_date'"></datepicker>
                        <div class="input-group-append">
                            <div class="input-group-text datepickerbutton">
                                <i class="fas fa-calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1 col-md-1 text-center form-group">
                    <div class="d-flex">
                        <div class="mr-auto p-2">
                            <label :for="'document['+index+'].image'">
                                <i class="fas fa-upload text-primary"></i>
                            </label>
                            <input type="file" :id="'document['+index+'].image'" :name="'document['+index+'].image'"
                                   @change="setFile($event.target.files, index)" class="form-control" style="display: none;">
                        </div>
                        <div class="p-2">
                            <a v-show="document.document_path!==null" :href="document.document_path" target="_blank"><i class="fas fa-download text-primary"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1 col-md-1">
                    <div>
                        <i class="alert-danger fas fa-minus-circle" @click="delDocumentRow(index)" v-show="(index || (!index && documents.length >1)) && (deletingDoc!==index)"></i>
                        <div class="spinner-border spinner-border-sm text-danger" role="status" v-show="deletingDoc===index"><span class="sr-only">Deleting...</span></div>
                        <i class="alert-success fas fa-plus-circle" @click="addDocumentRow(index)" v-show="index===documents.length-1"></i>
                    </div>
                </div>
            </div>
            <div class="fv-help-block" v-if="filesPending">Documents pending upload.  Please remember to click Add/Update Record</div>
        </fieldset>

        <fieldset class="field-border">
            <legend class="field-border">Notes</legend>
            <div class="form-row">
                <div class="form-group col-sm-7 col-md-7">
                    <textarea v-model="notes" :name="'notes'" :id="'notes'" class="form-control"></textarea>
                </div>
            </div>
        </fieldset>

        <fieldset class="field-border">
            <legend class="field-border">References</legend>
            <div class="row">
                <div class="col-sm-3 col-md-3 d-flex justify-content-center font-weight-bold">Reference Name</div>
                <div class="col-sm-3 col-md-3 d-flex justify-content-center font-weight-bold">Reference Phone Number</div>
                <div class="col-sm-2 col-md-2 d-flex justify-content-center font-weight-bold">Years Known</div>
                <div class="col-sm-4 col-md-4 d-flex justify-content-center font-weight-bold">How Reference is Known</div>
            </div>
            <div class="row" v-for="(reference, index) in references" :key="index">
                <div class="col-sm-3 col-md-3 d-flex justify-content-center">{{reference.name}}</div>
                <div class="col-sm-3 col-md-3 d-flex justify-content-center">{{reference.phone}}</div>
                <div class="col-sm-2 col-md-2 d-flex justify-content-center">{{reference.years_known}}</div>
                <div class="col-sm-4 col-md-4 d-flex justify-content-center">{{reference.how_known}}</div>
            </div>
        </fieldset>

        <!--Tooltip content -->
        <div ref="roleDesc" class="tooltip-content">
            <div class="row" v-for="role in roleDesc">
                <div class="col-sm-4 col-md-4">
                    <span>{{role.role}}</span>
                </div>
                <div class="col-sm-8 col-md-8">
                    <span>{{role.desc}}</span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import Declarative from 'formvalidation/plugins/Declarative';
import memberAppStore from '../../../store'
import ENUM from '../../../helpers/enum'

export default {
    name: "databaseCommittee",
    store: memberAppStore,
    mounted() {
        this.typeValidator={
            validators: {
                notEmpty: {
                    message: "Event type is required"
                }
            }
        }
        this.eventValidator={
            validators: {
                notEmpty: {
                    message: "Event date is required"
                },
                date: {
                    format: 'MM/DD/YYYY',
                    message: 'Invalid date'
                }
            }
        }
        this.coriValidator={
            validators: {
                date: {
                    format: 'MM/DD/YYYY',
                    message: 'Invalid date'
                }
            }
        }
        this.history=formValidation(document.getElementById('history'), {
            fields: {
                'history[0].type': this.typeValidator,
                'history[0].event_date': this.eventValidator,
                last_cori_date: this.coriValidator
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true,
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap()
            }
        })

    },
    created() {
        this.getPositionOptions()
        this.getHistoryEventTypes()
        this.getRoleOptions()
        this.getRoleDesc()
    },
    data() {
        return {
            filesPending: 0,
            msg: 'tooltip!',
            deleting: -1,
            deletingDoc: -1,
            positionOptions: [],
            roleDesc: [],
            roleOptions: [],
            roleSettings: {
                width: '100%',
                theme: 'bootstrap4',
                multiple: true
            },
            positionSettings: {
                width: '100%',
                theme: 'bootstrap4',
                multiple: true
            },
            historyOptions: [],
            historySettings: {
                width: '100%',
                theme: 'bootstrap4',
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
                allowInputToggle: false
            }
        }
    },
    computed: {
        ...mapFields('database',['positions','roles','member.last_cori_date','user.email','doc_images','member.notes']),
        ...mapMultiRowFields('database',['histories','documents','references']),
        apiState() {
            return memberAppStore.getters['database/getApiState']
        },
    },
    watch: {
        apiState: function () {
            if (this.apiState===ENUM.LOADED) {
                let length=this.histories.length
                let fv=this.history
                let ev=this.eventValidator
                let tv=this.typeValidator
                let fields=this.history.getFields()
                let fieldKeys=Object.keys(fields)
                let lastItem=length-1
                if (length>1) {
                    let element=async function() {
                        await document.getElementsByName('history['+lastItem+'].type')
                        return "Success"
                    }
                    element().then(function() {
                        for (let i=1; i<length; i++) {
                            fv.addField("history["+i+"].event_date",ev)
                            fv.addField('history['+i+'].type',tv)
                        }
                        fieldKeys.forEach(field => {
                            if (document.getElementsByName(field).length===0) {
                                fv.removeField(field)
                            }
                        })
                    })
                }
            }

        }
    },
    methods: {
        async validate() {
            let result=await this.history.validate()
            return result==="Valid"
        },
        getPositionOptions() {
            axios.get('/api/getPositions')
            .then((response) => {
                this.positionOptions=response.data
            })
        },
        getRoleDesc() {
            axios.get('/api/getRoleDesc')
            .then((response) => {
                this.roleDesc=response.data
            })
        },
        getRoleOptions() {
            axios.get('/api/getRoleList')
            .then((response) => {
                this.roleOptions=response.data
            })
        },
        getHistoryEventTypes() {
            axios.get('api/getEventTypes')
            .then((response) => {
                this.historyOptions=response.data
            })
        },
        add(index) {
            let fv=this.history
            let eventv=this.eventValidator
            let typev=this.typeValidator
            memberAppStore.dispatch('database/addHistory')
            let newIndex=index+1
            let element = async function(newIndex) {
                await document.getElementsByName('history['+newIndex+'].type')
                return "Success"
            }
            element().then(function (){
                fv.addField("history["+newIndex+"].event_date",eventv)
                fv.addField("history["+newIndex+"].type",typev)
            })
        },
        remove(index) {
            this.deleting=index
            let getLast=this.histories.length
            let toRemove=getLast-1
            let pid=this.histories[index].id
            let history_id=this.histories[index].history_event_id
            axios.delete('/api/deleteHistory/'+pid+'/'+history_id)
            .then(response =>{
                this.history.removeField('history['+toRemove+'].event_date')
                this.history.removeField('history['+toRemove+'].type')

                memberAppStore.dispatch('database/deleteHistory', index)
                this.deleting=-1
            }).catch(error =>{
                console.log(error)
                this.deleting=-1
            })
        },
        reValidate(field) {
            this.certs.validateField(field)
        },
        resetForm() {
            this.history.resetForm(true)
        },
        setFile(fileList, index) {
            if (!fileList.length) return
            let file=fileList[0]
            let fieldName='document_'+index
            memberAppStore.commit('database/ADD_DOCUMENT_IMAGE',{fieldName,file})
            this.filesPending=1
        },
        addDocumentRow(index) {
            let newIndex=index+1
            memberAppStore.dispatch('database/addDocument')
        },
        delDocumentRow(index) {
            this.deletingDoc=index
            let getLast=this.documents.length
            let toRemove=getLast-1
            let pid=this.documents[index].id
            let fieldName='document_'+index
            axios.delete('api/deleteDocument/'+pid)
            .then(()=> {
                memberAppStore.dispatch('database/delDocument',index)
                memberAppStore.dispatch('database/delDocImage',fieldName)
                this.deletingDoc=-1
            })
        }

    }
}
</script>

<style scoped>


</style>
