<template>
    <div>
        <form method="post" id="eventForm">
            <div class="form-group">
                <input v-model="form.senderName" type="text" class="form-control" id="senderName" name="senderName" placeholder="Your Name"
                    data-fv-not-empty="true"
                    data-fv-not-empty___message="Your name is required">
            </div>
            <div class="form-group">
                <input v-model="form.senderEmail" type="text" class="form-control" id="senderEmail" name="senderEmail" placeholder="you@yoursite.com"
                    data-fv-not-empty="true"
                    data-fv-not-empty___message="Your email address is required"
                    data-fv-email-address="true"
                    data-fv-email-address___message="Invalid email address">
            </div>
            <div class="form-group">
                <input v-model="form.orgName" type="text" class="form-control" id="orgName" name="orgName" placeholder="Organization Name"
                    data-fv-not-empty="true"
                    data-fv-not-empty___message="Organization name is required">
            </div>
            <div class="form-group selectContainer">
                <div class="input-group date">
                    <datepicker v-model="form.eventDate" :wrap="true" :config="options"  placeholder="mm/dd/yyyy" id="eventDate" name="eventDate" @click="revalidate"></datepicker>
                    <div class="input-group-append">
                        <div class="input-group-text datepickerbutton">
                            <i class="fas fa-calendar"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <input v-model="form.eventLocation" type="text" class="form-control" id="eventLocation" name="eventLocation" placeholder="Event Location"
                    data-fv-not-empty="true"
                    data-fv-not-empty___message="Event location is required">
            </div>
            <div class="form-group">
                <textarea v-model="form.eventDesc" class="form-control" rows="4" id="eventDesc" name="eventDesc" placeholder="Event Description"
                    data-fv-not-empty="true"
                    data-fv-not-empty___message="Event description is required"></textarea>
            </div>
            <div>
                <div class="form-group"><div id="captchaContainer"></div></div>
                <button class="btn btn-primary" type="submit">Send Message</button>

            </div>
        </form>
        <!--- MODAL --->
        <modal ref="messageModal" :redirect-url="messageRedirect"></modal>
    </div>

</template>

<script>
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import SubmitButton from 'formvalidation/plugins/SubmitButton';
import Declarative from 'formvalidation/plugins/Declarative';
import Icon from 'formvalidation/plugins/Icon';
import Recaptcha from 'formvalidation/plugins/Recaptcha';

export default {
    name: "Events",
    mounted() {
        console.log('Event module mounted')

        this.fv=formValidation(document.getElementById('eventForm'),{
            fields: {
                eventDate: {
                    validators: {
                        notEmpty: {
                            message: 'Event date is required'
                        },
                        date: {
                            format: 'MM/DD/YYYY',
                            message: 'Invalid date format'
                        },
                    }
                }
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true,
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
                icon: new Icon({
                    valid: 'fa fa-check',
                    invalid: 'fa fa-times',
                    validatiing: 'fa fa-refresh'
                }),
                submitButton: new SubmitButton(),
                recaptcha: new Recaptcha({
                    element: 'captchaContainer',
                    message: 'Invalid verification',
                    siteKey: process.env.MIX_RECAPTCHA_SITE_KEYv2_robot,
                    //badge: 'bottomright',
                    theme: 'light',
                    timeout: '120',
                    //size: 'invisible',
                    //backendVerificationUrl: '/api/checkCaptcha'
                })
            },
        });
        this.fv.on('core.form.valid', () => {
            let modal=this.$refs.messageModal
            modal.output="Sending your message"
            $(modal.$refs.messageSentModal).modal('show')
            axios.post('/api/sendMessage', this.form)
            .then(function (response) {
                modal.messageSent=true
                modal.output=response.data
            })
            .catch(function (error) {
                modal.messageSent=true
                modal.output=error
            });
        });
    },
    props: ['messageRedirect'],
    data() {
        return {
            form: {
                to: 'fischmanr@gmail.com',
                senderName: '',
                senderEmail: '',
                orgName: '',
                eventDate: '',
                eventLocation: '',
                eventDesc: '',
                formType: 'Event'
            },
            options: {
                format: 'MM/DD/YYYY',
                useCurrent:false,
                allowInputToggle: false,
            }
        }
    },
    methods: {
        revalidate() {
            this.fv.revalidateField('eventDate')
        },
    }
}
</script>

<style scoped>

</style>
