<template>
    <div class="pt-2">
        <div class="card w-100 h-100">
            <div class="card-header bg-dark text-white">Meeting Minutes</div>
            <div class="card-body">
                <div v-if="hasRole(bodMinutesAdmin) || hasRole(committeeMinutesAdmin) || hasRole(sysAdmin)">
                    <fieldset class="field-border" id="meetingUpload">
                        <legend class="field-border">Upload Minutes</legend>
                        <p>NOTE: If meeting minutes already exist, this will automatically overwrite them with the new file</p>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 form-group">
                                <label class="custom-address-label">Meeting Date</label>
                                <div class="input-group date">
                                    <datepicker v-model="meetingData.meetingDate" :wrap="true" :config="meetingConfig" id="meetingDate" name="meetingDate"
                                        @dp-change="validateField('meetingDate')"></datepicker>
                                    <div class="input-group-append">
                                        <div class="input-group-text datepickerbutton">
                                            <i class="fas fa-calendar"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <label class="custom-address-label">Meeting Type</label>
                                <div class="form-group">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" :id="'committee'" :name="'meetingType'" class="form-check-input" :value="'Committee'" v-model="meetingData.meetingType"
                                               :disabled="!hasRole(committeeMinutesAdmin) && !hasRole(sysAdmin)" @change="getAttendees()">
                                        <label class="form-check-label" :for="'committee'">Committee</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" :id="'bod'" :name="'meetingType'" class="form-check-inline" :value="'Board'" v-model="meetingData.meetingType"
                                               :disabled="!hasRole(bodMinutesAdmin) && !hasRole(sysAdmin)" @change="getAttendees()">
                                        <label class="form-check-label" :for="'bod'">Board of Directors</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 form-group">
                                <label class="custom-address-label">Upload minutes (PDF Only)</label>
                                <input type="file" class="form-control" name="meetingMinutes" id="meetingMinutes"
                                       @change="setFile($event.target.files)"
                                       data-fv-file___extension="pdf"
                                       data-fv-file___type="application/pdf"
                                       data-fv-file___message="Invalid file type">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-md-6 form-group" v-show="(meetingData.meetingType==='Committee')">
                                <label class="custom-address-label">Select members present</label>
                                <select2 v-model="meetingData.meetingAttendees" :options="memberList" :settings="memberSettings" :name="'attendees'"
                                         :id="'attendees'" ></select2>
                            </div>
                            <div class="col-sm-6 col-md-6 form-group" v-show="(meetingData.meetingType==='Committee')">
                                <label class="custom-address-label">Select members excused</label>
                                <select2 v-model="meetingData.meetingExcused" :options="memberList" :settings="memberSettings" :name="'excused'"
                                         :id="'excused'"></select2>
                            </div>
                        </div>
                        <div class="row py-2">
                            <div class="col-sm-12 col-md-12">
                                <button class="btn btn-primary float-right" @click="sendMinutes">Upload Minutes</button>
                            </div>
                        </div>
                    </fieldset>

                </div>
                <div class="row">
                    <div class="col-sm-3 col-md-3">
                        <div class="nav flex-column nav-pills" id="minutes-tab" role="tablist" aria-orientation="vertical">
                            <a v-for="(year, index) in years" :key="index" class="nav-link" :class="isLinkActive(index)" :id="'minutes-'+year+'-tab'"
                               data-toggle="pill"
                               :href="'#minutes-'+year" role="tab"
                               :aria-controls="'minutes-'+year" :aria-selected="index===0">{{year}}</a>
                        </div>
                    </div>
                    <div class="col-sm-9 col-md-9">
                        <div class="tab-content" id="minutes-tabContent">
                            <div v-for="(year,index) in years" :key="index" class="tab-pane fade" :class="isTabActive(index)" :id="'minutes-'+year" role="tabpanel"
                                 :aria-labelledby="'minutes-'+year+'-tab'">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <table class="table table-bordered table-striped">
                                            <thead class="bg-dark">
                                            <tr>
                                                <th v-for="col in headers" :class="'text-white'">{{col}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="row in meetingMinutes[year]">
                                                <td>{{row.document}}</td>
                                                <td>{{row.type}}</td>
                                                <td><a :href="row.filename" target="_blank"><i class="fas fa-download"></i></a></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <membermodal ref="messageModal"  @hide="closeModal"></membermodal>
    </div>
</template>

<script>
import {roleCheck} from "../../helpers/rolecheck"
import memberAppStore from "../../store";
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import Declarative from 'formvalidation/plugins/Declarative';
import membermodal from './MembersModal'

export default {
    name: "Minutes",
    store: memberAppStore,
    mixins: [roleCheck],
    components: {
        membermodal
    },
    created() {
        memberAppStore.dispatch('getRoles')
        this.getMinutes()
    },
    mounted() {
        this.getMembers(),
        this.dateValidator={
            validators: {
                notEmpty: {
                    message: 'Date of meeting is required'
                },
            }
        }
        this.meetingTypeValidator={
            validators: {
                notEmpty: {
                    message: 'Meeting type is required'
                }
            }
        }
        this.meeting=formValidation(document.getElementById('meetingUpload'), {
            fields: {
                meetingDate: this.dateValidator,
                meetingType: this.meetingTypeValidator
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap()
            }
        })
    },
    data() {
        return {
            meetingData: {
                meetingExcused:[],
                meetingAttendees: [],
                meetingDate: null,
                meetingType: null,
            },
            memberList: [],
            memberSettings: {
                width: '100%',
                placeholder: 'Select attendees',
                theme: 'bootstrap4',
                multiple: true
            },
            meetingConfig: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
                allowInputToggle: true
            },
            meetingMinutesFile: '',
            meetingMinutes:[],
            years:{},
            headers: [
                'Meeting Name',
                'Meeting Type',
                'Download Minutes'
            ]
        }
    },
    methods: {
        getMembers() {
            axios.post('api/getMemberList')
                .then(response => {
                    response.data.forEach(item => {
                        this.memberList.push(item)
                    })
                }).catch(error => {
                console.log(error)
            })
        },
        setFile(fileList) {
            if (!fileList.length) return
            this.meetingMinutesFile=fileList[0]
        },
        sendMinutes() {
            this.meeting.validate()
            .then(response => {
                if (response==="Valid") {
                    let modal=this.$refs.messageModal
                    modal.output="Uploading minutes"
                    modal.messageSent=false
                    $(modal.$refs.messageSentModal).modal('show')
                    let formData=new FormData()
                    let headers= {
                        'Content-Type': 'multipart/form-data'
                    }
                    formData.append('minutesFile',this.meetingMinutesFile)
                    formData.append('data',JSON.stringify(this.meetingData))
                    axios.post('/api/uploadMinutes',formData,{config: headers})
                        .then(response=>{
                            modal.messageSent=true
                            modal.output=response.data
                        })
                        .catch(error=>{
                            modal.messageSent=true
                            modal.output=error
                        })
                }
            })
        },
        closeModal() {
            let modal=this.$refs.messageModal
            modal.messageSent=false
            $(modal.$refs.messageSentModal).modal('hide')
            this.$router.push({name: 'profile'})
        },
        validateField(fieldName) {
            this.meeting.validateField(fieldName)
            this.getAttendees()
        },
        getAttendees() {
            if (this.meetingData.meetingType==="Committee" && this.meetingData.meetingDate!==null) {
                let formData=new FormData()
                formData.append('meetingDate',this.meetingData.meetingDate)
                axios.post('api/getMeetingAttendees',formData)
                    .then(response=>{
                        this.meetingData.meetingAttendees=response.data.attendees
                        this.meetingData.meetingExcused=response.data.excused
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                this.meetingData.meetingAttendees=[]
            }
        },
        getMinutes() {
            axios.get('/api/getMinutes')
            .then(response=>{
                this.meetingMinutes=response.data
                this.years=Object.keys(this.meetingMinutes).sort().reverse()
            })
            .catch(error=>{
                console.log(error)
            })
        },
        isLinkActive(index) {
            if (index===0) {
                return "active"
            }
        },
        isTabActive(index) {
            if (index===0) {
                return "show active"
            }
        }
    }
}
</script>

<style scoped>

</style>
