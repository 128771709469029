<template>
    <div id="demo">
        <div class="card-body">
            <div class="row">
                <div class="form-group col-md-4 col-sm-4">
                    <label class="custom-address-label">First name</label>
                    <input type="text" class="form-control" id="first_name" name="first_name" v-model="first_name"
                        data-fv-not-empty="true"
                        data-fv-not-empty___message="First name is required">
                </div>
                <div class="form-group col-md-4 col-sm-4">
                    <label class="custom-address-label">Middle name</label>
                    <input type="text" class="form-control" id="middle_name" name="middle_name" v-model="middle_name">
                </div>
                <div class="form-group col-md-4 col-sm-4">
                    <label class="custom-address-label">Last name</label>
                    <input type="text" class="form-control" id="last_name" name="last_name" v-model="last_name"
                        data-fv-not-empty="true"
                        data-fv-not-empty___message="Last name is required">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <label class="custom-address-label">Email address</label>
                    <div class="form-group">
                        <input type="email" class="form-control" id="email" name="email" v-model="email"
                               data-fv-not-empty="true"
                               data-fv-not-empty___message="Email address is required"
                               data-fv-email-address="true"
                               data-fv-email-address___message="Not a valid email address"
                               placeholder="Email address">
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <label class="custom-address-label">Date of Birth</label>
                    <div class="form-group selectContainer">
                        <div class="input-group date">
                            <datepicker v-model="dob" :wrap="true" :config="dateOptions" placeholder="mm/dd/yyyy" id="dob" name="dob"></datepicker>
                            <div class="input-group-append">
                                <div class="input-group-text datepickerbutton">
                                    <i class="fas fa-calendar"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <fieldset class="field-border">
                <legend class="field-border">Phone numbers</legend>
                <div class="form-row" v-for="(phone, index) in phones" :key="index">
                    <div class="form-group col-md-5 col-sm-5">
                        <Select2 v-model="phone.phone_type" :options="phoneTypes" :settings="phoneSettings" :name="'phone['+index+'].type'"
                                 @change="revalidate('phone['+index+'].type')" ></Select2>
                    </div>
                    <div class="form-group col-md-6 col-sm-6">
                        <input v-model="phone.phone_number" type="text" class="form-control" :name="'phone['+index+'].number'" placeholder="Phone number"
                               data-fv-not-empty="true"
                               data-fv-not-empty___message="Phone number is required"
                               data-fv-phone="true"
                               data-fv-phone___country="US"
                               data-fv-phone___message="Invalid phone number">
                    </div>
                    <div class="col-md-1 col-sm-1">
                        <i class="alert-danger fas fa-minus-circle" @click="remove(index)" v-show="(index || (!index && phones.length >1)) && (deleting!==index)"></i>
                        <div class="spinner-border spinner-border-sm text-danger" role="status" v-show="(index || (!index && phones.length >1)) && (deleting===index)"><span class="sr-only">Deleting...</span></div>
                        <i class="alert-success fas fa-plus-circle" @click="add(index)" v-show="index === phones.length-1"></i>
                    </div>
                </div>
                <div class="form-row align-content-center">
                    <div class="custom-control custom-checkbox custom-checkbox-switch">
                        <input type="checkbox" class="custom-control-input" v-model="roster_opt_out"  id="optOut" name="optOut">
                        <label class="custom-control-label" for="optOut">Select here to prevent your phone number from being listed on the member roster</label>
                    </div>
                </div>
            </fieldset>
            <fieldset class="field-border">
                <legend class="field-border">Home address</legend>
                <div class="row">
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <input type="text" name="rstreet" class="form-control" v-model="street" id="rstreet" placeholder="Street address"
                                   data-fv-not-empty="true"
                                   data-fv-not-empty___message="Street is required">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-4 col-md-4">
                        <input type="text" name="rcity" class="form-control" v-model="city" id="rcity" placeholder="City"
                               data-fv-not-empty="true"
                               data-fv-not-empty___message="City is required">
                    </div>
                    <div class="form-group col-sm-4 col-md-4">
                        <select2 v-model="state" :options="stateList" :settings="stateSettings" name="rstate" id="rstate" @select="revalidate('rstate')"></select2>
                    </div>
                    <div class="form-group col-sm-4 col-md-4">
                        <input type="num" name="rzip" class="form-control" v-model="zip_code" id="rzip" placeholder="Zip code"
                               data-fv-not-empty="true"
                               data-fv-not-empty___message="Zip code is required"
                               data-fv-zip-code="true"
                               data-fv-zip-code___country="US"
                               data-fv-zip-code___message="Invalid zip code">
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script>
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import Declarative from 'formvalidation/plugins/Declarative';
import Phone from 'formvalidation/validators/phone';
import ZipCode from 'formvalidation/validators/zipCode';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
import memberAppStore from "../../../store";
import states from "../../../states";

export default {
    name: "databasedemo",
    //store: memberAppStore,
    data() {
        return {
            deleting: -1,
            stateList: states,
            phoneTypes: [
                'Home',
                'Mobile',
                'Work'
            ],
            stateSettings: {
                width: "100%",
                placeholder: 'Select a state',
                theme: 'bootstrap4'
            },
            phoneSettings: {
                width: "100%",
                placeholder: "Select a phone number type",
                theme: 'bootstrap4'
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
                allowInputToggle: false
            }
        }
    },
    mounted() {
        console.log("database Demographics mounted")
        this.typeValidator={
            validators: {
                notEmpty: {
                    message: "Phone type is required"
                }
            }
        }
        this.numberValidator={
            validators: {
                notEmpty: {
                    message: "Phone number is required"
                },
                phone: {
                    country: "US",
                    message: "Invalid phone number"
                }
            }
        }
        this.demo=formValidation(document.getElementById('demo'), {
            fields: {
                'phone[0].type': this.typeValidator,
                rstate: {
                    validators: {
                        notEmpty: {
                            message: "State is required"
                        },
                    }
                },
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true,
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap()
            }
        })
        this.demo.registerValidator('phone', Phone)
        this.demo.registerValidator('zipCode',ZipCode)
        //Set the state field to default to Massachusetts and validate field for consistency in UI
        this.state="MA"
        this.demo.validateField('rstate')
        for (let i=1; i<this.phones.length; i++) {
            this.demo.addField('phone['+i+'].type',this.typeValidator)
            this.demo.addField('phone['+i+'].number',this.numberValidator)
        }
    },
    computed: {
        apiState() {
            return memberAppStore.getters['database/getApiState']
        },
        ...mapFields('database',[
            'member.first_name',
            'member.middle_name',
            'member.last_name',
            'member.dob',
            'member.email',
            'member.roster_opt_out',
            'addresses[0].street',
            'addresses[0].city',
            'addresses[0].state',
            'addresses[0].zip_code'
        ]),
        ...mapMultiRowFields('database',['phones'])
    },
    watch: {
        apiState: function() {
            if (this.apiState===3) {
                let length=this.phones.length
                let fv=this.demo
                let nv=this.numberValidator
                let tv=this.typeValidator
                let lastItem=length-1
                let field=fv.getFields()
                let fieldKeys=Object.keys(field)
                if (length>1) {
                    let element=async function() {
                        await document.getElementsByName('phone['+lastItem+'].number')
                        return "Success"
                    }
                    element().then(function() {
                        for (let i=1; i<length; i++) {
                            fv.addField('phone['+i+'].type',tv)
                            fv.addField('phone['+i+'].number',nv)
                        }
                        fieldKeys.forEach(field => {
                            if (document.getElementsByName(field).length===0) {
                                fv.removeField(field)
                            }
                        })
                    })
                }
            }
        }
    },
    methods: {
        revalidate(name) {
          this.demo.validateField(name)
        },
        resetForm() {
            this.demo.resetForm(true)
        },
        async validate() {
            let result=await this.demo.validate()
            return result==="Valid"
        },
        add(index) {
            let fv=this.demo
            let numv=this.numberValidator
            let typev=this.typeValidator
            memberAppStore.commit('database/addPhone')
            let newIndex=index+1
            let element = async function(newIndex) {
                await document.getElementsByName('phone['+newIndex+'].number')
                return "Success"
            }
            element().then(function (){
                fv.addField("phone["+newIndex+"].number",numv)
                fv.addField("phone["+newIndex+"].type",typev)
            })
        },
        remove(index) {
            this.deleting=index
            let getLast=this.phones.length
            let toRemove=getLast-1
            let pid=this.phones[index].id
            axios.delete('/api/deletePhone/'+pid)
            .then(response =>{
                this.demo.removeField('phone['+toRemove+'].number')
                this.demo.removeField('phone['+toRemove+'].type')

                memberAppStore.commit('database/deletePhone', index)
                this.deleting=-1
            }).catch(error =>{
                console.log(error)
                this.deleting=-1
            })

            //this.phones.splice(index,1);
        }
    }
}
</script>

<style scoped>

</style>
