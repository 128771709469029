<template>
    <div>
        <form method="post" id="contactForm" >
            <div class="form-group" >
                <input type="text" v-model="form.senderName" name="senderName" id="senderName" class="form-control" placeholder="Your Name"
                    data-fv-not-empty="true"
                    data-fv-not-empty___message="Your name is required">
            </div>
            <div class="form-group">
                <input type="text" v-model="form.senderEmail" name="senderEmail" id="senderEmail" class="form-control" placeholder="you@yoursite.com"
                    data-fv-not-empty="true"
                    data-fv-not-empty___message="Your email address is required"
                    data-fv-email-address="true"
                    data-fv-email-address___message="Invalid email address">
            </div>
            <div class="form-group">
                <Select2 v-model="form.subject" :options="options" name="subject" id="subject"  @change="revalidate"></Select2>
            </div>
            <div class="form-group">
                <textarea class="form-control" v-model="form.message" name="message" id="message" rows="4" placeholder="Type message here"
                    data-fv-not-empty="true"
                    data-fv-not-empty___message="Message is required"></textarea>
            </div>
            <div>
                <div class="form-group"><div id="captchaContainer"></div></div>
                <button class="btn btn-primary" type="submit">Send Message</button>
            </div>
        </form>

        <!---- MODAL DIALOG BOX --->
        <modal ref="messageModal" :redirect-url="contactRedirect"></modal>
        <!---END MODAL--->
    </div>
</template>

<script>
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import SubmitButton from 'formvalidation/plugins/SubmitButton';
import Declarative from 'formvalidation/plugins/Declarative';
import Icon from 'formvalidation/plugins/Icon';
import Recaptcha from 'formvalidation/plugins/Recaptcha';

export default {
    name: "ContactForm",
    mounted() {

        this.fv=formValidation(document.getElementById('contactForm'), {
           fields: {
                subject: {
                    validators: {
                        notEmpty: {
                            message: 'A subject is required'
                        },
                    }
                },
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true,
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
                icon: new Icon({
                    valid: 'fa fa-check',
                    invalid: 'fa fa-times',
                    validating: 'fa fa-refresh'
                }),
                submitButton: new SubmitButton(),
                recaptcha: new Recaptcha({
                    element: 'captchaContainer',
                    message: 'Invalid verification',
                    siteKey: process.env.MIX_RECAPTCHA_SITE_KEYv2_robot,
                    //badge: 'bottomright',
                    theme: 'light',
                    timeout: '120',
                    //size: 'invisible',
                    //backendVerificationUrl: '/api/checkCaptcha'
                })
            },
        });
        this.fv.on('core.form.valid', () => {
            let modal=this.$refs.messageModal
            modal.output="Sending your message"
            $(modal.$refs.messageSentModal).modal('show')
            axios.post('/api/sendMessage', this.form)
            .then(function (response) {
                modal.messageSent=true
                modal.output=response.data;
            })
            .catch(function (error) {
                modal.messageSent=true
                modal.output=error;
            });
        });
    },
    props: ['contactRedirect'],
    data() {
        return {
            form: {
                senderName: '',
                formType: 'contact',
                senderEmail: '',
                subject: '',
                message: '',
            },
            contactSettings: {
                width: "100%",
                theme: "bootstrap4"
            },
            options: [
                'Subscription Question',
                'Membership',
                'Community CPR Request',
                'General Comment',
                'Question'
            ]
        };
    },
    methods: {
        revalidate() {
            this.fv.revalidateField('subject')
        },
    }
}
</script>


<style>

</style>
