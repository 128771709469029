<template>
    <div id="certs">
        <div class="row h-100">
            <div class="col-sm-6 col-md-6">
                <div class="form-group">
                    <label class="custom-address-label">Level of training</label>
                    <Select2 v-model.number="level" :options="levelOptions" :settings="levelSettings" :name="'level'" @change="addCerts"></Select2>
                </div>
            </div>
            <div class="col-sm-6 col-md-6">
                <div class="form-group">
                    <label class="custom-address-label">Call Sign</label>
                    <input type="text" v-model="call_sign" class="form-control" readonly>
                </div>
            </div>
        </div>
        <div class="row h-100">
            <div class="col-sm-6 col-md-6 align-self-center">
                <div>
                    <div class="custom-control custom-checkbox custom-checkbox-switch">
                        <input type="checkbox" v-model="auth_to_practice" class="custom-control-input" name="auth_to_practice" id="auth_to_practice" disabled>
                        <label class="custom-control-label" for="auth_to_practice">Authorized to practice</label>
                    </div>
                </div>
            </div>
        </div>

        <fieldset class="field-border">
            <legend class="field-border">Certifications/Licenses</legend>
            <div class="row">
                <div class="col-sm-3 col-md-3 d-flex justify-content-center font-weight-bold">Type</div>
                <div class="col-sm-3 col-md-2 d-flex justify-content-center font-weight-bold">Number</div>
                <div class="col-sm-2 col-md-2 d-flex justify-content-center font-weight-bold">Expiration</div>
                <div class="col-sm-3 col-md-3 d-flex justify-content-center font-weight-bold">State of Issue</div>
                <div class="col-sm-1 col-md-1 d-flex justify-content-center font-weight-bold">Cert Copy</div>
                <div class="col-sm-1 col-md-1"></div>
            </div>
            <div class="form-row" v-for="(certification, index) in certifications" :key="index">
                <div class="form-group col-sm-3 col-md-3">
                    <Select2 v-model.number="certification.cert_type_id" :options="certTypes" :settings="certSettings" :name="'certification['+index+'].type'"
                             :id="'certification['+index+'].type'" @change="setCerts(index)"></Select2>
                </div>
                <div class="form-group col-sm-2 col-md-2">
                    <input type="text" v-model="certification.cert_number" class="form-control" :name="'certification['+index+'].number'" :id="'certification['+index+'].number'">
                </div>
                <div class="form-group col-sm-2 col-md-2 selectContainer">
                    <div class="input-group date">
                        <datepicker v-model="certification.expiration_date" class="form-control" :wrap="true" :config="dateOptions" placeholder="mm/dd/yyyy"
                                    :name="'certification['+index+'].expiration'"
                                    :id="'certification['+index+'].expiration'"
                                    @change="revalidate('certification['+index+'].expiration')"></datepicker>
                        <div class="input-group-append">
                            <div class="input-group-text datepickerbutton">
                                <i class="fas fa-calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm-3 col-md-3">
                    <Select2 v-model="certification.state" :options="stateList" :settings="stateSettings" :name="'certification['+index+'].state'"
                             :id="'certification['+index+'].state'"
                             @change="revalidate('certification['+index+'].state')"></Select2>
                </div>
                <div class="col-sm-1 col-md-1 text-center form-group">
                    <div class="d-flex">
                        <div class="mr-auto p-2">
                            <label :for="'certification['+index+'].image'">
                                <i class="fas fa-upload text-primary"></i>
                            </label>
                            <input type="file" :id="'certification['+index+'].image'" :name="'certification['+index+'].image'"
                                   @change="setFile($event.target.files, index)" class="form-control" style="display: none;">
                        </div>
                        <div class="p-2">
                            <a v-show="certification.cert_path!==null" :href="certification.cert_path" target="_blank"><i class="fas fa-download text-primary"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1 col-md-1">
                    <div>
                        <i class="alert-danger fas fa-minus-circle" @click="remove(index)" v-show="showRemove(index) && (deleting!==index)"></i>
                        <div class="spinner-border spinner-border-sm text-danger" role="status" v-show="showRemove(index) && (deleting===index)"><span class="sr-only">Deleting...</span></div>
                        <i class="alert-success fas fa-plus-circle" @click="add(index)" v-show="index === certifications.length-1"></i>
                    </div>
                </div>
            </div>
            <div class="fv-help-block" v-if="filesPending">Certificate images pending upload.  Please remember to click Update Profile</div>
        </fieldset>
    </div>
</template>

<script>
import formValidation from 'formvalidation/core/Core';
import Trigger from 'formvalidation/plugins/Trigger';
import Bootstrap from 'formvalidation/plugins/Bootstrap';
import Declarative from 'formvalidation/plugins/Declarative';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
import memberAppStore from "../../../store";
import states from "../../../states";
import {lowerCase, snakeCase} from "lodash/string";


export default {
    name: "profilecerts",
    //store: memberAppStore,
    mounted() {
        this.levelValidator={
            validators: {
                notEmpty: {
                    message: "Provider level is required"
                }
            }
        }
        this.typeValidator={
            validators: {
                notEmpty: {
                    message: "Cert. type is required"
                }
            }
        }
        this.stateValidator={
            validators: {
                notEmpty: {
                    message: "State of issuance is required"
                }
            }
        }
        this.numberValidator={
            validators: {
                notEmpty: {
                    message: "Cert. number is required"
                }
            }
        }
        this.expirationValidator={
            validators: {
                notEmpty: {
                    message: "Expiration date is required"
                },
                date: {
                    format: 'MM/DD/YYYY',
                    message: 'Invalid date'
                }
            }
        }
        this.imageValidator={
            validators: {
                file: {
                    extension: "pdf,jpg,jpeg",
                    type: "image/jpg,image/jpeg,application/pdf",
                    message: "PDF or JPG only"

                }
            }
        }
        this.certs=formValidation(document.getElementById('certs'), {
            fields: {
                level: this.levelValidator,
                'certification[0].type': this.typeValidator,
                'certification[0].number': this.numberValidator,
                'certification[0].expiration': this.expirationValidator,
                'certification[0].state': this.stateValidator,
                'certification[0].image': this.imageValidator
            },
            plugins: {
                declarative: new Declarative({
                    html5Input: true
                }),
                trigger: new Trigger(),
                bootstrap: new Bootstrap()
            }
        })
        document.getElementById('certification[0].type').disabled=true
        this.addCerts()


    },
    data() {
        return {
            filesPending: 0,
            deleting: -1,
            index: 0,
            certTypes: [],
            certSettings: {
                width: "100%",
                theme: "bootstrap4",
                minimumResultsForSearch: Infinity
            },
            stateList: states,
            stateSettings: {
                width: "100%",
                theme: "bootstrap4"
            },
            isProvider: false,
            levelOptions: [
                {id: 0, text:'None'},
                {id: 1, text:'Basic'},
                {id: 2, text: 'Advanced'},
                {id: 3, text:'Paramedic'}
            ],
            levelSettings: {
                width: "100%",
                theme: "bootstrap4",
                minimumResultsForSearch: Infinity
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
                allowInputToggle: false
            }
        }
    },
    created() {
        this.getCertTypes()
    },
    computed: {
        ...mapFields('profile',['level','member.call_sign','member.auth_to_practice','cert_images']),
        ...mapMultiRowFields('profile',['certifications']),
    },
    methods: {
        setFile(fileList, index) {
            if (!fileList.length) return
            let type_row=this.certTypes.find( ({id})=>id===this.certifications[index].cert_type_id)
            let certType=lowerCase(type_row['text'])
            let fieldName=snakeCase(certType)+"_image"
            let file=fileList[0]
            memberAppStore.commit('profile/ADD_CERT_IMAGE',{fieldName,file})
            this.filesPending=1
        },
        showRemove(index) {
            let lvl=true
            let i=index || (!index && this.certifications.length >1)
            let l=this.certifications[index].cert_type_id
            if (l===1) {
                return false
            }
            if (l!==1 && this.level===0) {
                lvl=true
            }
            switch(this.level) {
                case 1:
                case 2:
                    if (l===3 || l===4 || l===6) {
                        lvl=false
                    }
                    break
                case 3:
                    if (l===5 || l===6 || l===7) {
                        lvl=false
                    }
                    break
                default:
                    lvl=true
            }
            return i && lvl
        },
        addCerts() {
            this.certs.validateField('level')
            //First get index values for each of the certification types EMT(3), Advanced(4), Paramedic(5), CPR(6), ACLS(7)
            //If it doesn't exist, the value will be -1
            let emt=this.certifications.findIndex(function (certification) {return certification.cert_type_id===3})
            let advanced=this.certifications.findIndex(function (certification) {return certification.cert_type_id===4})
            let medic=this.certifications.findIndex(function (certification) {return certification.cert_type_id===5})
            let cpr=this.certifications.findIndex(function (certification) {return certification.cert_type_id===6})
            let acls=this.certifications.findIndex(function (certification) {return certification.cert_type_id===7})
            //Now lets figure out if we need to rename, add or delete fields based on the selected provider level
            let toAdd=0
            let toRemove=0
            let level=this.level
            switch (level) {
                case 0:
                    //need to remove emt levels, CPR and ACLS
                    if (emt!==-1) {
                        memberAppStore.dispatch('profile/removeCert', emt)
                        //We removed a level, so redefine CPR and ACLS
                        cpr=this.certifications.findIndex(function (certification) {return certification.cert_type_id===6})
                        acls=this.certifications.findIndex(function (certification) {return certification.cert_type_id===7})
                    }
                    if (advanced!==-1) {
                        memberAppStore.dispatch('profile/removeCert',advanced)
                        //We removed a level, so redefine CPR and ACLS
                        cpr=this.certifications.findIndex(function (certification) {return certification.cert_type_id===6})
                        acls=this.certifications.findIndex(function (certification) {return certification.cert_type_id===7})
                    }
                    if (medic!==-1) {
                        memberAppStore.dispatch('profile/removeCert',medic)
                        //We removed a level, so redefine CPR and ACLS
                        cpr=this.certifications.findIndex(function (certification) {return certification.cert_type_id===6})
                        acls=this.certifications.findIndex(function (certification) {return certification.cert_type_id===7})
                    }
                    if (cpr!==-1) {
                        memberAppStore.dispatch('profile/removeCert',cpr)
                        //We removed a level, so redefine CPR and ACLS
                        acls=this.certifications.findIndex(function (certification) {return certification.cert_type_id===7})
                    }
                    if (acls!==-1) {
                        memberAppStore.dispatch('profile/removeCert',acls)
                    }
                    break;
                case 1:
                    //First handle the EMT level
                    if (emt!==-1) {
                        //Check if EMT is here... Do nothing
                    } else if (advanced !==-1) {
                        //Check if advanced EMT is present.. If so, change it to EMT
                        this.$set(this.certifications[advanced], 'cert_type_id', 3)
                    } else if (medic!==-1) {
                        //Check if medic is present... If so change it to EMT
                        this.$set(this.certifications[medic], 'cert_type_id',3)
                    } else {
                        //Ok. EMT level isn't here, lets add the EMT cert
                        toAdd=level
                        memberAppStore.dispatch('profile/addCert',toAdd)
                    }

                    //Now lets check for CPR
                    if (cpr!==-1) {
                        //Do nothing
                    } else {
                        toAdd=5 //Key to add only CPR
                        memberAppStore.dispatch('profile/addCert',toAdd)
                    }

                    //Now see if ACLS is here, and if so lets remove
                    if (acls!==-1) {
                        toRemove=acls
                        memberAppStore.dispatch('profile/removeCert',toRemove)
                    }
                    break
                case 2:
                    //First handle the Advanced level
                    if (advanced!==-1) {
                        //Check if Advanced is here... Do nothing
                    } else if (emt !==-1) {
                        //Check if advanced EMT is present.. If so, change it to Advanced
                        this.$set(this.certifications[emt], 'cert_type_id', 4)
                    } else if (medic!==-1) {
                        //Check if medic is present... If so change it to Advanced
                        this.$set(this.certifications[medic], 'cert_type_id',4)
                    } else {
                        //Ok. Advanced level isn't here, lets add the EMT cert
                        toAdd=level
                        memberAppStore.dispatch('profile/addCert',toAdd)
                    }

                    //Now lets check for CPR
                    if (cpr!==-1) {
                        //Do nothing
                    } else {
                        toAdd=5 //Key to add only CPR
                        memberAppStore.dispatch('profile/addCert',toAdd)
                    }

                    //Now see if ACLS is here, and if so lets remove
                    if (acls!==-1) {
                        toRemove=acls
                        memberAppStore.dispatch('profile/removeCert',toRemove)
                    }
                    break
                case 3:
                    //First handle the medic level
                    if (medic!==-1) {
                        //Check if EMT is here... Do nothing
                    } else if (advanced !==-1) {
                        //Check if advanced EMT is present.. If so, change it to Medic
                        this.$set(this.certifications[advanced], 'cert_type_id', 5)
                    } else if (emt!==-1) {
                        //Check if EMT is present... If so change it to medic
                        this.$set(this.certifications[emt], 'cert_type_id',5)
                    } else {
                        //Ok. Medic level isn't here, lets add the Medic cert
                        toAdd=level
                        memberAppStore.dispatch('profile/addCert',toAdd)
                    }

                    //Now lets check for CPR
                    if (cpr!==-1) {
                        //Do nothing
                    } else {
                        toAdd=5 //Key to add only CPR
                        memberAppStore.dispatch('profile/addCert',toAdd)
                    }

                    //Now see if ACLS is here
                    if (acls!==-1) {
                        //Do nothing
                    } else {
                        toAdd=4 //Key for ACLS
                        memberAppStore.dispatch('profile/addCert',toAdd)
                    }
                    break
                default:
                    //Nothing found so add what's needed
                    toAdd=level
                    memberAppStore.dispatch('profile/addCert',toAdd)
                    break
            }
            let certKeys=Object.keys(this.certifications)
            let certType=''
            let fv=this.certs
            let certobject=this.certifications
            let tv=this.typeValidator
            let nv=this.numberValidator
            let ev=this.expirationValidator
            let sv=this.stateValidator
            let iv=this.imageValidator

            let valFields=Object.keys(this.certs.getFields())
            //Remove the validators
            valFields.forEach((field) => {
                if (field!=="level") {
                    fv.removeField(field)
                }
            })

            let element=async function() {
                await document.getElementsByName('certification[1].type')
                return "Success"
            }
            element().then(function() {
                certKeys.forEach((item) => {
                    certType=certobject[item].cert_type_id
                    if (certType===1) {
                        fv.addField('certification['+item+'].type',tv)
                        fv.addField('certification['+item+'].number',nv)
                        fv.addField('certification['+item+'].expiration',ev)
                        fv.addField('certification['+item+'].state',sv)
                        fv.addField('certification['+item+'].image',iv)
                        document.getElementById('certification['+item+'].type').disabled=true
                        document.getElementById('certification['+item+'].number').disabled=false
                        document.getElementById('certification['+item+'].expiration').disabled=false
                        document.getElementById('certification['+item+'].state').disabled=false
                    }
                    if (certType===2) {
                        fv.addField('certification['+item+'].type',tv)
                        fv.addField('certification['+item+'].number',nv)
                        fv.addField('certification['+item+'].expiration',ev)
                        fv.addField('certification['+item+'].image',iv)
                        document.getElementById('certification['+item+'].type').disabled=false
                        document.getElementById('certification['+item+'].number').disabled=false
                        document.getElementById('certification['+item+'].expiration').disabled=false
                        document.getElementById('certification['+item+'].state').disabled=true

                    }
                    if ( certType===3 || certType===4 || certType===5) {
                        fv.addField('certification['+item+'].type',tv)
                        fv.addField('certification['+item+'].number',nv)
                        fv.addField('certification['+item+'].expiration',ev)
                        fv.addField('certification['+item+'].image',iv)
                        document.getElementById('certification['+item+'].type').disabled=true
                        document.getElementById('certification['+item+'].number').disabled=false
                        document.getElementById('certification['+item+'].expiration').disabled=false
                        document.getElementById('certification['+item+'].state').disabled=true

                    }
                    if (certType===6 || certType===7) {
                        fv.addField('certification['+item+'].type',tv)
                        fv.addField('certification['+item+'].expiration',ev)
                        fv.addField('certification['+item+'].image',iv)
                        document.getElementById('certification['+item+'].type').disabled=true
                        document.getElementById('certification['+item+'].number').disabled=true
                        document.getElementById('certification['+item+'].expiration').disabled=false
                        document.getElementById('certification['+item+'].state').disabled=true
                    }
                })
            })

        },
        getCertTypes() {
            axios.get('/api/getCertTypes')
                .then((response) => {
                    this.certTypes=response.data
                })
        },
        revalidate(name) {
            this.certs.validateField(name)

        },
        async validate() {
            let result=await this.certs.validate()
            return result==="Valid"
        },
        add(index) {
            let newIndex=index+1
            memberAppStore.dispatch('profile/addCert', '0')
            let tv=this.typeValidator
            let fv=this.certs
            let element=async function(newIndex) {
                await document.getElementsByName('certification['+newIndex+'].type')
                return "Success"
            }
            element().then(function() {
                fv.addField('certification['+newIndex+'].type',tv)
            })
        },
        remove(index) {
            this.deleting=index
            let getLast=this.certifications.length
            let toRemove=getLast-1
            let pid=this.certifications[index].id
            let valFields=Object.keys(this.certs.getFields())
            let removeFields=['certification['+toRemove+'].type',
                'certification['+toRemove+'].number',
                'certification['+toRemove+'].expiration',
                'certification['+toRemove+'].state',
                'certification['+toRemove+'].image']
            axios.delete('/api/deleteCert/'+pid)
            .then(response =>{
                removeFields.forEach(field =>{
                    if (valFields.includes(field)){
                        this.certs.removeField(field)
                    }
                })
                memberAppStore.dispatch('profile/removeCert',index)
                this.deleting=-1
            })
        },
        setCerts(index) {
            this.certs.validateField('certification['+index+'].type')
            let certType=this.certifications[index].cert_type_id
            let valFields=Object.keys(this.certs.getFields())
            switch (certType) {
                case 1:
                    this.certs.addField('certification['+index+'].number',this.numberValidator)
                    this.certs.addField('certification['+index+'].expiration',this.expirationValidator)
                    this.certs.addField('certification['+index+'].state',this.stateValidator)
                    this.certs.addField('certification['+index+'].image',this.imageValidator)
                    document.getElementById('certification['+index+'].number').disabled=false
                    document.getElementById('certification['+index+'].expiration').disabled=false
                    document.getElementById('certification['+index+'].state').disabled=false
                    break;
                case 2:
                    this.certs.addField('certification['+index+'].number',this.numberValidator)
                    this.certs.addField('certification['+index+'].expiration',this.expirationValidator)
                    this.certs.addField('certification['+index+'].image',this.imageValidator)
                    document.getElementById('certification['+index+'].number').disabled=false
                    document.getElementById('certification['+index+'].expiration').disabled=false
                    document.getElementById('certification['+index+'].state').disabled=true
                    if (valFields.includes('certification['+index+'].state')) {
                        this.certs.removeField('certification['+index+'].state')
                    }
                    break
                case 3:
                case 4:
                case 5:
                    this.certs.addField('certification['+index+'].number',this.numberValidator)
                    this.certs.addField('certification['+index+'].expiration',this.expirationValidator)
                    this.certs.addField('certification['+index+'].image',this.imageValidator)
                    document.getElementById('certification['+index+'].number').disabled=false
                    document.getElementById('certification['+index+'].expiration').disabled=false
                    document.getElementById('certification['+index+'].state').disabled=true
                    if (valFields.includes('certification['+index+'].state')) {
                        this.certs.removeField('certification['+index+'].state')
                    }
                    this.certifications[index].state="MA"
                    break;
                case 6:
                case 7:
                    this.certs.addField('certification['+index+'].expiration',this.expirationValidator)
                    this.certs.addField('certification['+index+'].image',this.imageValidator)
                    document.getElementById('certification['+index+'].state').disabled=true
                    document.getElementById('certification['+index+'].number').disabled=true
                    document.getElementById('certification['+index+'].expiration').disabled=false
                    if (valFields.includes('certification['+index+'].state')) {
                        this.certs.removeField('certification['+index+'].state')
                    }
                    if (valFields.includes('certification['+index+'].number')) {
                        this.certs.removeField('certification['+index+'].number')
                    }
                    this.certifications[index].state=null
                    break;
                default:
                    break;
            }
        },
    }
}
</script>

<style scoped>

</style>
