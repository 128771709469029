<template>
    <div class="pt-2" v-if="hasRole(memberAdmin) || hasRole(sysAdmin)">
        <div class="card w-100 h-100">
            <div class="card-header bg-dark text-white">Data Export</div>
            <div class="card-body">
                <div class="row">
                    <div class="form-group col-sm-12 text-center">
                        <label class="custom-address-label">Select Filter (Active Members or Applicants)</label>
                        <Select2 v-model="filter" :options="filterList" :settings="filterSettings"
                                 :name="'filter'" @change="getMemberList"></Select2>
                    </div>
                </div>
                <div class="row" v-show="filter!==null">
                    <div ref="memberList" id="memberList">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 text-center">
                                <table class="table table-responsive w-100 d-block d-md-table table-bordered table-striped">
                                    <thead class="bg-dark">
                                    <tr>
                                        <th class="text-white">Export</th>
                                        <th class="text-white">ESO Empl ID</th>
                                        <th class="text-white">ESO Username</th>
                                        <th class="text-white">Last Name</th>
                                        <th class="text-white">First Name</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="row in rows">
                                        <td><input type="checkbox" :value="row['id']" v-model="exportIds"></td>
                                        <td><input type="text"  v-model="emplIds[row['id']]"></td>
                                        <td><input type="text" v-model="esoUserNames[row['id']]"></td>
                                        <td>{{row['last_name']}}</td>
                                        <td>{{row['first_name']}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-md-6"><button class="btn-dark text-white" @click="exportUsers">Export Personnel</button></div>
                            <div class="col-sm-6 col-md-6"><button class="btn-dark text-white" @click="exportCerts">Export Certs</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {roleCheck} from "../../helpers/rolecheck";
import memberAppStore from "../../store"
import { saveAs } from 'file-saver'
import moment from 'moment'

export default {
    name: "DataExport",
    mixins: [roleCheck],
    store: memberAppStore,
    created() {

    },

    data() {
        return {
            exportIds:[],
            emplIds: [],
            esoUserNames: [],
            rows:[],
            filter: null,
            filterList: ['Active','Applicant'],
            filterSettings: {
                width: '30%',
                placeholder: 'Select a filter',
                theme: 'bootstrap4',
                minimumResultsForSearch: Infinity
            }
        }
    },

    computed: {

    },

    methods: {
        getMemberList() {
            this.rows=[]
            this.emplIds=[]
            axios.get('/api/getExportMemberList/'+this.filter)
                .then(response => {
                    response.data.forEach(row => {
                        if (row!==null) {
                            this.rows.push(row)
                        }
                    })
                })
        },
        exportUsers() {
            let formData = new FormData
            formData.append('sourceIds',JSON.stringify(this.exportIds))
            formData.append('emplIds',JSON.stringify(this.emplIds))
            formData.append('esoUserNames',JSON.stringify(this.esoUserNames))
            axios.post('/api/exportUsers',formData, { responseType: 'blob'})
                .then((response) => {
                    const filename='users.xlsx'
                    const blob=new Blob([response.data], {type: 'application/vnd.ms-excel'})
                    saveAs(blob,filename)
                })
        },
        exportCerts() {
            let formData = new FormData
            formData.append('sourceIds',JSON.stringify(this.exportIds));
            formData.append('emplIds',JSON.stringify(this.emplIds));
            axios.post('/api/exportCerts',formData, {responseType: 'blob'})
                .then((response) => {
                    const filename='certs.xlsx'
                    const blob=new Blob([response.data],{type:'application/vnd.ms-exce;'})
                    saveAs(blob,filename)
                })
        }
    }

}

</script>
