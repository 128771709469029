<template>
    <div class="pt-2">
        <div class="card w-100 h-100" id="evalRequest">
            <div class="card-header bg-dark text-white">Request Evaluation</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-12">
                        <div class="card w-100 border border=dark">
                            <div class="card-header bg-primary text-white text-center">Evaluation Request Form</div>
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="col-sm-6 col-md-6">
                                        <div>
                                            <label class="col-sm-4 col-form-label" for="preceptor">Preceptor Name</label>
                                            <div class="form-group col-sm-8 col-md-8">
                                                <Select2 v-model="preceptor" :options="preceptorList" :settings="listSettings" :name="'preceptor'" :id="'preceptor'"></Select2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <div>
                                            <label class="col-sm-4 col-form-label" for="shiftdate">Date of shift</label>
                                            <div class="form-group col-sm-8 col-md-8">
                                                <div class="input-group date">
                                                    <datepicker v-model="shiftdate" class="form-control" :wrap="true" :config="dateOptions"
                                                                :name="'shiftdate'" :id="'shiftdate'"></datepicker>
                                                    <div class="input-group-append">
                                                        <div class="input-group-text datepickerbutton">
                                                            <i class="fas fa-calendar"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox custom-checkbox-switch">
                                                <input type="checkbox" class="custom-control-input form-control" name="calls" id="calls" v-model="calls">
                                                <label class="custom-control-label" for="calls">Were there any calls on the shift?</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox custom-checkbox-switch">
                                                <input type="checkbox" class="custom-control-input form-control" name="runreports" id="runreports" v-model="runreports">
                                                <label class="custom-control-label" for="runreports">Were any run reports completed?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-primary" @click="sendRequest">Submit Request</button>
            </div>
        </div>
        <membermodal ref="messageModal" @hide="closeModal"></membermodal>
    </div>
</template>

<script>
import memberAppStore from '../../../store'
import membermodal from '../MembersModal'

export default {
    name: "RequestEval",
    store: memberAppStore,
    components: {
        membermodal
    },
    created() {
        this.getPreceptors()
    },
    data() {
        return {
            preceptor: '',
            shiftdate: '',
            runreports: false,
            calls: false,
            preceptorList: [],
            listSettings: {
                width: "100%",
                theme: "bootstrap4"
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: true,
                allowInputToggle: false
            }

        }
    },
    computed: {
        trainee_id() {
            return memberAppStore.getters['profile/getMemberId']
        }
    },
    methods: {
        getPreceptors() {
            axios.get('/api/training/getPreceptors')
            .then(response => {
                response.data.data.forEach(item => {
                    this.preceptorList.push(item)
                })
            })
            .catch (error => {
                console.log(error)
            })
        },
        sendRequest() {
            let formData=new FormData
            this.$refs.messageModal.output="Submitting request"
            $(this.$refs.messageModal.$refs.messageSentModal).modal('show')
            formData.append('trainee_id',this.trainee_id)
            formData.append('evaluator_id',this.preceptor)
            formData.append('shift_date',this.shiftdate)
            formData.append('calls_on_shift',this.calls)
            formData.append('run_reports_completed',this.runreports)
            axios.post('api/training/submitEvalRequest',formData)
            .then(response=> {
                this.$refs.messageModal.messageSent=true
                this.$refs.messageModal.output=response.data
            })
            .catch(error => {
                this.$refs.messageModal.messageSent=true
                this.$refs.messageModal.output=error
            })
        },
        closeModal() {
            this.$refs.messageModal.messageSent=false
            $(this.$refs.messageModal.$refs.messageSentModal).modal('hide')
            this.$router.push({name: 'profile'})
        }
    }
}
</script>

<style scoped>

</style>
